import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from '../services/authentication.service';
import { CustomerAuthenticationService } from '../services/customer-authentication.service';

@Injectable({ providedIn: 'root' })
export class CustomerAuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: CustomerAuthenticationService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let id = route.params['id'];
       // console.log("formId id" + id);
        const currentUser = this.authenticationService.currentCustomerValue;
        //console.log("Current customer User " + JSON.stringify(currentUser));
        if (currentUser.pSrNo == id) {
            // authorised so return true

            return true;
        }

        // not logged in so redirect to login page with the return url

        this.router.navigate(['/Account/CustomerHome'], { queryParams: { returnUrl: state.url } });
        return false;
    }
}
