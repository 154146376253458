import { Component, OnInit } from '@angular/core';
import { NgForm, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class forgotPasswordComponent implements OnInit {

  forgotPasswordForm: FormGroup;
  emailSend: boolean = false;
  submitted = false;
  // emailNotExist: boolean = false;
  constructor(private userService: UserService, private formBuilder: FormBuilder, private toastr: ToastrService) { }
  get email() {
    return this.forgotPasswordForm.get('email');
  }

  ngOnInit() {
    this.forgotPasswordForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }
  onSubmit() {
    if (this.forgotPasswordForm.invalid) {
      this.submitted = true;
      return;
    }
   // console.log("Form data:" + JSON.stringify(this.forgotPasswordForm.value));
    this.userService.forgotPassword(this.forgotPasswordForm.value)
      .subscribe(data => {
      //  console.log("Data:" + JSON.stringify(data));
        if (data.statusCode=202) {
          this.emailSend = true;
          // this.emailNotExist=false;
        }
      }, error => { this.toastr.error("This email does not exist.") });
  //  if(this.emailSend==false)
  //  {
  //   this.emailNotExist=true;
  //  }

  }
}
