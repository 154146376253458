import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ClosureForm, PSRFormHistory } from '../models/closure-form';

import { HistoryData } from '../models/closure-form';
import { ISizeData } from '../models/sizedata';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Form } from '@angular/forms';
import { RegeneratedPassword } from '../models/RegeneratedPassword';

@Injectable({ providedIn: 'root' })
export class ClosureFormService {
  private sizeDataUrl = 'assets/sizedata/sizedata.json';
  baseUrl: string;
  constructor(private http: HttpClient, @Inject('BASE_URL') appUrl: string) {
    // console.log("base URL:" + baseUrl);
    //this.baseUrl = "https://localhost:44392/";
    this.baseUrl = appUrl;
    //this.baseUrl = "http://silganclosures.csdevhub.com/";
  }

  getAll() {
    //console.log(this.baseUrl + 'forms');
    return this.http.get<ClosureForm[]>(`${this.baseUrl}forms`);

  }

  getAllSaveDraft() {
    return this.http.get<ClosureForm[]>(`${this.baseUrl}forms/Draftforms`);
  }

  getSearchforms() {
    return this.http.get<ClosureForm[]>(`${this.baseUrl}forms/Searchforms`);
  }
  getById(id: number) {
    return this.http.get<ClosureForm>(`${this.baseUrl}forms/${id}`);
  }

  getHistoryPSRno(id: number) {
    //return this.http.get<HistoryData[]>(`${this.baseUrl}forms/GetHistoryPSRno/${id}`);
    return this.http.get<PSRFormHistory[]>(`${this.baseUrl}forms/GetHistoryPSRno/${id}`);
  }

  generatePDF(id: any) {
    return this.http.get<FormData>(`${this.baseUrl}forms/GeneratePDF/${id}`); 
  }
  GenerateMyPDF(id: any) {
    return this.http.get<FormData>(`${this.baseUrl}forms/GenerateMyPDF/${id}`);
  }
  generateExcel(id: any) {
    return this.http.get<string>(`${this.baseUrl}forms/GenerateExcel/${id}`);
  }

  getFormHistoryById(id: number) {
    
    return this.http.get<FormData>(`${this.baseUrl}forms/GetHistoryFormById/${id}`);
  }
  register(closureForms: any) {
    return this.http.post(`${this.baseUrl}forms/InitiateForm`, closureForms);
  }

  SaveDraft(closureForms: any) {
    return this.http.post(`${this.baseUrl}forms/SaveDraft`, closureForms);
  }

  SaveProfileFormData(closureForms: any) {
    return this.http.post(`${this.baseUrl}forms/SaveProfileFormData`, closureForms);
  }

  SendEmailToCustomer(closureForms: any) {
    return this.http.post(`${this.baseUrl}forms/SendEmailToCustomer`, closureForms);
  }
  SendEmailToCustomerFromPalletzation(closureForms: any) {
    return this.http.post(`${this.baseUrl}forms/SendEmailToCustomerFromPalletzation`, closureForms);
  }

  UpdateAndClearAllFormData(closureForms: any) {
    return this.http.post(`${this.baseUrl}forms/UpdateAndClearAllFormData`, closureForms);
  }

  SavePackageFormData(closureForms: any) {
    return this.http.post(`${this.baseUrl}forms/SavePackageFormData`, closureForms);
  }
  updateFormStatus(psrNo, statusValue) {
    return this.http.post(`${this.baseUrl}forms/UpdateFormStatus`, { 'psrNo': psrNo, 'statusValue': statusValue });
  }
  SaveProductFormData(closureForms: any) {
    return this.http.post(`${this.baseUrl}forms/SaveProductFormData`, closureForms);
  }
  SaveProcessFormData(closureForms: any) {
    return this.http.post(`${this.baseUrl}forms/SaveProcessFormData`, closureForms);
  }
  SavePallizationFormData(closureForms: any) {
    return this.http.post(`${this.baseUrl}forms/SavePalletizationFormData`, closureForms);
  }
  SaveSummaryFormData(closureForms: any) {
    return this.http.post(`${this.baseUrl}forms/SaveSummaryFormData`, closureForms);
  }

  update(closureForm: ClosureForm) {
    return this.http.put(`${this.baseUrl}forms/${closureForm.formId}`, closureForm);
  }
  customerLogin(CustomerLogin: any) {
    return this.http.post<any>(`${this.baseUrl}forms/CustomerLogin`, CustomerLogin);
  }

  isVaildPasswordLink(params: string) {
    //console.log("Params:" + params);
    return this.http.get<any>(`${this.baseUrl}forms/CustomerLogin?${params}`);
  }


  delete(id: number) {
    return this.http.delete(`${this.baseUrl}forms/${id}`);
  }

  UpdateFormStatusSignAndSend(psrNo, signature) {
    return this.http.post(`${this.baseUrl}forms/UpdateFormStatusSignAndSend`, { 'psrNo': psrNo, 'Signature': signature });
  }

  GetAllFormsByAdvSearch(AdvancedSearch: any) {
    return this.http.post(`${this.baseUrl}forms/GetAllFormsByAdvSearch`, AdvancedSearch);
  }

  GetAllFormsByAdvanceSearch(AdvancedSearch: any) {
    return this.http.post(`${this.baseUrl}forms/GetAllFormsByAdvSearch`, AdvancedSearch);
  }

  GetAllFormsByAdvSearchForms(closureForm: ClosureForm, condition: string) {
    return this.http.post(`${this.baseUrl}forms/GetAllFormsByAdvSearchForms`, { closureForm, condition });
  }

  getSizesData(): Observable<ISizeData[]> {
   

    return this.http.get<ISizeData[]>(this.sizeDataUrl).pipe(
      /* tap(data => console.log('All size Data: ' + JSON.stringify(data)))*/
    );
  }

  getSizeData(id: number): Observable<ISizeData | undefined> {
  
    return this.getSizesData()
      .pipe(
        map((sizes: ISizeData[]) => sizes.find(p => p.id === id))
      );
  }
  GetAllFormsByAdvSearch2(Query: any, AdvancedSearch2: any, smeTabForAdvanceSearch:any) {
    //console.log("DBQuery:" + Query);
    return this.http.post(`${this.baseUrl}forms/GetAllFormsByAdvSearch2`, { Query, smeTabForAdvanceSearch });
  }

  updateSMEFormStatus(psrNo, statusValue, isApprovedByBackUpSME) {
    return this.http.post(`${this.baseUrl}forms/UpdateSMEFormStatus`, { 'psrNo': psrNo, 'statusValue': statusValue, 'IsApprovedByBackUpSME': isApprovedByBackUpSME});
  }

  getPendingFormsForBackUpSME() {
    //console.log(this.baseUrl + 'forms');
    return this.http.get<ClosureForm[]>(`${this.baseUrl}Forms/GetPendingFormsForBackUpSME`);

  }
  GeneratePsrLink(closureForm: any) {
    
    return this.http.post<RegeneratedPassword>(`${this.baseUrl}forms/ReGeneratePsrLink`, closureForm);
  }

  deletePsrLink(psrid: number) {
    
    return this.http.get<string>(`${this.baseUrl}forms/DeletePsrForm/${psrid}`);
    
  }

  SendEmail(closureForm: any) {

    return this.http.post(`${this.baseUrl}forms/EmailToCustomer`, closureForm);
  }

  ReGenerateLinkEmailToCustomer(closureForm: any) {

    return this.http.post(`${this.baseUrl}forms/ReGenerateLinkEmailToCustomer`, closureForm);
  }

  lockPSRForm(Id: number) {

    return this.http.get<string>(`${this.baseUrl}forms/LockPsrForm/${Id}`);
  }

  unlockPSRForm(Id: number) {

    return this.http.get<string>(`${this.baseUrl}forms/UnlockPsrForm/${Id}`);
  }



  //upload(closureForm: any) {
  //  return this.http.post(`${this.baseUrl}forms/Upload`, closureForm);
  //}

}
