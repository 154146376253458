import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators  } from '@angular/forms';
import { ClosureFormService } from '../services/closure-form.service';
import { Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';

@Component({
  selector: 'app-new-closure-form',
  templateUrl: './new-closure-form.component.html',
  styleUrls: ['./new-closure-form.component.css']
})
export class NewClosureFormComponent implements OnInit {
  closureForm;
  loading: boolean = false;
  constructor(private formBuilder: FormBuilder, private formService: ClosureFormService, private router: Router, private authService: AuthenticationService) {
    this.closureForm = this.formBuilder.group({
      customerEmail: ['', Validators.required],
      casManagerName: this.authService.currentUserValue,
      customerName: ['', Validators.required],
      customerPhone: '',
      customerLocation: ['', Validators.required],
      customerProduct: ['', Validators.required],
      initialOrderQuantity: ['', Validators.required],
      dateClosure: ['', Validators.required],
      silganCoc: ['', Validators.required],
      estimatedAnnualVolume: ['', Validators.required],
      specialIssues: ''
    });
  }

  ngOnInit() {
  }
  onSubmit() {


    // stop here if form is invalid
    if (this.closureForm.invalid) {
      return;
    }

    this.loading = true;
    this.formService.register(this.closureForm.value)
      .subscribe(
        data => {
          this.router.navigate(['/forms']);
        },
        error => {
          this.loading = false;
        });
  }
}
