import { Component, OnInit } from '@angular/core';
import { NgForm, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { User } from '../models/user';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../services/user.service';
import { ToastrService } from 'ngx-toastr';
import { stringTextValidator } from '../validators/stringText-validator';
import { phoneNumberValidator } from '../validators/phoneNumber-validator';
import { AuthenticationService } from '../services/authentication.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit {
  userId: number;
  currentUser: User;
  userEditForm: FormGroup;
  loading: boolean = false;
  submitted: boolean = false;

  dropdownList = [];
  dropdownSettings = {};
  selectedItems: any;

  get phone() {
    return this.userEditForm.get('phone');
  }
  get email() {
    return this.userEditForm.get('email');
  }
  get firstName() {
    return this.userEditForm.get('firstName');
  }
  get lastName() {
    return this.userEditForm.get('lastName');
  }
  get username() {
    return this.userEditForm.get('username');
  }
  get teamId() {
    return this.userEditForm.get('teamId');
  }
  get isAdmin() {
    return this.currentUser && this.currentUser.role === "Admin";
  }
  get isSME() {
    return this.currentUser && this.currentUser.role === "SME";
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private authenticationService: AuthenticationService


  ) {

    // values.forEach(values, function (item, i) {

    //   // you get object like this {id: "100033"}
    //   // so to access id do like normal object

    //   if (item.id) {
    //     // do something
    //     userId = +item.id;
    //   }

    // });
    // if(values.id){
    //   if (values.id != 0)
    //   this.getUser(values.id);
    // }

  }

  ngOnInit() {
    this.authenticationService.currentUser.subscribe(x => {
    //  console.log(x);
      this.currentUser = x;
    });

    this.userEditForm = this.formBuilder.group({
      userId: 0,
      username: ['', [Validators.required]],
      firstName: ['', [Validators.required, stringTextValidator]],
      lastName: ['', [Validators.required, stringTextValidator]],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required,Validators.minLength(10)]],
      role: ['Select Role'],
      teamId: 1,
      team: ['']
    });
    this.dropdownList = [
      { "id": 1, "itemName": "Metal (Steel)" },
      { "id": 2, "itemName": "Composite" },
      { "id": 3, "itemName": "ROPP (Alum)" },
      { "id": 4, "itemName": "Plastic" }
    ];
    this.dropdownSettings = {
    };
    //this.userEditForm.get('username').disable();
    let values = JSON.parse(localStorage.getItem("currentUser"));
    // console.log(values);
    this.userId = values.id;
    // let values = JSON.parse(localStorage.getItem("currentUser"));
    // this.userId = values.id;



    //this.getUser(this.route.snapshot.params['id']);
    // this.userEditForm = this.formBuilder.group({
    //   userId: 0,
    //   username: [''],
    //   firstName: ['', Validators.required],
    //   lastName: ['', Validators.required],
    //   email: ['', Validators.required],
    //   phone: ['', Validators.required],
    //   role: ['Select Role'],
    //   teamId: 1
    // });
    // this.userEditForm.get('username').disable();

    if (values.id) {
      if (values.id != 0)
        this.getUser(values.id);
    }
    if(values.role == 'SME')
    {
      this.disable();
    }
    else 
    {
      this.enable();
    }
    this.userEditForm.controls['role'].disable();
    this.userEditForm.controls['teamId'].disable();
  }
  disable() {
    
    this.dropdownSettings = {
      singleSelection: false,
      text: "Select Closure Material Team",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: false,
      classes: "myclass custom-class",
      disabled: true
    };
    // this.selectedItems["SME"] = [];

  }

  enable() {
    this.dropdownSettings = {
      singleSelection: false,
      text: "Select Closure Material Team",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: false,
      classes: "myclass custom-class",
      disabled: false
    };
  }
  getUser(id: number) {
    this.userService.getById(id).subscribe(data => 
      {
      this.userEditForm.setValue({
        userId: id,
        username: data.username,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        phone: data.phone,
        role: data.role,
        teamId: data.teamId,
        team: ''
      });
      //console.log(data.teamId);

      var output = data.teamId.split('');
      var tempArray = [];
      for (let i = 0; i < output.length; i++) {
          let id = output[i];       
          if (id == "1") {
            var obj = new Object();
            obj['id'] = 1;
            obj['itemName'] = 'Metal (Steel)';          
            tempArray.push(obj);
          }
          if (id == "2") {
            var obj = new Object();
            obj['id'] = 2;
            obj['itemName'] = 'Composite';
           // console.log(obj);         
            tempArray.push(obj);
            
          }
          if (id == "3") {
            var obj = new Object();
            obj['id'] = 3;
            obj['itemName'] = 'ROPP (Alum)';
            tempArray.push(obj);
          }
          if (id == "4") {
            var obj = new Object();
            obj['id'] = 4;
            obj['itemName'] = 'Plastic';
            tempArray.push(obj);
          }
          this.selectedItems = tempArray;
      }
    });
  }
  onlyNumberValid(event: any) {
    var charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 45 && charCode > 31
      && (charCode < 48 || charCode > 57))
      return false;

    return true;
  }
  //NumberNotValid(event: any) {
  //  var charCode = (event.which) ? event.which : event.keyCode;
  //  if (charCode != 45 && charCode > 31
  //    && (charCode < 48 || charCode > 57))
  //    return true;

  //  return else;
  //}

  onSubmit(form: any) {
    
    //this.submitted = true;
    if (this.userEditForm.invalid) {
      
      this.submitted = true;
      return;
    }
    this.userEditForm.get('username').enable();
   // console.log("summitted form:" + JSON.stringify(form));
    this.loading = true;
    this.userService.update(form)
      .subscribe(x => {
        this.loading = false;
        this.toastr.success("Your changes successfully saved.");
        //console.log(x);
        // this.router.navigate(['/'])
      },
        error => {
         // console.log(error);
          this.loading = false;
          this.toastr.error(error);
        });
  }

}
