import { Component, OnInit } from '@angular/core';
import { ClosureFormService } from '../services/closure-form.service';
import { ClosureForm } from '../models/closure-form';

@Component({
  selector: 'app-form-list-save-draft',
  templateUrl: './form-list-save-draft.component.html',
  styleUrls: ['./form-list-save-draft.component.css']
})
export class FormListSaveDraftComponent implements OnInit {
  headElements = ['PSR Form ID', 'Customer Name', 'Customer Email', 'Location', 'Closure Material'];
  closureMaterials = [{ id: 1, name: 'Metal (Steel)' }, { id: 2, name: 'Plastic' }, { id: 3, name: 'ROPP (Alum)' }];
  closureForms: ClosureForm[] = [];
  constructor(
    private closureFomrService: ClosureFormService) {
    this.loadAllUsers();
  }
  ngOnInit() {

  }

  private loadAllUsers() {

    this.closureFomrService.getAllSaveDraft().subscribe(closureForms => this.closureForms = closureForms);

  }
  getClosureMaterial(id: number) {
    return this.closureMaterials.find(d => d.id == id).name;
  }
}
