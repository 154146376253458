import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ClosureForm } from 'src/app/models/closure-form';
import { ClosureFormService } from 'src/app/services/closure-form.service';
import { Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { User } from 'src/app/models/user';
declare var $: any;


@Component({
  selector: 'app-process',
  templateUrl: './process.component.html',
  styleUrls: ['./process.component.css']
})
export class ProcessComponent implements OnInit {
  loginUser: User;
  closureForm;
  loading: boolean = false;
  formData: ClosureForm;
  @Input() formId: number;
  @Output() formCompleted = new EventEmitter();
  showSubmitButton = true;
  formSearch: boolean = false;
  submitted = false;
  IsPasturizationTimeValid = true;
  IswaterTempValid = true;
  IsproductTempValid = true;
  IsprocessPressureValid = true;
  IscoolingPSIGValid = true;
  IsprocessOverridingValid = true;
  IsholdTimeValid = true;
  IsholdTimeInMinutes = true;
  IscapperManufacturerNameValid = true;
  successMessage = "";
  chkzero : boolean = true;
  
  get packerNameAddress() {
    return this.closureForm.get('packerNameAddress');
  }
  get packerNameAndAddress() {
    return this.closureForm.get('packerNameAndAddress');
  }
  get selectedWithPressure() {
    return this.closureForm.get('selectedWithPressure');
  }
  get pressureApplied() {
    return this.closureForm.get('pressureApplied');
  }

  get capperManufacturer() {
    return this.closureForm.get('capperManufacturer');
  }
  get packageProcess() {
    return this.closureForm.get('packageProcess');
  }
  get closureSterilization() {
    return this.closureForm.get('closureSterilization');
  }
  get sealedWithPressure() {
    return this.closureForm.get('sealedWithPressure');
  }
  get customerClosureMaterial() {
    return this.closureForm.get('customerClosureMaterial');
  }
  get typeOfCapper() {
    return this.closureForm.get('typeOfCapper');
  }
  get headspacePercentage() {
    return this.closureForm.get('headspacePercentage');
  }
  get productFillTemperature() {
    return this.closureForm.get('productFillTemperature');
  }
  get pasturizationTime() {
    return this.closureForm.get('pasturizationTime');
  }

  get waterTemp() {
    return this.closureForm.get('waterTemp');
  }

  get productTemp() {
    return this.closureForm.get('productTemp');
  }

  get holdTime() {
    return this.closureForm.get('holdTime');
  }
  get capperManufacturerName() {
    return this.closureForm.get('capperManufacturerName');
  }
  

  get processPressure() {
    return this.closureForm.get('processPressure');
  }
  get coolingPSIG() {
    return this.closureForm.get('coolingPSIG');
  }
  get processOverriding() {
    return this.closureForm.get('processOverriding');
  }
  get processPressureType() {
    return this.closureForm.get('processPressureType');
  }

  get pressuredBy() {
    return this.closureForm.get('pressuredBy');
  }

  get LN2() {
    return this.closureForm.get('LN2');
  }
  get isitGasLiquid() {
    return this.closureForm.get('isitGasLiquid');
  }

  get holdTimeMinutes() {
    return this.closureForm.get('holdTimeMinutes');
  }
   
  constructor(private formBuilder: FormBuilder, private formService: ClosureFormService, private authService: AuthenticationService, private router: Router) {
    this.closureForm = this.formBuilder.group({
      psrNo: '',
      packerNameAddress: ['', Validators.required],
      packerNameAndAddress: ['', Validators.required],
      headspacePercentage: ['', Validators.required],
      productFillTemperature: ['', Validators.required],
      sealedWithPressure: ['No'],
      pressuredBy: [''],
     pressureAmount: [''],
     // pressureApplied: ['Capper With Steam', Validators.required],
      pressureApplied: ['', Validators.required],
      typeOfCapper: ['Straight Line'],
      heatingMethod: ['Straight line'],
      capperManufacturer: ['Crown'],
      arrangCapperToolWithField1: ['No'],
      arrangCapperToolWithField2: ['No'],
      capperManufacturerName: [''],
      packageProcess: ['Other - Please describe', Validators.required],
      processPressure: [''],
      coolingPSIG: [''],
      processOverriding: [''],
      cookedBy: [''],
      processPressureType: [''],
      finishedPackageVacuum: [''],
      finishedPackagePressure: [''],
      finishedPackagePSI: [''],
      closureSterilization: [''],
      chemicalName: [''],
      doNeckBandsCoverSidewall: ['No'],
      volumesCO2: [''],
      chemicalConcentration: [''],
      isDudDetectionAvailable: ['No'],
      packageSterilizedHPP: ['No'],
      outOfCapperVacuumInHg: [''],
      chuckType: [''],
      waterTemp: [''],
      pasturizationTime: [''],
      productTemp: [''],
      customerClosureMaterial: 'Composite',
      holdTime: [''],
      holdTimeMinutes: [''],
      packagingProcessOtherDescription: [''],
      rateOfRotation: [''],
      LN2: [''],
      isitGasLiquid:['']
    });
  }

  ngOnInit() {
   
    this.loginUser = this.authService.currentUserValue;
    let currentURL = this.router.url;
    if (currentURL.includes("formsearch")) {
      this.formSearch = true;
    }
    if (this.formId) {
      if (currentURL.includes("formhistorystepper")) {
        this.formService.getFormHistoryById(this.formId).subscribe(this.onSubscribeSuccess);
        this.closureForm.disable();
        this.showSubmitButton = false;
      }
      else {
        this.formService.getById(this.formId).subscribe(this.onSubscribeSuccess);
      }
    }


  }
  onlyNumberValidzero(evt: any) {
    this.chkzero = true
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    var getvalue = this.closureForm.get('headspacePercentage').value;
    if (getvalue == "") {
      if (charCode == 48) {
        this.chkzero= false
        return false
      }
    }
    
    if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57)) {
      if (charCode == 37)
        return true;
      else
        return false;
    }
    else
      return true;
  }

  onlyNumberValid1(evt: any) {
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57)) {
      if (charCode == 37)
        return true;
      else
        return false;
    }   
    else
      return true;
  }
  oncleanCo2() {
    let getpressuredBy = this.closureForm.get('pressuredBy').value;
    if (getpressuredBy == "CO2") {
      this.closureForm.patchValue({      
        isitGasLiquid: " "
      });
    }
}
  onChanged() {
    let getpressureApplied = this.closureForm.get('pressureApplied').value;
    if (getpressureApplied == "Capper With Steam") {
      this.closureForm.patchValue({
        typeOfCapper: "Straight Line",
        capperManufacturer: "Crown",
        chuckType: ""

      });
    }
    //if (getpressureApplied == 'Capper Without Steam') {
    //  this.closureForm.patchValue({
    //    typeOfCapper: "",
    //    capperManufacturer: ""
    //  });
    //} 
   //else {
   //   this.closureForm.patchValue({
   //     typeOfCapper: "Straight Line"
   //   });
   // }
  }
  onclosureSterilizationchange() {    
      this.closureForm.patchValue({
        closureSterilization: ""
      });
    

  }
  onchemicalchange() {
    let getclosureSterilization = this.closureForm.get('closureSterilization').value;
    if (getclosureSterilization == 'Chemical') {
      this.closureForm.patchValue({
        chemicalName: "",
        chemicalConcentration:""
      });
    }
    if (getclosureSterilization == 'None') {
      this.closureForm.patchValue({
        chemicalName: "",
        chemicalConcentration: ""
      });
    }

  }
  onChanges() {   
    let typeOfCapper = this.closureForm.get('typeOfCapper').value;
    if (typeOfCapper == 'Straight Line') {
      this.closureForm.patchValue({
        capperManufacturer: "Crown",
        chuckType: ""
      });
    }
    if (typeOfCapper == 'Rotary') {
      this.closureForm.patchValue({
        capperManufacturer: "Arol",
        chuckType: "Segmented"
      });
    }
    else {
      this.closureForm.patchValue({
        capperManufacturer: "Crown",
        chuckType:""
      });
    }
  }
  onSearchForCurrent() {

  }

  onSearchForAllFormFields() {

  }

  onSearchCancel() {

  }
  onSubscribeSuccess = data => {
    //console.log("Form Data: " + JSON.stringify(data));
    this.formData = data;
    
    
    this.closureForm.setValue({
      //customerEmail: this.formData.customerEmail,
      psrNo: this.formId,
      packerNameAddress: this.formData.packerNameAddress,
      packerNameAndAddress: this.formData.packerNameAndAddress,
      headspacePercentage: this.formData.headspacePercentage,
      productFillTemperature: this.formData.productFillTemperature,
      sealedWithPressure: this.formData.sealedWithPressure == '' ? 'No' : this.formData.sealedWithPressure,
      /*pressuredBy: this.formData.pressuredBy == '' ? 'CO2' : this.formData.pressuredBy,*/
      pressuredBy: this.formData.pressuredBy,
      LN2: this.formData.LN2 == null ? 'Gas' : this.formData.LN2,
      pressureAmount: this.formData.pressureAmount,  
      pressureApplied: this.formData.pressureApplied == '' ? 'Capper With Steam' : this.formData.pressureApplied,
      typeOfCapper: this.formData.typeOfCapper == '' ? 'Straight Line' : this.formData.typeOfCapper,    
    /*  typeOfCapper: this.formData.typeOfCapper ==  this.formData.typeOfCapper,*/
      heatingMethod: this.formData.heatingMethod == '' ? 'Straight line' : this.formData.heatingMethod,
      capperManufacturer: this.formData.capperManufacturer == "" ? 'Crown' : this.formData.capperManufacturer == '' ? 'Crown' : this.formData.capperManufacturer,
      arrangCapperToolWithField1: this.formData.arrangCapperToolWithField1 == '' ? 'No' : this.formData.arrangCapperToolWithField1,
      arrangCapperToolWithField2: this.formData.arrangCapperToolWithField2 == '' ? 'No' : this.formData.arrangCapperToolWithField2,
      capperManufacturerName: this.formData.capperManufacturerName,
      packageProcess: this.formData.packageProcess == '' ? 'Other - Please describe' : this.formData.packageProcess,
      processPressure: this.formData.processPressure,
      coolingPSIG: this.formData.coolingPSIG,
      processOverriding: this.formData.processOverriding,
      /* cookedBy: this.formData.cookedBy == '' ? 'Water' : this.formData.cookedBy,*/
      cookedBy: this.formData.cookedBy,
      /* processPressureType: this.formData.processPressureType == '' ? 'Rotomat' : this.formData.processPressureType,*/
      processPressureType: this.formData.processPressureType,
      finishedPackageVacuum: this.formData.finishedPackageVacuum,
      finishedPackagePressure: this.formData.finishedPackagePressure,
      finishedPackagePSI: this.formData.finishedPackagePSI == null ? '' : this.formData.finishedPackagePSI,
      //closureSterilization: this.formData.closureSterilization == '' ? 'None' : this.formData.closureSterilization,
      //chemicalName: this.formData.chemicalName == '' ? 'None' : this.formData.chemicalName,
      closureSterilization: this.formData.closureSterilization,
      chemicalName: this.formData.chemicalName,
      doNeckBandsCoverSidewall: this.formData.doNeckBandsCoverSidewall == '' ? 'No' : this.formData.doNeckBandsCoverSidewall,
      volumesCO2: this.formData.volumesCO2,
      chemicalConcentration: this.formData.chemicalConcentration,
      isDudDetectionAvailable: this.formData.isDudDetectionAvailable == '' ? 'No' : this.formData.isDudDetectionAvailable,
      packageSterilizedHPP: this.formData.packageSterilizedHPP,
      outOfCapperVacuumInHg: this.formData.outOfCapperVacuumInHg,
      chuckType: this.formData.chuckType == '' ? '' : this.formData.chuckType,
      waterTemp: this.formData.waterTemp,
      pasturizationTime: this.formData.pasturizationTime,
      productTemp: this.formData.productTemp == '' ? '' : this.formData.productTemp,
      holdTime: this.formData.holdTime ,
      customerClosureMaterial: data.customerClosureMaterial,
      packagingProcessOtherDescription: this.formData.packagingProcessOtherDescription,
      rateOfRotation: this.formData.rateOfRotation,
      isitGasLiquid: this.formData.isitGasLiquid,
      holdTimeMinutes: this.formData.holdTimeMinutes,
      //this.closureForm.disable();
    });
    let currentURL = this.router.url;
    if (!currentURL.includes("formhistorystepper")) {

      if (this.loginUser != null && this.loginUser != undefined) {
    //    console.log("customer role" + this.loginUser.role);
        if (this.loginUser.role == 'Admin') {
          
          this.showSubmitButton = true;
          this.closureForm.enable();
        }
        else if (this.loginUser.role == 'Viewer') {
          this.showSubmitButton = false;
          this.closureForm.disable();
        } 
        else if (this.loginUser.role == 'CAS') {
      
          if (this.formData.status == 3 || this.formData.status == 1) {
            this.showSubmitButton = true;
            this.closureForm.enable();
          }
          else {
            this.showSubmitButton = false;
            this.closureForm.disable();
          }
        } else if (this.loginUser.role == 'SME') {
     
          //if (this.formData.status == 4 && this.formData.customerClosureMaterial == 'Composite' && this.loginUser.teamId == '2') {
          //  this.showSubmitButton = true;
          //  this.closureForm.enable();
          //}
          //else if (this.formData.status == 4 && this.formData.customerClosureMaterial == 'Plastic' && this.loginUser.teamId == '4') {
          //  this.showSubmitButton = true;
          //  this.closureForm.enable();
          //}
          //else if (this.formData.status == 4 && this.formData.customerClosureMaterial == 'Metal (Steel)' && this.loginUser.teamId == '1') {
          //  this.showSubmitButton = true;
          //  this.closureForm.enable();
          //}
          //else if (this.formData.status == 4 && this.formData.customerClosureMaterial == 'ROPP (Alum)' && this.loginUser.teamId == '3') {
          //  this.showSubmitButton = true;
          //  this.closureForm.enable();
          //}

          if (this.formData.status == 4 && this.formData.customerClosureMaterial == 'Composite') {
            var output = this.loginUser.teamId.split('');
            for (let i = 0; i < output.length; i++) {
              let id = output[i];
              if (id == "2") {
                this.showSubmitButton = true;
                this.closureForm.enable();
              }
            }
          }
          else if (this.formData.status == 4 && this.formData.customerClosureMaterial == 'Plastic') {
            var output = this.loginUser.teamId.split('');
            for (let i = 0; i < output.length; i++) {
              let id = output[i];
              if (id == "4") {
                this.showSubmitButton = true;
                this.closureForm.enable();
              }
            }
          }
          else if (this.formData.status == 4 && this.formData.customerClosureMaterial == 'Metal (Steel)') {
            var output = this.loginUser.teamId.split('');
            for (let i = 0; i < output.length; i++) {
              let id = output[i];
              if (id == "1") {
                this.showSubmitButton = true;
                this.closureForm.enable();
              }
            }
          }
          else if (this.formData.status == 4 && this.formData.customerClosureMaterial == 'ROPP (Alum)') {
            var output = this.loginUser.teamId.split('');
            for (let i = 0; i < output.length; i++) {
              let id = output[i];
              if (id == "3") {
                this.showSubmitButton = true;
                this.closureForm.enable();
              }
            }

          }

          else {
            this.showSubmitButton = false;
            this.closureForm.disable();
          }
        }
      }
      else {
        if (this.formData.status == 2) {
        }
        else {
          this.showSubmitButton = false;
          this.closureForm.disable();
        }
      }
    }

    if (this.formData.isFormLock == 1) {
      if (this.loginUser != null && this.loginUser != undefined) {
        if (this.loginUser.role == "Admin") {
          this.showSubmitButton = false;
          this.closureForm.disable();
        } else if (this.loginUser.role == "Viewer") {
          this.showSubmitButton = false;
          this.closureForm.disable();
        } else if (this.loginUser.role == "CAS") {
          if (this.formData.status == 3 || this.formData.status == 1) {
            this.showSubmitButton = false;
            this.closureForm.disable();
          } else {
            this.showSubmitButton = false;
            this.closureForm.disable();
          }
        } else if (this.loginUser.role == "SME") {
          if (
            this.formData.status == 4 &&
            this.formData.customerClosureMaterial == "Composite"
          ) {
            var output = this.loginUser.teamId.split("");
            for (let i = 0; i < output.length; i++) {
              let id = output[i];
              if (id == "2") {
                this.showSubmitButton = false;
                this.closureForm.disable();
              }
            }
          } else if (
            this.formData.status == 4 &&
            this.formData.customerClosureMaterial == "Plastic"
          ) {
            var output = this.loginUser.teamId.split("");
            for (let i = 0; i < output.length; i++) {
              let id = output[i];
              if (id == "4") {
                this.showSubmitButton = false;
                this.closureForm.disable();
              }
            }
          } else if (
            this.formData.status == 4 &&
            this.formData.customerClosureMaterial == "Metal (Steel)"
          ) {
            var output = this.loginUser.teamId.split("");
            for (let i = 0; i < output.length; i++) {
              let id = output[i];
              if (id == "1") {
                this.showSubmitButton = false;
                this.closureForm.disable();
              }
            }
          } else if (
            this.formData.status == 4 &&
            this.formData.customerClosureMaterial == "ROPP (Alum)"
          ) {
            var output = this.loginUser.teamId.split("");
            for (let i = 0; i < output.length; i++) {
              let id = output[i];
              if (id == "3") {
                this.showSubmitButton = false;
                this.closureForm.disable();
              }
            }
          } else {
            this.showSubmitButton = false;
            this.closureForm.disable();
          }
        }
      } else {
        if (this.formData.status == 2) {
          this.showSubmitButton = false;
          this.closureForm.disable();
        } else {
          this.showSubmitButton = false;
          this.closureForm.disable();
        }
      }
    }

  }
  onlyNumberValid(evt: any) {
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode != 45 && charCode > 31
      && (charCode < 48 || charCode > 57))
      return false;

    return true;
  }
  onSubmit() {
    let getpressureApplied = this.closureForm.get('pressureApplied').value;
    let getprocessPressureType = this.closureForm.get('processPressureType').value;
    let getcookedBy = this.closureForm.get('cookedBy').value;
    let getchemicalName= this.closureForm.get('chemicalName').value;
    let getsealedWithPressure = this.closureForm.get('sealedWithPressure').value;
    let gettypeOfCapper = this.closureForm.get('typeOfCapper').value;
    if (gettypeOfCapper == true) {
      this.closureForm.patchValue({
        typeOfCapper: "",

      });

    }
    if (getprocessPressureType == true) {
      this.closureForm.patchValue({
        processPressureType: "",

      });

    }
    if (getcookedBy == true) {
      this.closureForm.patchValue({
        cookedBy: "",

      });

    }
    if (getsealedWithPressure == '') {
      this.closureForm.patchValue({
        pressuredBy: "",

      });

    }
    if
      (getsealedWithPressure == 'No') {
      this.closureForm.patchValue({
        pressuredBy: "",

      });

    }
    if (getsealedWithPressure == '') {
      this.closureForm.patchValue({
        pressureAmount: "",
      });

    }
    if
      (getsealedWithPressure == 'No') {
      this.closureForm.patchValue({
        pressureAmount: "",
      });

    }
    if (getpressureApplied == 'Hand') {
      this.closureForm.patchValue({
        capperManufacturer: "",
        typeOfCapper: "",
        chuckType: ""

      });
    }
   
    if (getchemicalName == true) {
      this.closureForm.patchValue({
        chemicalName: "",

      });

    }



    if (this.formData.customerClosureMaterial == 'ROPP (Alum)') {
      this.closureForm.patchValue({
        pressureApplied: "Capper Without Steam",
        typeOfCapper: "",
        chuckType: ""
      });

    }
    console.log(this.closureForm.value);
    this.submitted = true;
    var chk = 0;
  //  console.log(this.closureForm.value);
    if (this.closureForm.value.packageProcess == "03 - Pasteurization - Continuous or Open Bath. For Acid Products." && this.closureForm.value.pasturizationTime == "") {
      this.IsPasturizationTimeValid = false;
      //return;
      chk = 1; 

    } else {
      this.IsPasturizationTimeValid = true;
    }
    if (this.closureForm.value.packageProcess == "03 - Pasteurization - Continuous or Open Bath. For Acid Products." && this.closureForm.value.waterTemp == "") {
      this.IswaterTempValid = false;
    //  return;
      chk = 1; 

    } else {
      this.IswaterTempValid = true;
    }
    if (this.closureForm.value.packageProcess == "03 - Pasteurization - Continuous or Open Bath. For Acid Products." && this.closureForm.value.productTemp == "") {
      this.IsproductTempValid = false;
      //  return;
      chk = 1;

    } else {
      this.IsproductTempValid = true;
    }

    //if ((this.closureForm.value.packageProcess == "03 - Pasteurization - Continuous or Open Bath. For Acid Products." || this.closureForm.value.packageProcess == "14 - Closed Retort A (212-240°F)" || this.closureForm.value.packageProcess == "15 - Closed Retort B (240-252°F)") && (this.closureForm.value.waterTemp != "" || this.closureForm.value.pasturizationTime != "" || this.closureForm.value.productTemp != "")) {
    //  this.closureForm.value.waterTemp = "";
    //  this.closureForm.value.pasturizationTime = "";
    //  this.closureForm.value.productTemp = "";
    //}
    //else if ((this.closureForm.value.packageProcess == "12 - Hot Fill – A. Force Cool" || this.closureForm.value.packageProcess == "12 - Hot Fill – B. Hold & Force Cool") && this.closureForm.value.productTemp != "") {
    //  this.closureForm.value.productTemp = "";
    //}

    if((this.closureForm.value.customerClosureMaterial == "Composite") || (this.closureForm.value.customerClosureMaterial == "Plastic" || this.closureForm.value.customerClosureMaterial == 'ROPP (Alum)' || this.closureForm.value.customerClosureMaterial == 'Metal (Steel)')) {
      if (this.closureForm.value.packageProcess == "02 - Hot Fill (For Acid Products)- B. Hold & Force Cool" && this.closureForm.value.holdTimeMinutes == "") {
        this.IsholdTimeInMinutes = false;
        //  return;
        chk = 1;
      }
      else {
        this.IsholdTimeInMinutes = true;
      }
    }



    if ((this.closureForm.value.customerClosureMaterial == "Composite") || (this.closureForm.value.customerClosureMaterial== "Plastic")) {
      if (this.closureForm.value.packageProcess == "02 - Hot Fill (For Acid Products)- B. Hold & Force Cool" && this.closureForm.value.holdTime == "") {
        this.IsholdTimeValid = false;
        //  return;
        chk = 1;
      }
      else {
        this.IsholdTimeValid = true;
      }
    }
    
    

    if (this.closureForm.value.capperManufacturer == "Other" && this.closureForm.value.capperManufacturerName == "") {
      this.IscapperManufacturerNameValid = false;
      //  return;
      chk = 1;

    } else {
      this.IscapperManufacturerNameValid = true;
    }

    

  

    if ((this.closureForm.value.packageProcess == "04 - Closed Retort A .Twist-Off Closures - Pressure is developed outside the package and cooking temperatures range from 212ºF (100ºC) and 240 ºF (115.6ºC)" ||
      this.closureForm.value.packageProcess == "05 - Closed Retort B - Twist-Off Closures - Closed Retort - Pressure is developed outside the package and cooking temperatures exceed 240º F (115.6º C) but do not exceed 252º F (122.2º C).") && this.closureForm.value.processPressure == "") {
      this.IsprocessPressureValid = false;
      //  return;
      chk = 1;

    } else {
      this.IsprocessPressureValid = true;
    }

    if ((this.closureForm.value.packageProcess == "04 - Closed Retort A .Twist-Off Closures - Pressure is developed outside the package and cooking temperatures range from 212ºF (100ºC) and 240 ºF (115.6ºC)" ||
      this.closureForm.value.packageProcess == "05 - Closed Retort B - Twist-Off Closures - Closed Retort - Pressure is developed outside the package and cooking temperatures exceed 240º F (115.6º C) but do not exceed 252º F (122.2º C).")
      && this.closureForm.value.processPressure == "") {
      this.IsprocessPressureValid = false;
      //  return;
      chk = 1;

    } else {
      this.IsprocessPressureValid = true;
    }

    if ((this.closureForm.value.packageProcess == "04 - Closed Retort A .Twist-Off Closures - Pressure is developed outside the package and cooking temperatures range from 212ºF (100ºC) and 240 ºF (115.6ºC)" ||
      this.closureForm.value.packageProcess == "05 - Closed Retort B - Twist-Off Closures - Closed Retort - Pressure is developed outside the package and cooking temperatures exceed 240º F (115.6º C) but do not exceed 252º F (122.2º C).")
      && this.closureForm.value.coolingPSIG == "") {
      this.IscoolingPSIGValid = false;
      //  return;
      chk = 1;

    } else {
      this.IscoolingPSIGValid = true;
    }
    if ((this.closureForm.value.packageProcess == "04 - Closed Retort A .Twist-Off Closures - Pressure is developed outside the package and cooking temperatures range from 212ºF (100ºC) and 240 ºF (115.6ºC)" ||
      this.closureForm.value.packageProcess == "05 - Closed Retort B - Twist-Off Closures - Closed Retort - Pressure is developed outside the package and cooking temperatures exceed 240º F (115.6º C) but do not exceed 252º F (122.2º C).")
      && this.closureForm.value.processOverriding == "") {
      this.IsprocessOverridingValid = false;
      //  return;
      chk = 1;

    } else {
      this.IsprocessOverridingValid = true;
    }
 
    if (chk == 1) {
      return;
    }

   // console.log('test');
    //debugger;
    // stop here if form is invalid
    if (this.closureForm.invalid) {
      $("#Processformsubmit-modal").modal("show");
      return;
    }
    this.closureForm.disable();
    this.loading = true;
   // console.log('test');
    this.formData.processCompleted = true;

    this.formService.SaveProcessFormData(this.closureForm.value)
      .subscribe(
        data => {
          this.loading = false;
          this.closureForm.enable();
          localStorage.setItem("dataSource", JSON.stringify(this.formData));

          this.formCompleted.emit(true);
          //this.router.navigate(['/formsList']);
        },
        error => {
          this.closureForm.enable();
          this.loading = false;
        });
  }

  onprocessOk() {
    
    $("#Processformsubmit-modal").modal("hide");
    this.router.navigate(["/formstepper/" + this.closureForm.get("pSRNo").value]);
  }

  Ondropdownchange() {
        if ((this.closureForm.value.packageProcess == "03 - Pasteurization - Continuous or Open Bath. For Acid Products." || this.closureForm.value.packageProcess == "14 - Closed Retort A (212-240°F)" || this.closureForm.value.packageProcess == "15 - Closed Retort B (240-252°F)") && (this.closureForm.value.waterTemp != "" || this.closureForm.value.pasturizationTime != "" || this.closureForm.value.productTemp != "")) {
          this.closureForm.get('waterTemp').setValue('');
          this.closureForm.get('pasturizationTime').setValue('');
          this.closureForm.get('productTemp').setValue('');
    }
    else if ((this.closureForm.value.packageProcess == "12 - Hot Fill – A. Force Cool" || this.closureForm.value.packageProcess == "12 - Hot Fill – B. Hold & Force Cool") && this.closureForm.value.productTemp != "") {
          this.closureForm.get('productTemp').setValue('');
    }
  }
}


