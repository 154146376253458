import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CdkStepperModule } from '@angular/cdk/stepper';

import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { CounterComponent } from './counter/counter.component';
import { FetchDataComponent } from './fetch-data/fetch-data.component';
import { JwtInterceptor } from './helpers/jwt.interceptor';
import { ErrorInterceptor } from './helpers/error.interceptor';

import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { appRoutingModule } from './app.routing';
import { UserListComponent } from './user-list/user-list.component';
import { EditUserComponent } from './edit-user/edit-user.component';
import { SpecificationReviewAddEdit } from './specification-review-add-edit/specification-review-add-edit.component';
import { FormListComponent } from './form-list/form-list.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { NewClosureFormComponent } from './new-closure-form/new-closure-form.component';
import { ProfileComponent } from './form-step/profile/profile.component';
import { PackageComponent } from './form-step/package/package.component';
import { ProductComponent } from './form-step/product/product.component';
import { ProcessComponent } from './form-step/process/process.component';
import { PalletizationComponent } from './form-step/palletization/palletization.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatRadioModule } from '@angular/material/radio';
import { FormListSaveDraftComponent } from './form-list-save-draft/form-list-save-draft.component';
import { forgotPasswordComponent } from './users/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './users/reset-password/reset-password.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatStepperModule } from '@angular/material/stepper';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FormStepperComponent } from './form-step/form-stepper/form-stepper.component';
import { MatDialogModule } from '@angular/material/dialog';

import { CustomerLoginComponent } from './customer-login/customer-login.component';
import { CustomStepperComponent } from './form-step/custom-stepper/custom-stepper.component';
import { FormSummaryComponent } from './form-step/form-summary/form-summary.component';

import { ThankComponent } from './form-step/thank/thank.component';
import { AdvanceSearchComponent } from './advance-search/advance-search.component';
import { AlertModule } from './alert/alert.module';
import { FormHistoryComponent } from './form-history/form-history.component';
import { ToastrModule } from 'ngx-toastr';
import { SignAndSendComponent } from './form-step/sign-and-send/sign-and-send.component';
import { PhoneMask } from '../app/form-step/profile/phone-mask';
import { CurrencyPipe } from '@angular/common';

import { CustomerHomeComponent } from "./customer-Home/customer-Home.component";

import { HttpModule } from '@angular/http';
import { FormSearchComponent } from './formsearch/formsearch.component';

import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { NgxPaginationModule } from 'ngx-pagination';
import { MatTabsModule } from '@angular/material/tabs';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    CounterComponent,
    FetchDataComponent,
    LoginComponent,
    RegisterComponent,
    UserListComponent,
    EditUserComponent,
    SpecificationReviewAddEdit,
    FormListComponent,
    UserProfileComponent,
    ChangePasswordComponent,
    NewClosureFormComponent,
    ProfileComponent,
    PackageComponent,
    ProductComponent,
    ProcessComponent,
    PalletizationComponent,
    FormListSaveDraftComponent,
    forgotPasswordComponent,
    ResetPasswordComponent,
    FormStepperComponent,
    CustomerLoginComponent,
    CustomStepperComponent,
    FormSummaryComponent,
    CustomerHomeComponent,
    ThankComponent,

    AdvanceSearchComponent,
    FormSearchComponent,
    FormHistoryComponent,

    SignAndSendComponent,
    PhoneMask
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    ToastrModule.forRoot(),
    AlertModule,
    MatProgressSpinnerModule,
    CdkStepperModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    appRoutingModule,
    BrowserAnimationsModule,
    MatRadioModule,
    MatStepperModule,
    MatCheckboxModule,
    MatDialogModule,
    HttpModule,
    NgxPaginationModule,
    AngularMultiSelectModule,
    MatTabsModule,
    NgxSpinnerModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    CurrencyPipe
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],

  exports: [
    PhoneMask
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
