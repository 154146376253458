import { Component, OnInit } from "@angular/core";
import { ClosureFormService } from "../services/closure-form.service";
import { ClosureForm } from "../models/closure-form";
import { AuthenticationService } from "../services/authentication.service";
import { User } from "../models/user";
import { debug, debuglog } from "util";
import { FileService } from "../../app/services/file.service";
import * as fileSaver from "file-saver";
import { HttpResponse } from "@angular/common/http";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl } from "@angular/forms";
import { RegeneratedPassword } from "../models/RegeneratedPassword";
import { ToastrService } from "ngx-toastr";
import { error } from "protractor";
import { DeleteForm } from "./DeleteForm";
import { ISizeData } from "../models/sizedata";
import { NgxSpinnerService } from "ngx-spinner";
declare var $: any;

@Component({
  selector: "app-form-list",
  templateUrl: "./form-list.component.html",
  styleUrls: ["./form-list.component.css"],
})
export class FormListComponent implements OnInit {
  config: any;
  headElements = [
    "PSR Form ID",
    "Customer Name",
    /*"Customer Email",*/
    "Location",
    "Size",
    "Type",
    "Product",
    "Closure Material",
    "Status",
    "Actions",
    "Export",
    "Form Summary",

  ];
  public form: FormGroup;
  closureForms: ClosureForm[] = [];
  filteredForms: ClosureForm[] = [];
  backupClosureForms: ClosureForm[] = [];
  backupfilteredForms: ClosureForm[] = [];
  statusIdForFilter: number = 0;
  statusIdForFilterBackupForms: number = 0;
  materialForFilter: string;
  materialForFilterBackupForms: string;
  _listFilter = "";
  _listFilterBackupForms = "";
  selectPSRFormNo: string;
  selectPSRFormNoStatus: string;
  previousSortColumn: string;
  loading = false;
  currentUser: User;
  IsAdvancedFilterApplied: boolean = false;
  IsAdvancedFilterAppliedBackupForms: boolean = false;
  regeneratedPsrLink: any;
  regeneratedPsrPassword: any;
  getid: any;
  regeneratedLinkForm: RegeneratedPassword;
  deleteform: DeleteForm;
  sizeData = [];
  Isformlock: boolean;
  get listFilter(): string {
    return this._listFilter;
  }
 
  set listFilter(value: string) {
    this._listFilter = value;
    this.filteredForms = this.listFilter
      ? this.performFilter(this.listFilter)
      : this.statusFilter();
  }
  get listFilterBackupForms(): string {
    return this._listFilterBackupForms;
  }
  set listFilterBackupForms(value: string) {
    this._listFilterBackupForms = value;
    this.backupfilteredForms = this.listFilterBackupForms
      ? this.performFilterBackupForms(this.listFilterBackupForms)
      : this.statusFilterBackupForms();
  }

  get isAdmin() {
    // this.formStatus = [
    //   { id: 1, name: 'Form initiated' },
    //   { id: 2, name: 'Pending customer input' },
    //   { id: 3, name: 'Pending Admin Review' },
    //   { id: 6, name: 'Not approved/on hold' },
    //   { id: 7, name: 'Approved' }
    // ];
    return this.currentUser && this.currentUser.role === "Admin";
  }
  get isViewer() {
    // this.formStatus = [
    //   { id: 1, name: 'Form initiated' },
    //   { id: 2, name: 'Pending customer input' },
    //   { id: 3, name: 'Pending Admin Review' },
    //   { id: 6, name: 'Not approved/on hold' },
    //   { id: 7, name: 'Approved' }
    // ];
    return this.currentUser && this.currentUser.role === "Viewer";
  }
  get isCAS() {
    // this.formStatus = [
    //   { id: 3, name: 'Pending Admin Review' },
    //   { id: 4, name: 'Pending SME Review' }
    // ];
    return this.currentUser && this.currentUser.role === "CAS";
  }
  get isSME() {
    // this.formStatus = [
    //   { id: 3, name: 'Pending Admin Review' },
    //   { id: 4, name: 'Pending SME Review' },
    //   { id: 5, name: 'Needs testing/development' }
    // ];
    return this.currentUser && this.currentUser.role === "SME";
  }



  formStatus = [
    { id: 1, name: "Form Initiated" },
    { id: 2, name: "Pending Customer Input" },
    { id: 3, name: "Pending Admin Review" },
    { id: 4, name: "Pending SME Review" },
    { id: 5, name: "Needs Testing/Development" },
    { id: 6, name: "Not Approved/On Hold" },
    { id: 7, name: "Approved" },
  ];

  closureMaterials = [
    { id: 1, name: "Composite" },
    { id: 2, name: "Metal (Steel)" },
    { id: 3, name: "Plastic" },
    { id: 4, name: "ROPP (Alum)" },
  ];
  smeTabIndex: Number = 0

  constructor(
    private spinner: NgxSpinnerService,
    private closureFomrService: ClosureFormService,
    private formService: ClosureFormService,
    //private _fb: FormBuilder,
    //private _frm: FormGroup,
    private authenticationService: AuthenticationService,
    private fileService: FileService,
    private toastr: ToastrService,
    private router: Router
  ) {
    this.config = {
      itemsPerPage: 15,
      currentPage: 1,
      totalItems: this.filteredForms.length,
    };
  }
  
  ngOnInit() {
    
    //console.log("history.state : " + JSON.stringify(history.state));
    this.authenticationService.currentUser.subscribe((x) => {
      //   console.log(x);
      this.currentUser = x;
      if (this.isAdmin || this.isViewer) {
        // [
        //   { id: 5, name: 'Needs testing/development' },
        //   { id: 6, name: 'Not approved/on hold' },
        //   { id: 7, name: 'Approved' }
        // ]
        this.formStatus = [
          { id: 1, name: "Form Initiated" },
          { id: 2, name: "Pending Customer Input" },
          { id: 3, name: "Pending Admin Review" },
          { id: 4, name: "Pending SME Review" },
          { id: 5, name: "Needs Testing/Development" },
          { id: 6, name: "Not Approved/On Hold" },
          { id: 7, name: "Approved" },
        ];
      } else if (this.isCAS) {
        // [
        //   { id: 1, name: 'Form initiated' },
        //   { id: 2, name: 'Pending customer input' },
        //   { id: 3, name: 'Pending Admin Review' },
        //   { id: 4, name: 'Pending SME Review' }
        // ]
        this.headElements.splice(this.headElements.indexOf("Form Summary"), 1);
        this.formStatus = [
          { id: 1, name: "Form Initiated" },
          //  { id: 2, name: 'Pending Customer Input' },
          { id: 3, name: "Pending Admin Review" },
          { id: 4, name: "Pending SME Review" },
        ];
      } else if (this.isSME) {
        this.headElements = [
          "PSR Form ID",
          "Customer Name",
          /*"Customer Email",*/
          "Location",
          "Size",
          "Type",
          "Product",
          "Closure Material",
          "Status",
          "SME Status",
          "Actions",
          "Export",
          "Form Summary"

        ];
        // [
        //   { id: 3, name: 'Pending Admin Review' },
        //   { id: 4, name: 'Pending SME Review' },
        //   { id: 5, name: 'Needs testing/development' }
        // ]
        this.formStatus = [
          { id: 3, name: "Pending Admin Review" },
          { id: 4, name: "Pending SME Review" },
          { id: 5, name: "Needs Testing/Development" },
          { id: 6, name: "Not Approved/On Hold" },
          { id: 7, name: "Approved" },
        ];
      }
    });
    if (history.state.data) {
      
      if (history.state.data.smeTabIndex == 1) {
        this.backupClosureForms = history.state.data.data;
        this.backupfilteredForms = this.backupClosureForms;
        this.IsAdvancedFilterAppliedBackupForms = true;
        this.smeTabIndex = history.state.data.smeTabIndex;
      }
      else {
        if (history.state.data.smeTabIndex == 0) {
          this.closureForms = history.state.data.data;
          this.filteredForms = this.closureForms;
          this.IsAdvancedFilterApplied = true;
          this.smeTabIndex = history.state.data.smeTabIndex;
        }
        else {
          this.closureForms = history.state.data.data;
          for (let i = 0; i < this.closureForms.length; i++) {
            this.closureFomrService.getSizeData(this.closureForms[i].customerClosureMaterialSize).subscribe((data) => {
              this.closureForms[i].customerClosureMaterialSize = data.size;
            });

          }
          this.filteredForms = this.closureForms;
          this.IsAdvancedFilterApplied = true;
        }
      }
    }
    else {
     
      this.loadAllForms();
      if (this.isSME) {
        this.GetPendingFormsForBackUpSME();
      }
    }
  }
  ResetAdvacnseFilter() {
    this.loadAllForms();
    this.IsAdvancedFilterApplied = false;
  }
  public downloadPdf(Id) {
    this.spinner.show();
    this.fileService.downloadFile().subscribe((response) => {
      let blob: any = new Blob([response.blob()], {
        type: "text/pdf; charset=utf-8",
      });
      const url = window.URL.createObjectURL(blob);
      // window.open(url);
      // window.location.href = response.url;
      fileSaver.saveAs(blob, "SilganClosures_" + Id + ".pdf");
      this.spinner.hide();
    }),
      (error) => console.log("Error downloading the file"),
      () => console.log("File downloaded successfully");
  }
  public downloadExcel(Id) {
    this.fileService.downloadExcelFile().subscribe((response) => {
      let blob: any = new Blob([response.blob()], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=utf-8",
      });
      const url = window.URL.createObjectURL(blob);
      fileSaver.saveAs(blob, "SilganClosures_" + Id + ".xlsx");
    }),
      (error) => console.log("Error downloading the file"),
      () => console.log("File downloaded successfully");
  }
  statusFilter() {
    
    //  console.log("status filter : " + this.statusIdForFilter);
    return this.statusIdForFilter != 0
      ? this.materialFilter().filter((f) => f.status == this.statusIdForFilter)
      : this.materialFilter();
  }
  statusFilterBackupForms() {
    //  console.log("status filter : " + this.statusIdForFilter);
    return this.statusIdForFilterBackupForms != 0
      ? this.materialFilterBackupForms().filter((f) => f.status == this.statusIdForFilterBackupForms)
      : this.materialFilterBackupForms();
  }
  pageChanged(event) {
    this.config.currentPage = event;
  }
  materialFilter() {
    //   console.log("material filter : " + this.materialForFilter);
    return this.materialForFilter
      ? this.closureForms.filter(
        (f) => f.customerClosureMaterial == this.materialForFilter
      )
      : this.closureForms;
  }
  materialFilterBackupForms() {
    
    //   console.log("material filter : " + this.materialForFilter);
    return this.materialForFilterBackupForms
      ? this.backupClosureForms.filter(
        (f) => f.customerClosureMaterial == this.materialForFilterBackupForms
      )
      : this.backupClosureForms;
  }
  performFilter(filterBy: string): ClosureForm[] {
   
    filterBy = filterBy.toLocaleLowerCase();
    return this.closureForms.filter((closureForm: ClosureForm) => {
      return (
        //closureForm.pSrNo.toLocaleLowerCase().indexOf(filterBy) !== -1 ||
        (closureForm.customerName.toLocaleLowerCase().indexOf(filterBy) !==
          -1 ||
          closureForm.customerClosureMaterial
            .toLocaleLowerCase()
            .indexOf(filterBy) !== -1 ||
          closureForm.psrFormNoByYear.toString().indexOf(filterBy) !== -1 ||
          closureForm.customerClosureMaterialType.toLocaleLowerCase().indexOf(filterBy) !== -1 ||
          closureForm.customerEmail.toLocaleLowerCase().indexOf(filterBy) !==
          -1 ||
          closureForm.location.toLocaleLowerCase().indexOf(filterBy) !== -1 ||
          closureForm.customerClosureMaterialSize.toString().indexOf(filterBy) !== -1) &&
        (this.statusIdForFilter != 0
          ? closureForm.status == this.statusIdForFilter
          : true) 
      );
    });
  }

  performFilterBackupForms(filterBy: string): ClosureForm[] {
    
    filterBy = filterBy.toLocaleLowerCase();
    return this.backupClosureForms.filter((closureForm: ClosureForm) => {
      return (
        //closureForm.pSrNo.toLocaleLowerCase().indexOf(filterBy) !== -1 ||
        (closureForm.customerName.toLocaleLowerCase().indexOf(filterBy) !==
          -1 ||
          closureForm.customerClosureMaterial
            .toLocaleLowerCase()
            .indexOf(filterBy) !== -1 ||
          closureForm.pSrNo.toString().indexOf(filterBy) !== -1 ||
          closureForm.customerEmail.toLocaleLowerCase().indexOf(filterBy) !==
          -1 ||
          closureForm.location.toLocaleLowerCase().indexOf(filterBy) !== -1) &&
        (this.statusIdForFilter != 0
          ? closureForm.status == this.statusIdForFilter
          : true) &&
        (this.materialForFilterBackupForms
          ? closureForm.customerClosureMaterial == this.materialForFilterBackupForms
          : true)
      );
    });
  }
  onFilterByStatus(statusId) {

    //  console.log("statusId:" + statusId + " listFilter:" + this.listFilter);
    this.statusIdForFilter = statusId;
    // if (statusId == 0) {
    //   this.filteredForms = this.listFilter ? this.performFilter(this.listFilter) : this.materialFilter();
    // }
    // else {
    //   this.filteredForms = this.listFilter ?
    //     this.performFilter(this.listFilter).filter(f => f.status == statusId) :
    //     this.closureForms.filter(f => f.status == statusId);
    // }
    this.filteredForms = this.listFilter
      ? this.performFilter(this.listFilter)
      : this.statusFilter();
  }

  onFilterByStatusBackupForms(statusId) {
   
    //  console.log("statusId:" + statusId + " listFilter:" + this.listFilter);
    this.statusIdForFilterBackupForms = statusId;
    this.backupfilteredForms = this.listFilterBackupForms
      ? this.performFilterBackupForms(this.listFilterBackupForms)
      : this.statusFilterBackupForms();
  }
  onFilterByMaterial(materialId) {
    let material = this.closureMaterials.find((m) => m.id == materialId);
    //  console.log(material);
    if (material) {
      this.materialForFilter = material.name;
      // this.filteredForms = this.listFilter ?
      //   this.performFilter(this.listFilter).filter(f => f.customerClosureMaterial == material.name) :
      //   this.closureForms.filter(f => f.customerClosureMaterial == material.name);
    } else {
      this.materialForFilter = "";
      //this.filteredForms = this.listFilter ? this.performFilter(this.listFilter) : this.closureForms;
    }
    this.filteredForms = this.listFilter
      ? this.performFilter(this.listFilter)
      : this.statusFilter();
  }

  onFilterByMaterialBackupForms(materialId) {
    
    let material = this.closureMaterials.find((m) => m.id == materialId);
    //  console.log(material);
    if (material) {
      this.materialForFilterBackupForms = material.name;
      // this.filteredForms = this.listFilter ?
      //   this.performFilter(this.listFilter).filter(f => f.customerClosureMaterial == material.name) :
      //   this.closureForms.filter(f => f.customerClosureMaterial == material.name);
    } else {
      this.materialForFilterBackupForms = "";
      //this.filteredForms = this.listFilter ? this.performFilter(this.listFilter) : this.closureForms;
    }
    this.backupfilteredForms = this.listFilterBackupForms
      ? this.performFilterBackupForms(this.listFilterBackupForms)
      : this.statusFilterBackupForms();
  }

  onStatusSelected(formPSRNo: string, value: string) {
    $("#form-status").modal("show");
    this.selectPSRFormNo = formPSRNo;
    this.selectPSRFormNoStatus = value;
  }


  statusChange() {
    //   console.log("form psr no:" + this.selectPSRFormNo + ", the selected status value is " + this.selectPSRFormNoStatus);
    this.closureFomrService
      .updateFormStatus(this.selectPSRFormNo, this.selectPSRFormNoStatus)
      .subscribe((s) => {
        $("#form-status").modal("hide");
        this.loadAllForms();
      });
  }

  onSMEStatusSelected(formPSRNo: string, value: string) {

    $("#sme-form-status").modal("show");
    this.selectPSRFormNo = formPSRNo;
    this.selectPSRFormNoStatus = value;
  }

  onSMEStatusSelectedBackUpForms(formPSRNo: string, value: string) {

    $("#sme-form-status-backup-forms").modal("show");
    this.selectPSRFormNo = formPSRNo;
    this.selectPSRFormNoStatus = value;
  }

  SMEStatusChange(IsApprovedByBackUpSme) {
    //   console.log("form psr no:" + this.selectPSRFormNo + ", the selected status value is " + this.selectPSRFormNoStatus);
    this.closureFomrService
      .updateSMEFormStatus(this.selectPSRFormNo, this.selectPSRFormNoStatus, IsApprovedByBackUpSme)
      .subscribe((s) => {
        $("#sme-form-status").modal("hide");
        this.loadAllForms();
      });
  }

  SMEStatusChangeBackUpForms(IsApprovedByBackUpSme) {
    
    //   console.log("form psr no:" + this.selectPSRFormNo + ", the selected status value is " + this.selectPSRFormNoStatus);
    this.closureFomrService
      .updateSMEFormStatus(this.selectPSRFormNo, this.selectPSRFormNoStatus, IsApprovedByBackUpSme)
      .subscribe((s) => {
        $("#sme-form-status-backup-forms").modal("hide");
        this.loadAllForms();
      });
  }

  SMEStatusChangeReject() {
    $("#sme-form-status").modal("hide");
    this.loadAllForms();
  }

  SMEStatusChangeRejectBackUpForms() {
    $("#sme-form-status-backup-forms").modal("hide");
    this.loadAllForms();
  }

  generatePDF(Id) {
    // alert('test generate PDF - ' + Id);
    //  console.log("form psr no:" + this.selectPSRFormNo + ", the selected status value is " + this.selectPSRFormNoStatus);
    // this.closureFomrService.generatePDF(Id);
    // this.closureFomrService.generatePDF(Id).subscribe(s => {
    //   $('#form-status').modal('hide');
    // //  $('#form-status').modal('hide');
    ////   this.loadAllForms();
    // console.log("form psr no:" + this.selectPSRFormNo + ", the selected status value is " + this.selectPSRFormNoStatus);
    this.closureFomrService.GenerateMyPDF(Id).subscribe((s) => {
      $("#form-status").modal("hide");
      //this.loadAllForms();
      // alert('test generate PDF - ' + Id);
      this.downloadPdf(Id);
    });
  }
  generateExcel(Id) {
    this.closureFomrService.generateExcel(Id).subscribe((s) => {
      $("#form-status").modal("hide");
      //this.loadAllForms();
      this.downloadExcel(Id);
    });
  }




  statusChangeReject() {
    $("#form-status").modal("hide");
    this.loadAllForms();
  }

  advanceSearchPopUp() {
    
    $("#advance-search-modal").modal("show");
  }
  
  OnSendEmail() {
    
    let myform=this.regeneratedLinkForm
    this.closureFomrService.SendEmail(myform).subscribe(data => {
     // console.log(data);
      this.toastr.success("Email Send Successfully")
    }, error => {
      this.toastr.error("Email sending failed")
    });
    $("#PSR-Link-Modal").modal("hide");    
    this.loadAllForms();

  }
  OnGeneratPSR(psrNo) {
    
    Link: ['']
    let form = this.filteredForms.find((x) => x.pSrNo == psrNo);
   // console.log("Himanshu", form);
    this.closureFomrService.GeneratePsrLink(form).subscribe(data => {
      
      this.regeneratedLinkForm = data;
      this.regeneratedPsrLink = this.regeneratedLinkForm.isGenerateLink;
      this.regeneratedPsrPassword = this.regeneratedLinkForm.password;
    //  console.log(data);
      $("#PSR-Link-Modal").modal("show");
    });      
  }

  OnDeletingPopUp(id)
  {
    let psrid = this.filteredForms.find((x) => x.pSrNo == id);
    this.deleteform = id;
    this.getid = this.deleteform;
    
    $("#Delete-Modal").modal("show");
  }

 

  OnDeleteingPSrForm() {    
       
    let psrid = this.getid;

    this.closureFomrService.deletePsrLink(psrid).subscribe(data => {
      $("#Delete-Modal").modal("hide");
    
      this.loadAllForms();
    });

  }

  //get link(): string {
  //  return this.regeneratedPsrLink;
  //}
  //get pass(): string {
  //  return this.regeneratedPsrPassword;
  //}
  //get Link() {
  //  return this._frm.get('fo');
  //}
  

  private loadAllForms() {

    this.closureFomrService.getAll().subscribe((closureForms) => {
      
      this.closureForms = closureForms;

      for (let i = 0; i < this.closureForms.length; i++) {
        this.closureFomrService.getSizeData(this.closureForms[i].customerClosureMaterialSize).subscribe((data) => {     
            this.closureForms[i].customerClosureMaterialSize = data.size;
        });
      
      }
      
   
      console.log("GDGD", this.closureForms)
      this.filteredForms = this.closureForms;
   
     // console.log('FormListValues', this.closureForms);
     // let myresult = this.filteredForms.find((x) => x.pSrNo == '61109');
      
     //debugger
    });
  }

  sortAscendingDesceding(col: string) {
    let sortColumn = this.headElements.find((c) => c === col);
    let sortValue = "pSrNo";
    switch (sortColumn) {
      case this.headElements[0]:
        sortValue = "pSrNo";
        break;
      case this.headElements[1]:
        sortValue = "customerName";
        break;
      case this.headElements[2]:
        sortValue = "customerEmail";
        break;
      case this.headElements[3]:
        sortValue = "Size";
        break;
      case this.headElements[4]:
        sortValue = "Type";
        break;
      case this.headElements[5]:
        sortValue = "location";
        break;
      case this.headElements[6]:
        sortValue = "customerClosureMaterial";
        break;
      case this.headElements[7]:
        sortValue = "status";
        break;
      default:
        sortValue = "pSrNo";
        break;
    }
    if (col !== "Actions" && col !== "Form Summary" && col !== "Export") {
      if (this.previousSortColumn == sortValue) {
        this.filteredForms.reverse();
      } else {
        this.previousSortColumn = sortValue;
        this.filteredForms.sort((a, b) =>
          a[sortValue] > b[sortValue] ? 1 : -1
        );
      }
    }
  }
  getClosureMaterial(id: number) {
    //return this.closureMaterials.find(d => d.id == id).name;
  }
  JSON2CSV(objArray) {
    let array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    let str = "";
    let line = "";
    let head = array[0];
    for (let index in array[0]) {
      line += '"' + index + '",';
    }
    line = line.slice(0, -1);
    str += line + "\r\n";

    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (let index in array[i]) {
        if (array[i][index] != null) {
          line += '"' + array[i][index] + '",';
        } else {
          line += '"",';
        }
      }
      line = line.slice(0, -1);
      //console.log("Array " + JSON.stringify(line));
      if (line != "") str += line + "\r\n";
    }
    return str;
  }
  convertToCSV(objArray) {
    var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    var str = "";

    for (var i = 0; i < array.length; i++) {
      var line = "";
      for (var index in array[i]) {
        if (line != "") line += ",";

        line += array[i][index];
      }

      str += line + "\r\n";
    }

    return str;
  }
  CSV(array) {
    // Use first element to choose the keys and the order
    var keys = Object.keys(array);

    // Build header
    var result = keys.join(",") + "\n";

    // Add the rows

    result +=
      keys
        .map((k) => {
          if (array[k] != null) return array[k].toString().replace(/,/g, "");
          return "";
        })
        .join(",") + "\n";

    return result;
  }
  exportCSVFile(items, fileTitle) {
    // Convert Object to JSON
    //var jsonObject = JSON.stringify(items);

    var csv = this.CSV(items);

    var exportedFilenmae = fileTitle + ".csv" || "export.csv";

    var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, exportedFilenmae);
    } else {
      var link = document.createElement("a");
      if (link.download !== undefined) {
        // feature detection
        // Browsers that support HTML5 download attribute
        var url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", exportedFilenmae);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }
  onExportCSV(id: number) {
    this.formService.getById(id).subscribe((x) => {
      //this.JSON2CSV(x);
      //      console.log("Form Data:" + JSON.stringify(x));
      this.downloadClick(x);
    });
  }
  downloadClick(data: any) {
    let fileTitle = "Form Data"; // or 'my-unique-title'

    this.exportCSVFile(data, fileTitle); // call the exportCSVFile() function to process the JSON and trigger the download
  }
  download2(strData, strFileName, strMimeType) {
    if (
      navigator.userAgent.indexOf("Safari") != -1 &&
      navigator.userAgent.indexOf("Chrome") == -1
    ) {
      var D = document;
      var a = D.createElement("a");
      strMimeType = strMimeType || "application/octet-stream";
    } else {
      this.download(strData, strFileName, strMimeType);
    }
  }
  download(csvString, exportFilename, strMimeType) {
    var csvData = new Blob([csvString], {
      type: strMimeType + ";charset=utf-8;",
    });
    setTimeout(function () {
      if (navigator.msSaveBlob) {
        //IE11 & Edge
        navigator.msSaveBlob(csvData, exportFilename);
      } else {
        //In FF link must be added to DOM to be clicked
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(csvData);
        link.setAttribute("download", exportFilename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }, 1000);
  }
  submitRequest(buttonId) {
    var d = window.parent ? window.parent.document : window.document;
    if (
      d.getElementById(buttonId) == null ||
      d.getElementById(buttonId) == undefined
    )
      return;
    if (d.getElementById(buttonId).dispatchEvent) {
      var e = d.createEvent("MouseEvents");
      e.initEvent("click", true, true);
      d.getElementById(buttonId).dispatchEvent(e);
    } else {
      d.getElementById(buttonId).click();
    }
  }

  openForm(psrNo: any) {
    // debugger;
    let data = this.filteredForms.find((x) => x.pSrNo == psrNo);
    localStorage.setItem("dataSource", JSON.stringify(data));
  }

  GetPendingFormsForBackUpSME() {
    
    this.closureFomrService.getPendingFormsForBackUpSME().subscribe((closureForms) => {
      
      this.backupClosureForms = closureForms;
      this.backupfilteredForms = closureForms;
    });
  }
  onBackUpSmeChange(event: MatTabChangeEvent) {
    
    this.smeTabIndex = event.index
  }

  onAdvancedFilterClick(tabIndex) {
    
    var data = {
      smeTabIndex: tabIndex
    }
    this.router.navigate(['/formsearch'], { state: { data: data } });
  }

  OnSendRegenerateLinkEmail() {
    
    let myform = this.regeneratedLinkForm
    this.closureFomrService.ReGenerateLinkEmailToCustomer(myform).subscribe(data => {
     // console.log(data);
      this.toastr.success("Email Send Successfully")
    }, error => {
      this.toastr.error("Email sending failed")
    });
    $("#PSR-Link-Modal").modal("hide");
    this.loadAllForms();

  }

  onPopUPLock(id,flag) {
    
    if (flag == 1) {
      this.Isformlock = false;
    }
    else {
      this.Isformlock = true;
    }
    $("#Lock-Modal").modal("show");
    this.getid = id;
  }

  lockForm() {
    
    this.closureFomrService.lockPSRForm(this.getid).subscribe((s) => {
      this.loadAllForms();
      this.toastr.success("Form Locked Successfully.")
      $("#Lock-Modal").modal("hide");
    });
  }

  unlockForm() {
    
    this.closureFomrService.unlockPSRForm(this.getid).subscribe((s) => {  
      this.loadAllForms();
      this.toastr.success("Form Un-locked Successfully.")
      $("#Lock-Modal").modal("hide");
    });
  }

}
