import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { first } from 'rxjs/operators';

import { UserService } from '../services/user.service';
import { AuthenticationService } from '../services/authentication.service';
import { stringTextValidator } from '../validators/stringText-validator';
import { phoneNumberValidator } from '../validators/phoneNumber-validator';
import { ToastrService } from 'ngx-toastr';
import { User } from '../models/user';

@Component({
    selector: 'app-register-user',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  registerForm: FormGroup;
  loading = false;
  submitted = false;
  IsteamValid = true;
  IsroleValid = true;
  dropdownList = [];
  selectedId = "";
  selectedOption: number = 0
    //selectedItems: Map<string, Array<any>>;
  selectedItems = [];
    dropdownSettings = {};
    @Output() userAdded = new EventEmitter();
    
    get role() {
        return this.registerForm.get('role');
    }
    get phone() {
        return this.registerForm.get('phone');
    }
    get email() {
        return this.registerForm.get('email');
    }
    get firstName() {
        return this.registerForm.get('firstName');
    }
    get lastName() {
        return this.registerForm.get('lastName');
    }
    get password() {
        return this.registerForm.get('password');
    }
    get username() {
        return this.registerForm.get('username');
    }
    get teamId() {
        return this.registerForm.get('teamId');
    }
  get team() {
    return this.registerForm.get('team');
  }
  BackUpSmeUsers: User[]

    constructor(
        private formBuilder: FormBuilder,
        private authenticationService: AuthenticationService,
        private userService: UserService,
        private toastr: ToastrService
    ) {
        // redirect to home if already logged in
        // if (!this.authenticationService.currentUserValue) {
        //     this.router.navigate(['/']);
        // }
    }

    ngOnInit() {
        
      this.registerForm = this.formBuilder.group({
        firstName: ['', [Validators.required, this.noWhitespaceValidator]],
        lastName: ['', [Validators.required, this.noWhitespaceValidator]],
        username: ['', [Validators.required,  this.noWhitespaceValidator]],
            email: ['', [Validators.required, Validators.email]],
            phone: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(12)]],
        password: ['', [Validators.required,  this.noWhitespaceValidator, Validators.minLength(6)]],
            role: ['Select Role', Validators.required],
            teamId: '',
            team: [''],
            BackUpSmeUserId: []
        });
       this.GetBackupSMEs();
        this.onChanges();
        //this.selectedItems = new Map<string, Array<any>>();
 //     selectedItems = [];
        this.dropdownList = [
                              {"id":1,"itemName":"Metal (Steel)"},
                              {"id":2,"itemName":"Composite"},
                              {"id":3,"itemName":"ROPP (Alum)"},
                              {"id":4,"itemName":"Plastic"}  
                            ];

                            this.selectedItems =  [
                              
                            ];

        this.dropdownSettings = { 
                               
                                };  
      this.disable();
                               // console.log(this.selectedItems)          
    }
    

    // convenience getter for easy access to form fields
  get f() { return this.registerForm.controls; }

  public noWhitespaceValidator(control: FormControl) {
    
    if (control.value == '') {
      return
    }
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  disable() {
    this.dropdownSettings = {
      singleSelection: false,
      text: "Select Closure Material Team",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: false,
      classes: "myclass custom-class",
      disabled: true,
      itemsShowLimit: 3
    };
   // this.selectedItems["SME"] = [];
  
  }

  enable() {
    this.dropdownSettings = {
      singleSelection: false,
      text: "Select Closure Material Team",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: false,
      classes: "myclass custom-class",
      disabled: false,
      itemsShowLimit: 3
    };
  }


  onChanges() {
    this.selectedOption = 0;
    this.IsroleValid = true;
        this.registerForm.get('role').valueChanges
          .subscribe(roleValue => {

            //console.log(roleValue);
            if (roleValue != 'SME') {
              this.disable();
                  //  this.registerForm.patchValue({
                  //      teamId: 0
                  //  });
                  //  this.teamId.setValidators(null);
                  //this.registerForm.get('teamId').disable();
                }
                else {
                 // console.log("set vaildator");
                  this.enable();
                  //  this.registerForm.get('teamId').enable();
                  //this.teamId.setValidators([Validators.required, Validators.min(1)]);
                 // var ansu = this.dropdownSettings['disabled'];
                  //this.dropdownSettings['disabled'] = false;
                  //this.teamidDisabled = false;
                 // this.dropdownSettings['disabled'] = this.teamidDisabled;
                 // console.log(this.dropdownSettings['disabled']);
                }
                this.teamId.updateValueAndValidity();
            });
    }
    onlyNumberValid(event: any) {
        var charCode = (event.which) ? event.which : event.keyCode;
        if (charCode != 45 && charCode > 31
          && (charCode < 48 || charCode > 57))
          return false;
    
        return true;
      }
      
  onSubmit() {
    this.submitted = true;
    if (this.registerForm.value.role == 'Select Role') {
      this.IsroleValid = false;
      return;
    } else {
      this.IsroleValid=true}

    if (this.registerForm.value.role == 'SME') {


      let teamAssignedId = "";
      for (let i = 0; i < this.registerForm.value.team.length; i++) {
        teamAssignedId += this.registerForm.value.team[i].id + ",";
      }
      teamAssignedId = teamAssignedId.substring(0, teamAssignedId.length - 1);
      // console.log(teamAssignedId);

      // console.log("Team Error:" + JSON.stringify(this.team.errors));
      //  console.log(this.registerForm.value);
      if (teamAssignedId == "") {
        this.IsteamValid = false;

      }
      else {
        this.IsteamValid = true;
      }

      this.registerForm.patchValue({
        teamId: teamAssignedId
      });
    }

    if (this.IsteamValid== false) {
      return;
    }

        // stop here if form is invalid
        if (this.registerForm.invalid) {
            return;
        }
        this.loading = true;
        this.userService.register(this.registerForm.value)
            .pipe(first())
            .subscribe(
                data => {
                    this.toastr.success('User added successfully');
                    this.loading = false;
                    this.userAdded.emit();
                    this.onCloseAddUser();
                    //this.router.navigate(['/login']);
                },
                error => {
                    this.toastr.error(error);
                    this.loading = false;
                });
    }
  onCloseAddUser() {
       this.IsroleValid = true;
    this.IsteamValid = true;
    this.submitted = false;
    this.selectedOption=0;
        this.registerForm.reset({
            firstName: '',
            lastName: '',
            username: '',
            email: '',
            phone: '',
            password: '',
            role: 'Select Role',
          teamId: '',
          team:''
        });
    
  }

  GetBackupSMEs() {
    this.userService.GetBackUpSmeUsers().subscribe(data => {
      this.BackUpSmeUsers = data;
    });
  }
}
