import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { PackageSpecificationService } from '../services/package-specification.service';
import { PackageSpecification } from '../models/packageSpecification';

@Component({
    selector: 'app-specification-review-add-edit',
    templateUrl: './specification-review-add-edit.component.html'
})
export class SpecificationReviewAddEdit {
    packageSpecificationForm: FormGroup;
    actionType: string;
    formTitle: string;
    id: number;
    errorMessage: any;
    existingPackageSpecification: PackageSpecification;
    constructor(private packageSpecificationService: PackageSpecificationService, private formBuilder: FormBuilder, private avRoute: ActivatedRoute, private router: Router) {
        const idParam = 'id';
        this.actionType = 'Add';
        this.formTitle = 'title';
        if (this.avRoute.snapshot.params[idParam]) {
            this.id = this.avRoute.snapshot.params[idParam];
        }

        this.packageSpecificationForm = this.formBuilder.group(
            {
                id: 0,
                salesRepName: ['', [Validators.required]],
                casPhoneNumber: ['', [Validators.required]],
                formCompletedDate: ['', [Validators.required]],
                customerCompany: ['', [Validators.required]],
                customerContect: ['', [Validators.required]],
                customerContactPhoneNumber: ['', [Validators.required]],
                businessOrderInformationId: 0,
                businessOrderReasonId: 0,
                customerClosureMaterialId: 0,
                customerName: ['', [Validators.required]],
                customerClosureMaterialSize: 0,
                customerClosureMaterialType: '',
                cutomerBusinessAddress: ['', [Validators.required]],
                silganCoc: ['', [Validators.required]],
                productName: ['', [Validators.required]],
                initialOrderQuantity: ['', [Validators.required]],
                estmatedAnnualVolume: ['', [Validators.required]],
                dateClosureNeeded: ['', [Validators.required]],
                bpaNICoatingRequired: [null, [Validators.required]],
                customerClosureSpecialIssues: ['', [Validators.required]],
                approvedStatusId: 0,
                pSRNo: ['', [Validators.required]],
                approvedDate: ['', [Validators.required]],
                approvedInitial: ['', [Validators.required]],
                approvedComments: ['', [Validators.required]],
                customerSectionCompanyName: ['', [Validators.required]],
                customerProjectName: ['', [Validators.required]],
                containerClosureSize: ['', [Validators.required]],
                capShellColor: ['', [Validators.required]],
                diskColor: ['', [Validators.required]],
                isDesignOnFace: [null, [Validators.required]],
                arePrintsOnTheReverse: [null, [Validators.required]],
                additionalInstructions: ['', [Validators.required]],
                customerCompanyName: ['', [Validators.required]],
                customerSectionDate: ['', [Validators.required]],
                containerTypeId: 0,
                containerFinishNumber: ['', [Validators.required]],
                containerMenufacturer: ['', [Validators.required]],
                containerCapacity: ['', [Validators.required]],
                containerComments: ['', [Validators.required]],
                isFatOnSurface: [null, [Validators.required]],
                isBeverage: [null, [Validators.required]],
                phLevelId: 0,
                productPhLevel: ['', [Validators.required]],
                isVinegarPresent: [null, [Validators.required]],
                isSO2Present: [null, [Validators.required]],
                isAlcoholPresent: [null, [Validators.required]],
                shelfLifePackedProduct: ['', [Validators.required]],
                FdaFoodCategoryId: 0,
                FdaConditionId: 0,
                packerNameAddress: ['', [Validators.required]],
                headspacePercentage: 0,
                productFillTemperature: 0,
                appliedId: 0,
                packageProcessId: 0,
                finishedPackageVacuum: ['', [Validators.required]],
                packageTryPackedId: 0,
                warehouseTemeratureId: 0,
                warehouseHumidityId: 0,
                retailDisplayTemeratureId: 0,
                retailDisplayHumidityId: 0,
                warehouseShippingDisplayIssues: ['', [Validators.required]],
                isRegularatoryCompliance: [null, [Validators.required]],
                submitterContactNameTitle: ['', [Validators.required]],
                submitterDateInformation: ['', [Validators.required]],
                submitterMailingAddress: ['', [Validators.required]],
                submitterPhone: ['', [Validators.required]],
                submitterEmailAddress: ['', [Validators.required]]
            }
        )
    }
    onSubmitPackageSpecification(packageSpecificationData) {
         if (!this.packageSpecificationForm.valid) { //check for vaild form state
             return;
         }
      //  console.log("packageSpecificationData" + JSON.stringify(packageSpecificationData));
        this.packageSpecificationService.savePackageSpecification(packageSpecificationData)
            .subscribe((data) => {
                //this.router.navigate(['/packageSpecification', data.id]);
               // console.log("Package specification save form data:" + data);
            });
    }
}
