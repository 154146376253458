import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first, debounce } from 'rxjs/operators';
import { ClosureFormService } from 'src/app/services/closure-form.service';
import { AuthenticationService } from '../services/authentication.service';
import { CustomerAuthenticationService } from '../services/customer-authentication.service';
import { AlertService } from '../alert/alert.service';
import { isNullOrUndefined } from 'util';

@Component({
  templateUrl: 'customer-Home.component.html',
  styleUrls: ['./customer-Home.component.scss']
})
export class CustomerHomeComponent implements OnInit {
  CustomerHomeForm: FormGroup;
  sendCustomerData: any;
  loading = false;
  submitted = false;
  returnUrl: string;
  queryString: any;
  _d: any;
  _i: any;
  _e: any;
  showStartButton:boolean=false;
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    private formService: ClosureFormService,
    private customerAuthenticationService: CustomerAuthenticationService

  ) {
    // redirect to home if already logged in
    //  if (this.authenticationService.currentUserValue) {
    //      this.router.navigate(['/']);
    //}
    //this.loginForm = this.formBuilder.group({
    //  username: ['', Validators.required],
    //  password: ['', Validators.required]
    //});
  }

  ngOnInit() {
  
    //this.CustomerloginForm = this.formBuilder.group({
    //  password: ''
    //});
    this.route.url.subscribe(data => {
  //    console.log(data);
    });
    this.route.queryParams.subscribe(params => {

      this._d = params._d;
      this._i = params._i;
      this._e = params._e;
   //   console.log(params);
      if(!isNullOrUndefined(params._d) && !isNullOrUndefined(params._e) && !isNullOrUndefined(params._i)){
       
          this.showStartButton=true;
      }
      //});
      
      //for (let key in params) {
      //  if (params.hasOwnProperty(key)) {
      //    let element = params[key];
      //    this.queryString = this.queryString + key + '=' + element + '&';
      //  }
      //}
      //this.queryString = this.queryString + this.queryString.substring(0, this.queryString.length - 1);
  //    console.log("queryString" + this.queryString);
      //if (queryString != '') {
      //  this.formService.isVaildPasswordLink(queryString)
      //    .subscribe(res => {
      //      //this.router.navigate(['/profile'])
      //      this.sendCustomerData = res.response;
      //      console.log(res.response);
      //    });
      //}
    });
  }

  // convenience getter for easy access to form fields
  //get f() { return this.CustomerloginForm.controls; }

  Continue() {

  //  this.router.navigate(['/formstepper/' + this.queryString]);
 
    this.router.navigate(['Account/CustomerLogin'], { queryParams: { _d: this._d,_i:this._i,_e:this._e }});

  }
}
