import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { ClosureForm } from 'src/app/models/closure-form';
import { ClosureFormService } from 'src/app/services/closure-form.service';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { formatDate } from '@angular/common';
import { User } from 'src/app/models/user';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { FileService } from '../../services/file.service';
import * as fileSaver from "file-saver";
declare var $: any;

@Component({
  selector: 'app-form-summary',
  templateUrl: './form-summary.component.html',
  styleUrls: ['./form-summary.component.scss']
})
export class FormSummaryComponent implements OnInit, AfterViewInit {
  loading: boolean = false;
  closureForm;
  showSubmitButton = true;
  lubAdded: string = 'No';
  initiatedDate = '';
  customerSubmittedDate = '';
  smeSubmittedDate = '';
  successMessage = '';
  fileUpload: File = null;
  filename: string;
  myldrsummary: string;
  //@Input() 
  formId: number;
  formData: ClosureForm;
  currentUser: User;
  sizeData: number;
  submitted = false;
  uploadResponse = { fileStatus: '', message: '', filePath: '' };
  error: string; 
  formStatus = [
    { id: 1, name: 'Form Initiated' },
    { id: 2, name: 'Pending customer input' },
    { id: 3, name: 'Pending Admin Review' },
    { id: 4, name: 'Pending SME Review' },
    { id: 5, name: 'Needs testing/development' },
    { id: 6, name: 'Not approved/on hold' },
    { id: 7, name: 'Approved' }
  ];
  get isAdmin() {
    return this.currentUser && this.currentUser.role === "Admin";
  }
  get isViewer() {
    return this.currentUser && this.currentUser.role === "Viewer";
  }
  get isSME() {
    return this.currentUser && this.currentUser.role === "SME";
  }
  get status() {
    return this.closureForm.get('status');
  }
  get endUseCode() {
    return this.closureForm.get('endUseCode');
  }

  constructor(
    private formBuilder: FormBuilder,
    private formService: ClosureFormService,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router,
    private fileService: FileService
  ) {
    this.formData = new ClosureForm();
    this.closureForm = this.formBuilder.group({
      psrNo: '',
      psrFormNoByYear: '',
      

      //packerNameAndAddress: [''],
      //palletsHigh: [''],
      //packagesPerTray: [''],
      //traysPerLayer: [''],
      //layersPerPallet: [''],
      //weightPerPallet: [''],
      //warehouseStorageCondition: ['Frozen'],
      //externalCondensationWithHumidity: ['Dry'],
      //retailDisplayConditions: ['Frozen'],
      //humidity: ['Dry'],
      //productWeightPerPalletInLbs: [''],
      //packageTrayPacked: ['Unknown'],
      //customerContantNameTitle: [''],
      //customerClosureMaterial: 'Composite'

      diskSummary: '',
      linerSummary: '',
      compoundSummary: '',
      fitmentResinSummary: '',
      capshellResinSummary: '',
      fitmentSummary: '',
      lubAddedLDRSummary: '',
      colorantSummary: '',
      lDRSummary: '',
      faceSystemSummary: '',
      faceCoatingsSummary: '',
      reverseSystemSummary: '',
      reverseCoatingsSummary: '',
      status: '',
      approverAttachment: '',
      approvedComments: '',
      endUseCode: ['', Validators.required],
      steelCoat: '',
      capExteriorCoating: '',
      capInteriorTopCoat: '',
      aluminumAlloyTemperThickness: '',
      steelWeight: '',
    });

  }
  ngAfterViewInit() {

  }
  ngOnInit() {
    
    this.authenticationService.currentUser.subscribe(x => {
   //   console.log(x);
      this.currentUser = x;
    });
    this.formId = this.route.snapshot.params['id'];
    $('#edit-modal').on('hidden.bs.modal', this.onDismissPopUp);
    if (this.formId) {
      
      this.formService.getById(this.formId).subscribe(data => {
   //     console.log("Form Data: " + JSON.stringify(data));
       
        this.formData = data;
        this.myldrsummary = this.formData.ldrSummary;
       
        this.formService.getSizeData(+this.formData.customerClosureMaterialSize).subscribe(
          p => {
            this.sizeData = p.size;                        
          });
        this.initiatedDate = formatDate(this.formData.initiatedDate, 'MM-dd-yyyy', 'en');
        this.customerSubmittedDate = formatDate(this.formData.customerSubmittedDate, 'MM-dd-yyyy', 'en');
        this.smeSubmittedDate = formatDate(this.formData.smeSubmittedDate, 'MM-dd-yyyy', 'en');
        
        this.closureForm.setValue({
          
          //customerEmail: this.formData.customerEmail,
          psrNo: this.formId,
          psrFormNoByYear: this.formData.psrFormNoByYear,
          diskSummary: this.formData.diskSummary,
          linerSummary: this.formData.linerSummary,
          compoundSummary: this.formData.compoundSummary,
          fitmentResinSummary: this.formData.fitmentResinSummary,
          capshellResinSummary: this.formData.capshellResinSummary,
          fitmentSummary: this.formData.fitmentSummary,
          lubAddedLDRSummary: this.formData.lubAddedLDRSummary,
          colorantSummary: this.formData.colorantSummary,
          lDRSummary: this.formData.ldrSummary == null ? '' : this.formData.ldrSummary,                    
          faceSystemSummary: this.formData.faceSystemSummary,
          faceCoatingsSummary: this.formData.faceCoatingsSummary,
          reverseSystemSummary: this.formData.reverseSystemSummary,
          reverseCoatingsSummary: this.formData.reverseCoatingsSummary,
          capExteriorCoating: this.formData.capExteriorCoating,
          capInteriorTopCoat: this.formData.capInteriorTopCoat,          
          status: this.formData.status,
          approverAttachment: this.closureForm.approverAttachment == null ? '' : this.formData.approverAttachment,
          approvedComments: this.formData.approvedComments,
          steelCoat: this.formData.steelCoat,
          endUseCode: this.formData.endUseCode,
          aluminumAlloyTemperThickness: this.closureForm.aluminumAlloyTemperThickness == '' ? '3105 – H19' : this.formData.aluminumAlloyTemperThickness,
          steelWeight: this.closureForm.steelWeight == '' ? '50#' : this.formData.steelWeight
          

        });


        if (this.formData.isFormLock == 1) {
          if (this.currentUser != null && this.currentUser != undefined) {
            if (this.currentUser.role == "Admin") {
              this.showSubmitButton = false;
              this.closureForm.disable();
            } else if (this.currentUser.role == "Viewer") {
              this.showSubmitButton = false;
              this.closureForm.disable();
            } else if (this.currentUser.role == "CAS") {
              if (this.formData.status == 3 || this.formData.status == 1) {
                this.showSubmitButton = false;
                this.closureForm.disable();
              } else {
                this.showSubmitButton = false;
                this.closureForm.disable();
              }
            } else if (this.currentUser.role == "SME") {
              if (
                this.formData.status == 4 &&
                this.formData.customerClosureMaterial == "Composite"
              ) {
                var output = this.currentUser.teamId.split("");
                for (let i = 0; i < output.length; i++) {
                  let id = output[i];
                  if (id == "2") {
                    this.showSubmitButton = false;
                    this.closureForm.disable();
                  }
                }
              } else if (
                this.formData.status == 4 &&
                this.formData.customerClosureMaterial == "Plastic"
              ) {
                var output = this.currentUser.teamId.split("");
                for (let i = 0; i < output.length; i++) {
                  let id = output[i];
                  if (id == "4") {
                    this.showSubmitButton = false;
                    this.closureForm.disable();
                  }
                }
              } else if (
                this.formData.status == 4 &&
                this.formData.customerClosureMaterial == "Metal (Steel)"
              ) {
                var output = this.currentUser.teamId.split("");
                for (let i = 0; i < output.length; i++) {
                  let id = output[i];
                  if (id == "1") {
                    this.showSubmitButton = false;
                    this.closureForm.disable();
                  }
                }
              } else if (
                this.formData.status == 4 &&
                this.formData.customerClosureMaterial == "ROPP (Alum)"
              ) {
                var output = this.currentUser.teamId.split("");
                for (let i = 0; i < output.length; i++) {
                  let id = output[i];
                  if (id == "3") {
                    this.showSubmitButton = false;
                    this.closureForm.disable();
                  }
                }
              } else {
                this.showSubmitButton = false;
                this.closureForm.disable();
              }
            }
          } else {
            if (this.formData.status == 2) {
              this.showSubmitButton = false;
              this.closureForm.disable();
            } else {
              this.showSubmitButton = false;
              this.closureForm.disable();
            }
          }
        }

        //this.closureForm.disable();
      });
    }
  }

  onSubmit() {
    this.submitted = true;
   
    //  alert('summury');
 //   console.log(this.closureForm.value);
 //   console.log(' Closure form invalid : ' + this.closureForm.invalid);
    // stop here if form is invalid
    if (this.closureForm.invalid) {
    
      return;
    }
    if (this.fileUpload != null) {

      this.fileService.uploadApproverAttachment(this.fileUpload, this.closureForm.value.psrNo).subscribe(
        (res) => this.uploadResponse = res,
        (err) => this.error = err
      );
    }
    this.closureForm.patchValue({
      psrNo: this.formId,
      status: 7
    });
    this.closureForm.disable();
    this.loading = true;
    this.formService.SaveSummaryFormData(this.closureForm.value)
      .subscribe(
        data => {
          this.closureForm.enable();
          this.loading = false;
          //this.formCompleted.emit(true);
          this.router.navigate(['/formsList']);
        },
        error => {
          this.closureForm.enable();
          this.loading = false;
        });
  }
  onSaveFormSummary() {
    this.submitted = true;
    if (this.closureForm.invalid) {
      return;
      this.closureForm.value;      
    }    

    if (this.fileUpload != null) {

      this.fileService.uploadApproverAttachment(this.fileUpload, this.closureForm.value.psrNo).subscribe(
        (res) => this.uploadResponse = res,
        (err) => this.error = err
      );
    }
   
    this.closureForm.disable();
    this.loading = true;    
    
    this.formService.SaveSummaryFormData(this.closureForm.value)
      .subscribe(
        data => {
          this.closureForm.enable();
          this.refreshForm();
          this.loading = false;
          this.successMessage = "Form summary saved successfully.";
          $('#edit-modal').modal('show');
          //this.formCompleted.emit(true);
          //this.router.navigate(['/formsList']);
        },
        error => {
          this.closureForm.enable();
          this.loading = false;
        });
  }
  onSubmitNeedDevelopment() {
    this.submitted = true;
    if (this.closureForm.invalid) {
      return;
    }
    if (this.fileUpload != null) {

      this.fileService.uploadApproverAttachment(this.fileUpload, this.closureForm.value.psrNo).subscribe(
        (res) => this.uploadResponse = res,
        (err) => this.error = err
      );
    }
    this.closureForm.patchValue({
      psrNo: this.formId,
      status: 5
    });
    this.closureForm.disable();
    this.loading = true;
    this.formService.SaveSummaryFormData(this.closureForm.value)
      .subscribe(
        data => {
          this.closureForm.enable();
          this.loading = false;
          //this.formCompleted.emit(true);
          this.router.navigate(['/formsList']);
        },
        error => {
          this.closureForm.enable();
          this.loading = false;
        });
  }

  onSubmitNotApproved() {
    this.submitted = true;
    if (this.closureForm.invalid) {
      return;
    }
    if (this.fileUpload != null) {

      this.fileService.uploadApproverAttachment(this.fileUpload, this.closureForm.value.psrNo).subscribe(
        (res) => this.uploadResponse = res,
        (err) => this.error = err
      );
    }
    this.closureForm.patchValue({
      psrNo: this.formId,
      status: 6
    });
    this.closureForm.disable();
    this.loading = true;
    this.formService.SaveSummaryFormData(this.closureForm.value)
      .subscribe(
        data => {
          this.closureForm.enable();
          this.loading = false;
          //this.formCompleted.emit(true);
          this.router.navigate(['/formsList']);
        },
        error => {
          this.closureForm.enable();
          this.loading = false;
        });
  }

  onSubmitCancel() {
    this.router.navigate(['/formsList']);
  }
  getFormStatus(status) {
    let formStatus = this.formStatus.find(f => f.id == status);
    if (formStatus) {
      return formStatus.name;
    }
    return '';
  }
  onSuccessOk() {
    
    //this.router.navigate(['/formsList']);
    $('#edit-modal').modal('hide');
    
    
  }
  onDismissPopUp() {
    this.router.navigate(['/formsList']);
  }

  uploadFile(files) {
    
    this.fileUpload = files.item(0);
    this.filename = files[0].name;
    //this.closureForm.patchValue({
    //  approverAttachment: this.filename
    //});
  }

  downloadFile(psrFormNo, fileName) {
    
    this.fileService.downloadApproverAttachmentFile(psrFormNo, fileName).subscribe((response) => {
      let blob: any = new Blob([response.blob()], {
        type: "text/pdf; charset=utf-8",
      });
      const url = window.URL.createObjectURL(blob);
      // window.open(url);
      // window.location.href = response.url;
      fileSaver.saveAs(blob, "SilganClosures_" + fileName);
    }),
      (error) => console.log("Error downloading the file"),
      () => console.log("File downloaded successfully");
  }

  refreshForm() {
    
    this.authenticationService.currentUser.subscribe(x => {
      //   console.log(x);
      this.currentUser = x;
    });
    this.formId = this.route.snapshot.params['id'];
    $('#edit-modal').on('hidden.bs.modal', this.onDismissPopUp);
    if (this.formId) {

      this.formService.getById(this.formId).subscribe(data => {
        //     console.log("Form Data: " + JSON.stringify(data));

        this.formData = data;
        this.myldrsummary = this.formData.ldrSummary;
       
        this.formService.getSizeData(+this.formData.customerClosureMaterialSize).subscribe(
          p => {
            this.sizeData = p.size;
          });
        this.initiatedDate = formatDate(this.formData.initiatedDate, 'MM-dd-yyyy', 'en');
        this.customerSubmittedDate = formatDate(this.formData.customerSubmittedDate, 'MM-dd-yyyy', 'en');
        this.smeSubmittedDate = formatDate(this.formData.smeSubmittedDate, 'MM-dd-yyyy', 'en');

        this.closureForm.setValue({

          //customerEmail: this.formData.customerEmail,
          psrNo: this.formId,
          diskSummary: this.formData.diskSummary,
          linerSummary: this.formData.linerSummary,
          compoundSummary: this.formData.compoundSummary,
          fitmentResinSummary: this.formData.fitmentResinSummary,
          capshellResinSummary: this.formData.capshellResinSummary,
          fitmentSummary: this.formData.fitmentSummary,
          lubAddedLDRSummary: this.formData.lubAddedLDRSummary,
          colorantSummary: this.formData.colorantSummary,
          lDRSummary: this.formData.ldrSummary == null ? '' : this.formData.ldrSummary,
          faceSystemSummary: this.formData.faceSystemSummary,
          faceCoatingsSummary: this.formData.faceCoatingsSummary,
          reverseSystemSummary: this.formData.reverseSystemSummary,
          /*reverseCoatingsSummary: this.formData.reverseCoatingsSummary,*/
          status: this.formData.status,
          approverAttachment: this.closureForm.approverAttachment == null ? '' : this.formData.approverAttachment,
          approvedComments: this.formData.approvedComments,
          steelCoat: this.formData.steelCoat,
          endUseCode: this.formData.endUseCode
        });

        
        //this.closureForm.disable();
      });
    }
  }
}
