import { Component, OnInit } from '@angular/core';
import { ClosureFormService } from '../services/closure-form.service';
import { Router } from '@angular/router';

import { AdvancedSearch } from '../models/closure-form';
@Component({
  selector: 'app-advance-search',
  templateUrl: './advance-search.component.html',
  styleUrls: ['./advance-search.component.css']
})
export class AdvanceSearchComponent implements OnInit {
  marked = true;
  allSelect = true;

  checkedProfile = false;
  checkedPackage = false;
  checkedProduct = false;
  checkedProcess = false;
  checkedPalletization = false;

  textAll: string;
  textProfile: string;
  textPackage: string;
  textProduct: string;
  textProcess: string;
  textPalletization: string;



  advancedSearchdata;

  PackageType: string;
  Packagecondition: string;
  //PackagestringToFind: string;

  PalletizationType: string;
  Palletizationcondition: string;
  //PalletizationstringToFind: string;


  ProcessType: string;
  Processcondition: string;
  //ProcessstringToFind: string;


  ProductType: string;
  Productcondition: string;
  //ProductstringToFind: string;


  ProfileType: string;
  Profilecondition: string;
 // ProfilestringToFind: string;


  AllType: string;
  Allcondition: string;
  //AllstringToFind: string;

  constructor(private closureFomrService: ClosureFormService, private router: Router ) { }

  ngOnInit() {
    this.Packagecondition ="Contain";
    this.Palletizationcondition = "Contain";
    this.Processcondition = "Contain";   
    this.Productcondition = "Contain";
    this.Profilecondition = "Contain"; 
    this.Allcondition = "Contain";
  }
  
  goToFormList(): void {

    let advancedSearch = new AdvancedSearch();
    if (this.allSelect == true) {
      advancedSearch.AllType = "All";
      advancedSearch.Allcondition = this.Allcondition;//"CONTAINS";
      advancedSearch.AllstringToFind = this.textAll;//"test";
    } else {
      advancedSearch.AllType = "";
    }
    

    if (this.checkedPackage == true) {
      advancedSearch.PackageType = "Package";
      advancedSearch.Packagecondition = this.Packagecondition;//"Not EQUAL";
      advancedSearch.PackagestringToFind = this.textPackage;// "test";
    } else {
      advancedSearch.PackageType = "";
    }
    //advancedSearch.PackageType = "Package";
    

    if (this.checkedPalletization == true) {
      advancedSearch.PalletizationType = "Palletization";
      advancedSearch.Palletizationcondition = this.Palletizationcondition;//"Not EQUAL";
      advancedSearch.PalletizationstringToFind = this.textPalletization;//"test";
    } else {
      advancedSearch.PalletizationType = "";
    }
    //advancedSearch.PalletizationType = "Palletization";
    

    if (this.checkedProcess == true) {
      advancedSearch.ProcessType = "Process";
      advancedSearch.Processcondition = this.Processcondition; //"CONTAINS";
      advancedSearch.ProcessstringToFind = this.textProcess;//"test";
    } else {
      advancedSearch.ProcessType = "";
    }
  //  advancedSearch.ProcessType = "Process";
    

    if (this.checkedProduct == true) {
      advancedSearch.ProductType = "Product";
      advancedSearch.Productcondition = this.Productcondition; //"Not EQUAL";
      advancedSearch.ProductstringToFind = this.textProduct;//"test";
    } else {
      advancedSearch.ProductType = "";
    }
   // advancedSearch.ProductType = "Product";
    

    if (this.checkedProfile == true) {
      advancedSearch.ProfileType = "Profile";
      advancedSearch.Profilecondition = this.Profilecondition;// "CONTAINS";
      advancedSearch.ProfilestringToFind = this.textProfile;//"test";
    } else {
      advancedSearch.ProfileType = "";
    }
    //advancedSearch.ProfileType = "Profile";
   

    this.closureFomrService.GetAllFormsByAdvSearch(advancedSearch).subscribe(data => {
      this.router.navigate(['/formsList'], { state: { data: data } });
    });

  }
  toggleVisibility(e) {
    this.marked = e.target.checked;
  }
  conditionLogin(con,y) {

    if (y =='Package')
      this.Packagecondition = con;
    if (y == 'Palletization')
      this.Palletizationcondition = con;
    if (y == 'Process')
      this.Processcondition = con;
    if (y == 'Product')
      this.Productcondition = con;
    if (y == 'Profile')
      this.Profilecondition = con;
    if (y == 'All')
      this.Allcondition = con;
  }

}
