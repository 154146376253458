import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { User } from '../models/user';
import { UserRegister } from '../models/userRegister';

@Injectable({ providedIn: 'root' })
export class UserService {
    baseUrl: string;
    constructor(private http: HttpClient, @Inject('BASE_URL') appUrl: string) {
        this.baseUrl = appUrl;
        //this.baseUrl = "https://localhost:44392/";
        //this.baseUrl = "http://silganclosures.csdevhub.com/";
    }

    getAll() {
        return this.http.get<User[]>(`${this.baseUrl}users`);
    }

    getById(id: number) {
        return this.http.get<UserRegister>(`${this.baseUrl}users/${id}`);
    }

    register(user: UserRegister) {
        return this.http.post(`${this.baseUrl}users/register`, user);
    }

    update(user: any) {
        return this.http.put(`${this.baseUrl}users/${user.userId}`, user);
    }
    updatePassword(user: any) {
        return this.http.put(`${this.baseUrl}users/password/${user.userId}`, user);
    }

    delete(id: number) {
        return this.http.delete(`${this.baseUrl}users/${id}`);
    }
    changePassword(id: number, chagePasswordModel: any) {
        return this.http.put(`${this.baseUrl}users/password/${id}`, chagePasswordModel);
    }
    forgotPassword(emailforgotPassword: any) {
        return this.http.post<any>(`${this.baseUrl}users/forgetpassword`, emailforgotPassword);
    }
    resetPassword(emailResetPassword: any) {
        return this.http.post<any>(`${this.baseUrl}users/resetpassword`, emailResetPassword);
    }
    isVaildPasswordLink(params: string) {
       // console.log("Params:" + params);
        return this.http.get<any>(`${this.baseUrl}users/resetpassword?${params}`);
    }

   GetBackUpSmeUsers() {
     return this.http.get<User[]>(`${this.baseUrl}Users/GetBackUpSMEs`);
  }
}
