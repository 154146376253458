import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgForm, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ClosureFormService } from '../../services/closure-form.service';
import { ClosureForm } from '../../models/closure-form';
import { debug } from 'util';
@Component({
  selector: 'app-sign-and-send',
  templateUrl: './sign-and-send.component.html',
  styleUrls: ['./sign-and-send.component.css']
})
export class SignAndSendComponent implements OnInit {
  allSelect = false;
  @Input() formId: number;
  @Output() formCompleted = new EventEmitter();
  submitted = false;
  Name: string = '';
  disabledAgreement: boolean = true;
  SignAndSendForm: FormGroup;
  closureForms: ClosureForm[] = [];
  filteredForms: ClosureForm[] = [];
  get SignAndSendName() {
    return this.SignAndSendForm.get('Name');
  }
  constructor(private formBuilder: FormBuilder, private closureFomrService: ClosureFormService,) { }

  ngOnInit() {
    this.SignAndSendForm = this.formBuilder.group({
      Name: ['', Validators.required],
    });
    this.SignAndSendForm.controls['Name'].disable();
  }
  onSubmit() {
    this.submitted = true;
    if (this.SignAndSendForm.invalid) {
      return;
    }
    let Name = this.SignAndSendForm.controls['Name'].value;
    let obj = {
      completed: true,
      Name: Name
    };
    this.formCompleted.emit(obj);
   // this.loadAllForms();
  }
  private loadAllForms() {
    this.closureFomrService.getAll().subscribe(closureForms => {
      this.closureForms = closureForms;
      this.filteredForms = this.closureForms;
    });
  }
  ToggletxtName(event) { 
    this.Name = '';
    this.disabledAgreement = !event.target.checked;
    if (event.target.checked) {
      this.SignAndSendForm.controls['Name'].enable();
    }
    else {
      this.SignAndSendForm.controls['Name'].disable();
    }
  }
  onlyNumberValid(event: any) {
    var charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 45 && charCode > 31
      && (charCode < 48 || charCode > 57))
      return false;

    return true;
  }

}
