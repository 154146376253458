import { Component, OnInit, Input, OnDestroy, ViewChild } from "@angular/core";
import { CdkStepper } from "@angular/cdk/stepper";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { CustomStepperComponent } from "../custom-stepper/custom-stepper.component";
import { AuthenticationService } from "src/app/services/authentication.service";
import { User } from "src/app/models/user";
import { ClosureForm } from "src/app/models/closure-form";
import { ClosureFormService } from "src/app/services/closure-form.service";
import { FileService } from "../../services/file.service";
import * as fileSaver from "file-saver";
import { HttpResponse } from "@angular/common/http";


declare var $: any;
@Component({
  selector: "app-form-stepper",
  templateUrl: "./form-stepper.component.html",
  styleUrls: ["./form-stepper.component.css"],
})
export class FormStepperComponent {
  @ViewChild("appStepper", { static: true })
  appCustomStepper: CustomStepperComponent;
  id: number;
  formData: ClosureForm;
  formDataEmpty: ClosureForm;
  completed = false;
  completedFirst = false;
  //isOptional = false;
  currentUser: User;
  closureForms: ClosureForm[] = [];
  filteredForms: ClosureForm[] = [];
  curentUserRole;
 
  constructor(
    private route: ActivatedRoute,
    private formService: ClosureFormService,
    private closureFomrService: ClosureFormService,
    private fileService: FileService,
    private authService: AuthenticationService,
    private router: Router
  ) {}
  ngOnInit() {
    this.currentUser = this.authService.currentUserValue;
    this.currentUser == undefined ? null : this.currentUser.role;
    //this.curentUserRole = this.currentUser.role;
    this.id = this.route.snapshot.params["id"];
    let currentURL = this.router.url;

    if (currentURL.includes("formsearch")) {
      this.completedFirst = true;
      this.completed = true;
    }

    

   

    if (this.id) {
    } else {
      localStorage.setItem("dataSource", JSON.stringify(this.closureForms));
    }

    if (this.id) {
      this.formService.getById(this.id).subscribe((data) => {
        this.formData = data;
       console.log(this.formData);
        localStorage.setItem("dataSource", JSON.stringify(this.formData));
  

      });
    }

    if (currentURL.includes("formhistorystepper/" + this.id)) {
      this.formService.getFormHistoryById(this.id).subscribe((data) => {
        
      //  console.log("mydata", data);
        localStorage.setItem("dataSource", JSON.stringify(this.formData));
      }); 
 
    }

    //if (currentURL.includes("formhistorystepper/" + this.id)) {
    //  this.formService.getById(50630).subscribe((data) => {
    //    this.formData = data;
    //    console.log("mydata",this.formData);
    //    localStorage.setItem("dataSource", JSON.stringify(this.formData));
    //  }); 
 
    //}


    if (this.id) {
      this.completedFirst = true;
      if (this.currentUser != null && this.currentUser != undefined) {
        if (this.currentUser.role) {
          this.completed = true;
        }
      }
    }
  }
  completeStep(data): void {
     
    switch (this.appCustomStepper.selectedIndex) {
      case 0: this.formData.profileCompleted = true
        break
      case 1: this.formData.packageCompleted = true
        break
      case 2: this.formData.productCompleted = true
        break
      case 3: this.formData.processCompleted = true
        break
      case 4: this.formData.palletizationCompleted = true
        break
      default:
        this.formData.profileCompleted = false
        this.formData.packageCompleted = false
        this.formData.productCompleted = false
        this.formData.processCompleted = false
        this.formData.palletizationCompleted = false
    }
    localStorage.setItem("dataSource", JSON.stringify(this.formData));
    this.completed = data;
    setTimeout(() => {
      this.appCustomStepper.next();
      if (this.currentUser.role) {
        //    this.isOptional = true;
      }
    }, 500);
  }
  completeLastStep(data): void {
    
    this.completed = data;
    this.formData.palletizationCompleted = true
    localStorage.setItem("dataSource", JSON.stringify(this.formData));
    setTimeout(() => {
      if (!this.currentUser) {
        this.appCustomStepper.next();
      }
      if (this.currentUser.role) {
        this.completed = false;
        this.router.navigate(["/formsList"]);
      }
    }, 500);
  }
  completeSignAndSendStep(data): void {
    
    this.formService
      .UpdateFormStatusSignAndSend(this.id, data.Name)
      .subscribe((s) => {
        $("#form-status").modal("hide");
        // this.downloadPdf(this.id);
        this.appCustomStepper.next();
        this.downloadPdf(this.id);
        this.completed = data.completed;
        if (this.formData.profileCompleted = true) {
          this.formData.profileCompleted = true
        }
        else if (this.formData.packageCompleted = true) {
          this.formData.packageCompleted = true
        }
        else if (this.formData.productCompleted = true) {
          this.formData.productCompleted = true
        }
        else if (this.formData.processCompleted = true) {
          this.formData.processCompleted = true
        }
        else if (this.formData.palletizationCompleted = true) {
          this.formData.palletizationCompleted = true
        }
        localStorage.setItem("dataSource", JSON.stringify(this.formData));
        setTimeout(() => {
          this.loadAllForms();
        }, 15000);
      });
  }
  private loadAllForms() {
    this.closureFomrService.getAll().subscribe((closureForms) => {
      this.closureForms = closureForms;
      this.filteredForms = this.closureForms;
    });
  }
  public downloadPdf(Id) {
    this.fileService.downloadFile().subscribe((response) => {
      let blob: any = new Blob([response.blob()], {
        type: "text/pdf; charset=utf-8",
      });
      const url = window.URL.createObjectURL(blob);
      // window.open(url);
      // window.location.href = response.url;
      fileSaver.saveAs(blob, "SilganClosures_" + Id + ".pdf");
    }),
      (error) => console.log("Error downloading the file"),
      () => console.log("File downloaded successfully");
    //setTimeout(() => {
    //  this.loadAllForms();
    //}, 11000);
  }
}
interface IFormProfile {
  customerEmail: string;
  casManagerName: string;
  createdById: number;
  customerComapnyName: string;
  customerName: string;
  customerContactPhoneNumber: string;
  customerMailingAddress: string;
  location: string;
  customerClosureMaterialId: number;
  customerClosureMaterialType: string;
  customerClosureMaterialSize: number;
  orderTypeId: number;
  orderReasonFirstTimeId: number;
  orderReasonReplacementId: number;
  orderReasonReplacementExplaination: string;
  customerProduct: string;
  initialOrderQuantity: number;
  dateClosureNeeded: string;
  silganCoc: string;
  estmatedAnnualVolume: number;
  coatingRequired: boolean;
  specialIssues: string;
}
