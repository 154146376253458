import { Injectable, Inject } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { Http, ResponseContentType } from '@angular/http';
import { Observable } from 'rxjs';
import { HttpClient, HttpEvent, HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { map } from 'rxjs/operators';
@Injectable({ providedIn: 'root' })
export class FileService {
  baseUrl: string;
 
  constructor(private http: Http, private httpClient: HttpClient, @Inject('BASE_URL') appURL: string) {
    this.baseUrl = appURL;
  }

  downloadFile(): Observable<any> {

    return this.http.get(this.baseUrl + 'SavedPdf/SilganClosures.pdf', { responseType: ResponseContentType.Blob });
    //return this.http.get('https://localhost:44392/SavedPdf/SilganClosures.pdf', {responseType: ResponseContentType.Blob});
    //return this.http.get('http://112.196.25.162:8033/SavedPdf/SilganClosures.pdf', {responseType: ResponseContentType.Blob});
    // return this.http.get('http://capsure.silgancls.com/SavedPdf/SilganClosures.pdf', { responseType: ResponseContentType.Blob });
  }
  downloadApproverAttachmentFile(psrFormId, fileName): Observable<any> {

    return this.http.get(this.baseUrl + 'UploadFile/ApproverAttachments/' + psrFormId + '/' + fileName+'', { responseType: ResponseContentType.Blob });
  }
  downloadExcelFile(): Observable<any> {
   // console.log(this.baseUrl);

    return this.http.get(this.baseUrl + 'SaveExcel/SilganClosures.xlsx', { responseType: ResponseContentType.Blob });
    // return this.http.get('https://localhost:44392/SaveExcel/SilganClosures.xlsx', { responseType: ResponseContentType.Blob });
    //return this.http.get('http://112.196.25.162:8033/SaveExcel/SilganClosures.xlsx', { responseType: ResponseContentType.Blob });
    //return this.http.get('http://capsure.silgancls.com/SaveExcel/SilganClosures.xlsx', { responseType: ResponseContentType.Blob });

  }

  public uploadPkg(data, userId, filename) {
    let uploadURL = `${this.baseUrl}forms/Upload/Pkg/${userId}`;    
    const formData: FormData = new FormData();
    formData.append('file', data, filename);
    return this.httpClient.post<any>(uploadURL, formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(map((event) => {
      switch (event.type) {
        case HttpEventType.UploadProgress:
          const progress = Math.round(100 * event.loaded / event.total);
          return { status: 'progress', message: progress };
        case HttpEventType.Response:
          return event.body;
        default:
          return `Unhandled event: ${event.type}`;
      }
    })
    );
  }
  public uploadPrdct(data, userId) {
    
    let uploadURL = `${this.baseUrl}forms/Upload/Prdct/${userId}`;
    
    const formData: FormData = new FormData();
    formData.append('file', data, data.name);
    return this.httpClient.post<any>(uploadURL, formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(map((event) => {
      switch (event.type) {
        case HttpEventType.UploadProgress:
          const progress = Math.round(100 * event.loaded / event.total);
          return { status: 'progress', message: progress };
        case HttpEventType.Response:
          return event.body;
        default:
          return `Unhandled event: ${event.type}`;
      }
    })
    );
  }
  public uploadApproverAttachment(data, userId) {
    
   // console.log(this.baseUrl);
    let uploadURL = `${this.baseUrl}forms/Upload/ApproverFile/${userId}`;
    //debugger;
    const formData: FormData = new FormData();
    formData.append('file', data, data.name);
    return this.httpClient.post<any>(uploadURL, formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(map((event) => {
      switch (event.type) {
        case HttpEventType.UploadProgress:
          const progress = Math.round(100 * event.loaded / event.total);
          return { status: 'progress', message: progress };
        case HttpEventType.Response:
          return event.body;
        default:
          return `Unhandled event: ${event.type}`;
      }
    })
    );
  }

  downloadFinishDrawingFile(formId, fileName): Observable<any> {
    
    return this.http.get(this.baseUrl + 'UploadFile/finishDrawing/' + formId + '/' + fileName, { responseType: ResponseContentType.Blob });
    
  }
}


//export class FileService {

//  constructor(private http: Http) {}

//  downloadFile(): Observable<any> {
//    //return this.http.get('https://localhost:44392/SavedPdf/SilganClosures.pdf', {responseType: ResponseContentType.Blob});
//     return this.http.get('http://112.196.25.162:8033/SavedPdf/SilganClosures.pdf', {responseType: ResponseContentType.Blob});
//   // return this.http.get('http://capsure.silgancls.com/SavedPdf/SilganClosures.pdf', { responseType: ResponseContentType.Blob });
//  }
//  downloadExcelFile(): Observable<any> {
//   // return this.http.get('https://localhost:44392/SaveExcel/SilganClosures.xlsx', { responseType: ResponseContentType.Blob });
//    return this.http.get('http://112.196.25.162:8033/SaveExcel/SilganClosures.xlsx', { responseType: ResponseContentType.Blob });
//    //return this.http.get('http://capsure.silgancls.com/SaveExcel/SilganClosures.xlsx', { responseType: ResponseContentType.Blob });

//  }
//} 
