import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ClosureFormService } from 'src/app/services/closure-form.service';
import { AuthenticationService } from '../services/authentication.service';
import { CustomerAuthenticationService } from '../services/customer-authentication.service';
import { AlertService } from '../alert/alert.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  templateUrl: 'customer-login.component.html',
  styleUrls: ['./customer-login.component.scss']
})
export class CustomerLoginComponent implements OnInit {
  CustomerloginForm: FormGroup;
  sendCustomerData: any;
  loading = false;
  submitted = false;
  returnUrl: string;
  _d: any;
  _i: any;
  _e: any;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    private formService: ClosureFormService,
    private customerAuthenticationService: CustomerAuthenticationService,
    private toastr: ToastrService
  ) {
    // redirect to home if already logged in
    //  if (this.authenticationService.currentUserValue) {
    //      this.router.navigate(['/']);
    //}
    //this.loginForm = this.formBuilder.group({
    //  username: ['', Validators.required],
    //  password: ['', Validators.required]
    //});
  }

  ngOnInit() {
    
    this.CustomerloginForm = this.formBuilder.group({
      password: ['', Validators.required]
    });
    this.route.url.subscribe(data => {
  //    console.log(data);
    });
    this.route.queryParams.subscribe(params => {
   //   console.log(params);
      this._d = params._d;
      this._i = params._i;
      this._e = params._e;

      //});

      let queryString = '';
      for (let key in params) {
        if (params.hasOwnProperty(key)) {
          let element = params[key];
          queryString = queryString + key + '=' + element + '&';
        }
      }
      queryString = queryString.substring(0, queryString.length - 1);
  //    console.log("queryString" + queryString);
      if (queryString != '') {

        this.formService.isVaildPasswordLink(queryString)
          .subscribe(res => {
            
            //this.router.navigate(['/profile'])
            this.sendCustomerData = res.response;
            if (res.message == 'Link expired') {
              //this.router.navigate(['/Account/CustomerHome']);
              this.toastr.error("Link expired! You have already submitted this form.", '', { timeOut: 5000 });
              this.router.navigate(['Account/CustomerHome'], { queryParams: { _d: this._d, _i: this._i, _e: this._e } });
            }

            //  console.log(res.response);
          });
      }
      else {
        this.router.navigate(['Account/CustomerHome']);
      }
    });
  }

  // convenience getter for easy access to form fields
  //get f() { return this.CustomerloginForm.controls; }

  noSpace(event: any) {
    var charCode = (event.which) ? event.which : event.keyCode;
    if (charCode == 32){
    //  console.log(charCode);
      return false;
    }
     else
          return true;
  }
  
  onSubmit() {
    

    if (!this.CustomerloginForm.valid) {
  //    console.log("form is invaild");
      this.toastr.error("Please provide password");
      return;
    }
    this.loading = true;
    this.sendCustomerData.password = this.CustomerloginForm.get('password').value;
    //console.log(this.sendCustomerData.password);
    //console.log(this.sendCustomerData);
    //this.formService.customerLogin(this.sendCustomerData)
    //  .subscribe(res => {
    //    // this.router.navigate(['/profile'])
    //    // if (res.isRequestSuccessfull && res.response) {
    //    //   this.router.navigate(['/formstepper/57']);
    //    // this.messageShow = true;
    //    // this.message = "Your password has changed."
    //    // }
    //    // else {
    //    // this.message = res.message;
    //    // }
    //    console.log(res);
    //    this.router.navigate(['/formstepper/' + res.pSrNo]);
    //  });

    this.customerAuthenticationService.login(this.sendCustomerData)
      .pipe(first())
      .subscribe(
        data => {
          //this.router.navigate([this.returnUrl]);
          this.loading = false;
          this.router.navigate(['/formcustomerstepper/' + this.sendCustomerData.pSrNo]);
        },
        error => {
          this.toastr.error(error);
          this.loading = false;
        });


  }
}
