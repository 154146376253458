import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClosureFormService } from '../services/closure-form.service';
import { HistoryData, PSRFormHistory } from '../models/closure-form';
import { ClosureForm } from 'src/app/models/closure-form';

@Component({
  selector: 'app-form-history',
  templateUrl: './form-history.component.html',
  styleUrls: ['./form-history.component.css']
})
export class FormHistoryComponent implements OnInit {
  formId: number;
  //formsHistory: HistoryData[];
  formsHistory: PSRFormHistory[];
  formData: ClosureForm;
  //closureForm;
  constructor(
    private route: ActivatedRoute,
    private formService: ClosureFormService,
    private router: Router,
  ) {
    this.formId = +this.route.snapshot.params['id'];
   }

  ngOnInit() {
  
    this.formId = +this.route.snapshot.params['id'];
    if (this.formId) {
      this.formService.getHistoryPSRno(this.formId).subscribe(data => {
        
        this.formsHistory = data;
        this.formService.getById(this.formId).subscribe(data => {
          this.formData = data;
          console.log("mydata",this.formData)
          
        })
        //   console.log(JSON.stringify(this.formsHistory));
      //  this.closureForm.setValue({

      //    //customerEmail: this.formData.customerEmail,

      //    psrFormNoByYear: this.formData.psrFormNoByYear
      //})
      },
        err => {

        });
    }
  }
  onHistoryClick(formId: number) {
  //  console.log("form Id " + formId);
    this.router.navigate(['/formhistorystepper/' + formId]);
  }

}
