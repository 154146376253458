
export class ClosureForm {

  currentUserEmail: string;
  currentUserPhone: string;

  customerName: string;
  customerContactPhoneNumber: string;
  customerEmail: string;
  //customerClosureMaterialId?: number;
  customerClosureMaterial: string;
  customerClosureMaterialBackupForms: string;
  productName: string;
  location: string;
  customerClosureMaterialSize?: number;
  customerClosureMaterialType: string;
  initialOrderQuantity: string;
  dateClosureNeeded: string;
  silganCoc: string;
  estmatedAnnualVolume: string;
  customerClosureSpecialIssues: string;
  status: number;
  customerComapnyName: string;
  customerMailingAddress: string;
  orderTypeId?: number;
  orderReasonFirstTimeId?: number;
  orderReasonReplacementId?: number;
  orderReasonReplacementExplaination: string;
  customerProduct: string;
  coatingRequired: string;
  specialIssues: string;
  casManagerName: string;
  initiatedDate: string;
  customerSubmittedDate: string;
  smeSubmittedDate: string
  notes: string;
  address:string
  city: string;
  state: string;
  zipCode: string;
  
  customerEXT: string;
  customerContact: string;

  experimentalTypeName: string;
  
  psrFormNoByYear: string;
  
  

  // Package
  willhaveprintedgraphics: string;
  willPrintDesignUTC: string;
  willhaveprintedgraphicsType: string;
  specialInstructions: string;
  containerType: string;
  containerFinishNumber: string;
  drawingUpload:File;
  containerMenufacturer: string;
  containerClosureSize: string;
  containerComments: string;
  plasticType: number;
  outsert: string;
  finishDrawing: string; 

  // product
  isFatOnSurface: string;
  isBeverage: string;
  beverageType: string;
  beverageTypeOther: string;
  phLevelId: string;
  productPhLevel: string;
  isVinegarPresent: string;
  amountofVinegarPresent: string;
  isSO2Present: string;
  ppm: string;
  isAlcoholPresent: string;
  amntOfAlcoholPresent: string;
  shelfLifePackedProduct: string;
  productContainCBDorTHC: string;
  fdaFoodCategory: string;
  fdaCondition: string;
  shippedOutsideUSA: string;
  listTheCountriesWhrPckgShipped: string;
  whtCountry: string;
  dieteticOrLowCalorie: string;
  cbdfile: string;
  cbddrwningfileupload: File;
  productEstimatedpHLevel: string;
  ozoneGasTreated: string;

  //process 
  packerNameAddress: string;
  headspacePercentage: string;
  productFillTemperature: string;
  sealedWithPressure: string;
  pressuredBy: string;
  pressureAmount: string;
  pressureApplied: string;
  typeOfCapper: string;
  heatingMethod: string;
  capperManufacturer: string;
  arrangCapperToolWithField1: string;
  arrangCapperToolWithField2: string;
  capperManufacturerName: string;
  packageProcess: string;
  processPressure: string;
  coolingPSIG: string;
  processOverriding: string;
  cookedBy: string;
  processPressureType: string;
  finishedPackageVacuum: string;
  finishedPackagePressure: string;
  finishedPackagePSI: string;
  closureSterilization: string;
  chemicalName: string;
  doNeckBandsCoverSidewall: string;
  volumesCO2: string;
  chemicalConcentration: string;
  isDudDetectionAvailable: string;
  packageSterilizedHPP: string;

  chuckType: string;
  outOfCapperVacuumInHg: string;
  waterTemp: string;
  pasturizationTime: string;
  productTemp: string;

  packerNameAndAddress: string;

  holdTime: string;
  holdTimeMinutes: string;
  rateOfRotation: string;
  LN2: string;
  isitGasLiquid: string;

  // Palletization
  
  palletsHigh: string;
  packagesPerTray: string;
  traysPerLayer: string;
  layersPerPallet: string;
  weightPerPallet: string;
  warehouseStorageCondition: string;
  externalCondensationWithHumidity: string;
  retailDisplayConditions: string;
  humidity: string;
  productWeightPerPalletInLbs: string;
  packageTrayPacked: string;
  customerContantNameTitle: string;

  formId: number;
  salesRepName: string;
  casPhoneNumber: string;
  formCompletedDate: string;
  customerCompany: string;
  customerContect: string;
  businessOrderInformationId?: number;
  businessOrderReasonId?: number;

  cutomerBusinessAddress: string;
  bpaNICoatingRequired?: boolean;
  approvedStatusId: number;
  pSRNo: string;
  pSrNo: string;
  approvedDate: string;
  approvedInitial: string;
  approvedComments: string;
  customerSectionCompanyName: string;
  customerSectionDate: string;
  customerProjectName: string;
  fitmentColor: string;
  diskColor: string;
  capShellColor: string;
  totalPalletWeightPound: string;





  containerCapacity: string;
  appliedId?: number;
  packageProcessId?: number;
  packageTryPackedId?: number;
  warehouseTemeratureId?: number;
  warehouseHumidityId?: number;
  retailDisplayTemeratureId?: number;
  retailDisplayHumidityId?: number;
  warehouseShippingDisplayIssues: string;
  isRegularatoryCompliance?: boolean;
  submitterContactNameTitle: string;
  submitterDateInformation: string;
  submitterMailingAddress: string;
  submitterPhone: string;
  submitterEmailAddress: string;

  token: string;


  // Summary

  diskSummary: string;
  linerSummary: string;
  compoundSummary: string;
  fitmentResinSummary: string;
  capshellResinSummary: string;
  fitmentSummary: string;
  lubAddedLDRSummary: string;
  colorantSummary: string;
  ldrSummary: string;
  faceSystemSummary: string;
  faceCoatingsSummary: string;
  reverseSystemSummary: string;
  reverseCoatingsSummary: string;
  capExteriorCoating: string;
  capInteriorTopCoat: string;
  aluminumAlloyTemperThickness: string;
  steelWeight: string;
  


  profileCompleted   : boolean;
  packageCompleted   : boolean;
  productCompleted   : boolean;
  processCompleted: boolean;
  palletizationCompleted: boolean;

  SMEApprovalStatus: Number;
  approverAttachment: File;
  endUseCode: string;
  steelCoat: string;
  packagingProcessOtherDescription: string;
  isFormLock: Number;

}

export class HistoryData {
  id: number;
  historyInsertedDate: string;
  userName: string;
  psrFormNoByYear: string;
}

export class AdvancedSearch {
  PackagestringToFind         : string;
  Packagecondition            : string;
  PackageType                 : string;
  PalletizationstringToFind   : string;
  Palletizationcondition      : string;
  PalletizationType           : string;
  ProcessstringToFind         : string;
  Processcondition            : string;
  ProcessType                 : string;
  ProductstringToFind         : string;
  Productcondition            : string;
  ProductType                 : string;
  ProfilestringToFind         : string;
  Profilecondition            : string;
  ProfileType                 : string;
  AllType                     : string;
  Allcondition                : string;
  AllstringToFind             : string;
}

export class PSRFormHistory {
  HistoryData: HistoryData;
  SMEStatusLog: SMEStatusLog;
  
}

export class SMEStatusLog {
  SmeStatusId: String;
  PSRFormId: Number;
  UserId: Number;
  ApprovedStatusId: Number;
  ApprovedOn: Date; 
  LastApprovedStatusId: Number;
  SMEName: String;
  ApprovedStatus: Number;
  FormApprovedByRoleId: Number;
}
