import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';

import { Customer } from '../models/customer';


@Injectable({ providedIn: 'root' })
export class CustomerAuthenticationService {
  private currentCustomerSubject: BehaviorSubject<Customer>;
  public currentCustomer: Observable<Customer>;
  baseUrl: string;
  constructor(private http: HttpClient, @Inject('BASE_URL') appURL: string) {
    this.currentCustomerSubject = new BehaviorSubject<Customer>(JSON.parse(localStorage.getItem('currentCustomer')));
    this.currentCustomer = this.currentCustomerSubject.asObservable();
    //this.baseUrl = "https://localhost:44392/";
    this.baseUrl = appURL;
    //this.baseUrl = "http://silganclosures.csdevhub.com/";
  }

  public get currentCustomerValue(): Customer {
    return this.currentCustomerSubject.value;
  }

  login(form: any) {
    return this.http.post<any>(`${this.baseUrl}forms/authenticate`, form)
      .pipe(map(Customer => {
        // login successful if there's a jwt token in the response
        if (Customer && Customer.token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentCustomer', JSON.stringify(Customer));
          this.currentCustomerSubject.next(Customer);
        }

        return Customer;
      }));
  }

  //logout() {
  //    // remove user from local storage to log user out
  //    localStorage.removeItem('currentUser');
  //  this.currentCustomerSubject.next(null);
  //}
}
