import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  resetPasswordForm: FormGroup;
  sendUserData: any;
  messageShow: boolean = false;
  message: string = '';
  constructor(private route: ActivatedRoute, private formBuilder: FormBuilder, private userService: UserService) { }

  ngOnInit() {
    this.route.url.subscribe(data => {
    //  console.log(data);
    });
    this.route.queryParams.subscribe(params => {
     // console.log(params);
      //});
      let queryString = '';
      for (let key in params) {
        if (params.hasOwnProperty(key)) {
          let element = params[key];
          queryString = queryString + key + '=' + element + '&';
        }
      }
      queryString = queryString.substring(0, queryString.length - 1);
     // console.log("queryString" + queryString);
      if (queryString != '') {
        this.userService.isVaildPasswordLink(queryString)
          .subscribe(res => {
            //this.router.navigate(['/profile'])
            this.sendUserData = res.response;
          //  console.log(res.response);
          });
      }
    });
    this.resetPasswordForm = this.formBuilder.group({
      newPassword: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]]
    }, { validator: this.passwordConfirming });
  }
  passwordConfirming(c: AbstractControl): { invalid: boolean } {
    if (c.get('newPassword').value !== c.get('confirmPassword').value) {
      return { invalid: true };
    }
  }
  onSubmit() {
    if (!this.resetPasswordForm.valid) {
     // console.log("form is invaild");
      return;
    }
    this.sendUserData.password = this.resetPasswordForm.get('newPassword').value;
   // console.log(this.sendUserData.password);
    this.userService.resetPassword(this.sendUserData)
      .subscribe(res => {
        //this.router.navigate(['/profile'])
        if (res.isRequestSuccessfull && res.response) {
          this.messageShow = true;
          this.message = ""
        }
        else {
          this.message = res.message;
        }
       // console.log(res);
      });
  }

}
