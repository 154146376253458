import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroupDirective, FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { UserService } from '../services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { phoneNumberValidator } from '../validators/phoneNumber-validator'
import { User } from '../models/user';
import { stringTextValidator } from '../validators/stringText-validator';
import { ToastrService } from 'ngx-toastr';
import { debounce } from 'rxjs/operators';
import { debug } from 'util';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html'
})
export class EditUserComponent implements OnInit {

  user: User;
  loading: boolean = false;
  //userId: number;
  userEditForm: FormGroup;
  submitted = false;

  getTeamId: string;
  dropdownList = [];
  selectedId = "";

  //selectedItems: Map<string, Array<any>>;
  selectedItems: any;
  dropdownSettings = {};
  @Input() userId: number;
  @Output() userUpdated = new EventEmitter();

  get role() {
    return this.userEditForm.get('role');
  }
  get phone() {
    return this.userEditForm.get('phone');
  }
  get email() {
    return this.userEditForm.get('email');
  }
  get firstName() {
    return this.userEditForm.get('firstName');
  }
  get lastName() {
    return this.userEditForm.get('lastName');
  }
  get teamId() {
    return this.userEditForm.get('teamId');
  }
  get team() {
    return this.userEditForm.get('team');
  }

  BackUpSmeUsers: User[]
  //get editForm() { return this.userEditForm.controls; }
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    
    this.GetBackupSMEs();
    //this.userId = this.route.snapshot.params['id'];
    this.userEditForm = this.formBuilder.group({
      userId: 0,
      username: [''],
      firstName: ['', [Validators.required, stringTextValidator]],
      lastName: ['', [Validators.required, stringTextValidator]],
      phone: ['', [Validators.required, phoneNumberValidator, Validators.minLength(10), Validators.maxLength(12)]],
      email: ['', [Validators.required, Validators.email]],
      role: ['Select Role', Validators.required],
      teamId: '',
      team: ['', Validators.required],
      BackUpSmeUserId: []
    });

    //this.selectedItems = new Map<string, Array<any>>();
 //   selectedItems = [];
    this.dropdownList = [
      { "id": 1, "itemName": "Metal (Steel)" },
      { "id": 2, "itemName": "Composite" },
      { "id": 3, "itemName": "ROPP (Alum)" },
      { "id": 4, "itemName": "Plastic" }
    ];
    //this.selectedItems[""] = [
    //  { "id": 2, "itemName": "Composite" }
    //];
    this.dropdownSettings = {
    }; 


    this.userEditForm.get('username').disable();
    if (this.userId) {
      this.getUser(this.userId);
    }
  }

  onlyNumberValid(event: any) {
    var charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 45 && charCode > 31
      && (charCode < 48 || charCode > 57))
      return false;

    return true;
  }
  onlyLettersValid(event: any) {
    var charCode = (event.which) ? event.which : event.keyCode;
    if (charCode == 46  && (charCode >= 65  || charCode <= 90) && (charCode >= 97 || charCode <= 112))
      return true;
    else
      return false;
  }
  getUser(id: number) {
    
    this.userEditForm = this.formBuilder.group({
      userId: 0,
      username: [''],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      phone: ['', [Validators.required,  Validators.minLength(10), Validators.maxLength(14)]],
      email: ['', [Validators.required, Validators.email]],
      role: ['Select Role'],
      teamId: '',
      team: '',
      BackUpSmeUserId:[]
    });

    this.userService.getById(id).subscribe(data =>
    {
      
      
   //   console.log(data);
      this.userEditForm.setValue({
        userId: id,
        username: data.username,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        phone: data.phone,
        role: data.role,
        teamId: data.teamId,
        team: '',
        BackUpSmeUserId: data.backUpSmeUserId
      });
     // console.log(data.teamId);
      //if (data.teamId == "1234") {
      //  this.selectedItems = [
      //    { "id": 1, "itemName": "Metal (Steel)" },
      //    { "id": 2, "itemName": "Composite" },
      //    { "id": 3, "itemName": "ROPP (Alum)" },
      //    { "id": 4, "itemName": "Plastic" }
      //  ];
      //}

      var output = data.teamId.split('');
      var abc = [];
      for (let i = 0; i < output.length; i++) {
          let id = output[i];       
          if (id == "1") {
            var obj = new Object();
            obj['id'] = 1;
            obj['itemName'] = 'Metal (Steel)';          
            abc.push(obj);
          }
          if (id == "2") {
            var obj = new Object();
            obj['id'] = 2;
            obj['itemName'] = 'Composite';
           // console.log(obj);         
            abc.push(obj);
            
          }
          if (id == "3") {
            var obj = new Object();
            obj['id'] = 3;
            obj['itemName'] = 'ROPP (Alum)';
            abc.push(obj);
          }
          if (id == "4") {
            var obj = new Object();
            obj['id'] = 4;
            obj['itemName'] = 'Plastic';
            abc.push(obj);
          }
      }
      this.selectedItems = abc;
     // console.log(this.selectedItems);
   //     this.enable();    
      //this.userEditForm.get('username').disable();
      //this.userEditForm.controls['role'].disable();
      //this.userEditForm.controls['teamId'].disable();
    });
    this.onChangesDropdown();
  }

  onChangesDropdown() {
    this.userEditForm.get('role').valueChanges
      .subscribe(roleValue => {
        if (roleValue != 'SME') {
          this.disable();
          //this.userEditForm.patchValue({
          //  teamId: '0'
          //});
          //this.teamId.setValidators(null);
          //this.userEditForm.get('teamId').disable();
        }
        else {
          this.enable();
   //       console.log("set vaildator");
          //this.userEditForm.get('teamId').enable();
          //this.teamId.setValidators([Validators.required, Validators.min(1)]);
        }
        this.teamId.updateValueAndValidity();
      });
  }


  disable() {
    
    this.dropdownSettings = {
      singleSelection: false,
      text: "Select Closure Material Team",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: false,
      classes: "myclass custom-class",
      disabled: true,
      escapeToClose :true
    };
    // this.selectedItems["SME"] = [];

  }

  enable() {
    this.dropdownSettings = {
      singleSelection: false,
      text: "Select Closure Material Team",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: false,
      classes: "myclass custom-class",
      disabled: false,
      escapeToClose :true
    };
  }

  onSubmit() {
    debugger;
    this.submitted = true;

    let teamAssignedId = "";
    for (let i = 0; i < this.userEditForm.value.team.length; i++) {
      teamAssignedId += this.userEditForm.value.team[i].id + ",";
    }
    teamAssignedId = teamAssignedId.substring(0, teamAssignedId.length - 1);
    this.userEditForm.patchValue({
      teamId: teamAssignedId
    });

    if (this.userEditForm.invalid) {
      return;
    }
    
    this.userService.update(this.userEditForm.value)     
      .subscribe(x => {
        this.toastr.success("User updated successfully");
        this.userUpdated.emit(null);
        //this.router.navigate(['/'])
      }, err => {
        this.toastr.error(err);
      });
  }

  GetBackupSMEs() {
    
    this.userService.GetBackUpSmeUsers().subscribe(data => {
      
      this.BackUpSmeUsers = data;
    });
  }
}
