import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroupDirective, FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ClosureFormService } from '../services/closure-form.service';

@Component({
  selector: 'formsearch',
  templateUrl: './formsearch.component.html',
  styleUrls: ['./formsearch.component.css']
})
export class FormSearchComponent implements OnInit {
  smeTabForAdvanceSearch: number;
  constructor(private formBuilder: FormBuilder, private router: Router, private closureFormService: ClosureFormService, private toastr: ToastrService) {
 
  }
  field: string = '';
  condition: string = '';
  text: string = '';
  Rows: number[] = [0];
  SearchForm: FormGroup;
  SearchFieldValues: SearchFieldValue[] = [];
  ngOnInit() {
    
    if (history.state.data) {
      this.smeTabForAdvanceSearch = history.state.data.smeTabIndex;
      
    }
    else {
      this.smeTabForAdvanceSearch = -1;
    }
    //this.smeTabForAdvanceSearch = this.route.snapshot.params['id'];
    this.SearchForm = this.formBuilder.group({
      userId: 0,
      username: [''],
      firstName: [''],
      lastName: [''],
      phone: [''],
      email: [''],
    });
  }
  goToFormList() {
    
    this.SearchFieldValues = [];
    let IsSearchValid: boolean = true;
    this.Rows.forEach((value, i) => {
      let field = (<HTMLSelectElement>document.getElementById('field' + i)).value;
      let condition = (<HTMLInputElement>document.getElementById('condition' + i)).value;
      let text = (<HTMLInputElement>document.getElementById('text' + i)).value;
      if (field == "Please select") {
        IsSearchValid = false;
        this.toastr.error("Please Select Search In!");
        return;
      }
      if ( text == '') {
        IsSearchValid = false;
        this.toastr.error("Please Enter Text To Search!");
        return;
      }
      if (IsSearchValid)
        this.SearchFieldValues.push({ Field: field, Condition: condition, Searchext: text })
    });
    let queryString = this.SearchFieldObjectTostring();
   // console.log(queryString);
    if (queryString == "Please select like '%%'") {
      this.toastr.error("Please choose any condition to search!");
      return;
    }
    if (IsSearchValid) {
      this.closureFormService.GetAllFormsByAdvSearch2(queryString, this.SearchFieldValues, this.smeTabForAdvanceSearch).subscribe(data => {
        var dataSet = {
          smeTabIndex: this.smeTabForAdvanceSearch,
          data: data
        };
        this.router.navigate(['/formsList'], { state: { data: dataSet } });
      });
    }
  }
  SearchFieldObjectTostring(): string {
    
    let str = "";
    this.SearchFieldValues.forEach((searchFieldValue, index) => {

      str += searchFieldValue.Field;
      if (searchFieldValue.Condition == "Contain") {
        str += " like '%" + searchFieldValue.Searchext + "%'";
      } else if (searchFieldValue.Condition == "Equal") {
        str += "= '" + searchFieldValue.Searchext + "'";
      } else if (searchFieldValue.Condition == "Not Contain") {
        //str += "<>'" + searchFieldValue.Searchext + "'";
        str += " NOT like '%" + searchFieldValue.Searchext + "%'";
      }
      if (this.SearchFieldValues.length != (index + 1))
        str += " AND  ";
    });
    return str;
  }

  AddNewRow(i) {
    
    let field = (<HTMLInputElement>document.getElementById('field' + i)).value;
    let condition = (<HTMLInputElement>document.getElementById('condition' + i)).value;
    let text = (<HTMLInputElement>document.getElementById('text' + i)).value;

    //if (field == 'Please select' || text == '') {
    //  this.toastr.error("Please choose any condition to search!");
    //  return;
    //}

    if (field == "Please select") {
     // IsSearchValid = false;
      this.toastr.error("Please Select Search In!");
      return;
    }
    if (text == '') {
    //  IsSearchValid = false;
      this.toastr.error("Please Enter Text To Search!");
      return;
    }
    var x = document.getElementById('btnAddNewRow' + i);
    if (x.style.display === "none") {
      x.style.display = "inline-block";
    } else {
      x.style.display = "none";
    }
    this.Rows.push(this.Rows.length);

    setTimeout(() => {
      let ExistingFields: number[] = [];
      let newfieldOptions = (<HTMLSelectElement>document.getElementById('field' + (i + 1))).options;
      this.Rows.forEach((value, i) => {
        let selectedIndex = (<HTMLSelectElement>document.getElementById('field' + i)).selectedIndex;
        ExistingFields.push(selectedIndex);
      });

      ExistingFields.forEach((ExistingIndex, index) => {
        newfieldOptions[ExistingIndex].hidden = true;
      });
    }, 500);

  }
  RemoveThisRow(i) {
    
    this.Rows.splice(i, 1);
    if (this.Rows.length == 1) {
      var x = document.getElementById('btnAddNewRow0');
      if (x.style.display != "none") {
        x.style.display = "none";
      } else {
        x.style.display = "inline-block";
      }
    }

    if (this.Rows.length > 1 && this.Rows.length <= i && i != 1) {
      var x = document.getElementById('btnAddNewRow' + (i - 1));
      if (x.style.display != "none") {
        x.style.display = "none";
      } else {
        x.style.display = "inline-block";
      }
    }
  }

  hideSelectedOptionForAnotherDropdowns(event, currentRow): void {
    
    setTimeout(() => {
      let ExistingFields: number[] = [];
      this.Rows.forEach((value, i) => {
        let selectedIndex = (<HTMLSelectElement>document.getElementById('field' + i)).selectedIndex;
        ExistingFields.push(selectedIndex);
      });

      this.Rows.forEach((value, i) => {
        if (i != currentRow) {
          let selectedoption = event.target.selectedIndex;
          let options = (<HTMLSelectElement>document.getElementById('field' + i)).options;
          for (var ind = 0; ind < options.length; ind++) {
            if (selectedoption == ind || !ExistingFields.includes(ind))
              options[ind].hidden = false;
          }
          options[selectedoption].hidden = true;
        }
      })
    }, 500);



  }
  public Fields: Field[] = [

    { name: "Special Instructions", value: "SpecialInstructions" },
    //{ name: "", value: "AppliedId" },
    { name: "ApprovedComments", value: "ApprovedComments" },
  //  { name: "ApprovedDate", value: "ApprovedDate" }, test
    { name: "ApprovedInitial", value: "ApprovedInitial" },
    //{ name: "", value: "ApprovedStatusId" },
    { name: "Will there be a print design under the cap (UTC)?", value: "WillPrintDesignUTC" },
    //{ name: "", value: "BpaNiCoatingRequired" },
    //{ name: "", value: "BusinessOrderInformationId" },
    //{ name: "", value: "BusinessOrderReasonId" },
    { name: "Cap Shell Color", value: "CapShellColor" },
    //{ name: "", value: "CasPhoneNumber  " },
    //{ name: "", value: "ContainerCapacity  " },
    { name: "ContainerClosureSize", value: "ContainerClosureSize  " },
    { name: "Comments", value: "ContainerComments  " },
    { name: "Finish Number", value: "ContainerFinishNumber " },
    { name: "Manufacturer", value: "ContainerMenufacturer " },
    { name: "Container Type", value: "ContainerType" },
    { name: "Closure Material", value: "CustomerClosureMaterial" },
    { name: "Size", value: "CustomerClosureMaterialSize  " },
    { name: "Type", value: "CustomerClosureMaterialType  " },
    //{ name: "", value: "CustomerClosureSpecialIssues " },
    //{ name: "", value: "CustomerCompany " },
    { name: "Customer Phone", value: "CustomerContactPhoneNumber" },
    // { name: "", value: "CustomerContect " },
    { name: "Customer Name", value: "CustomerName " },
    { name: "Customer Project Name (optional)", value: "CustomerProjectName" },
    //{ name: "", value: "CustomerSectionCompanyName" },
    //{ name: "", value: "CustomerSectionDate" },
    //{ name: "", value: "CustomerSubmittedDate " },
    //{ name: "", value: "SMESubmittedDate" },
    //{ name: "", value: "CutomerBusinessAddress" },
    { name: "Customer Email", value: "CustomerEmail" },
   // { name: "Date Closure Needed", value: "DateClosureNeeded  " },
    { name: "Disk Color", value: "DiskColor " },
    { name: "Estmated Annual Volume", value: "EstmatedAnnualVolume  " },
    { name: "FDA CONDITIONS OF USE", value: "FdaCondition " },
    { name: "FDA FOOD CATEGORIES", value: "FdaFoodCategory " },
    { name: "Fitment Color", value: "FitmentColor " },
    { name: "FinishedPackageVacuum", value: "FinishedPackageVacuum " },
    //{ name: "", value: "FormCompletedDate  " },
    { name: "Per Cent of Headspace ( not in inches)", value: "HeadspacePercentage" },
    //{ name: "", value: "Id " },
    { name: "Initial Order Quantity", value: "InitialOrderQuantity  " },
    { name: "Alcohol Present", value: "IsAlcoholPresent" },
    { name: "IsBeverage", value: "IsBeverage" },
    { name: "Will the closure have printed graphics?", value: "Willhaveprintedgraphics" },
    { name: "Type:", value: "WillhaveprintedgraphicsType  " },
    { name: "Finished Pkg. Pressure", value: "FinishedPackagePressure" },
    { name: "Oil or Fat on Surface", value: "IsFatOnSurface  " },
    //{ name: "", value: "IsRegularatoryCompliance  " },
    { name: "Sodium Bisulfite (SO2) Present?", value: "IsSO2Present " },
    { name: "Vinegar Present", value: "IsVinegarPresent" },
    //{ name: "", value: "PackageProcessId" },
    //{ name: "", value: "PackageTryPackedId " },
    { name: "Packer Name", value: "PackerNameAddress  " },
    //{ name: "", value: "PhLevelId " },
    { name: "Product Fill Temperature", value: "ProductFillTemperature" },
    //{ name: "", value: "ProductName  " },
    { name: "ProductPhLevel", value: "ProductPhLevel  " },
    //{ name: "", value: "PSrNo " },
    //{ name: "", value: "RetailDisplayHumidityId" },
    //{ name: "", value: "RetailDisplayTemeratureId " },
    //{ name: "", value: "SalesRepName " },
    { name: "Shelf Life of Packed Product?", value: "ShelfLifePackedProduct" },
    { name: "Silgan COC#", value: "SilganCoc " },
    //{ name: "", value: "SubmitterContactNameTitle " },
    //{ name: "", value: "SubmitterDateInformation  " },
    //{ name: "", value: "SubmitterEmailAddress " },
    //{ name: "", value: "SubmitterMailingAddress" },
    //{ name: "", value: "SubmitterPhone  " },
    //{ name: "", value: "WarehouseHumidityId" },
    //{ name: "", value: "WarehouseShippingDisplayIssues  " },
    //{ name: "", value: "WarehouseTemeratureId " },
    { name: "Location", value: "Location  " },
    { name: "Product", value: "Product" },
    { name: "Special Issues", value: "SpecialIssues" },
    { name: "Status", value: "Status" },
    { name: "BeverageType", value: "BeverageType " },
    { name: "AmountofVinegarPresent", value: "AmountofVinegarPresent" },
    { name: "PPM if known", value: "PPM" },
    { name: "% if known", value: "AmntOfAlcoholPresent" },
    { name: "Does the product contain CBD or THC?", value: "ProductContainCBDorTHC" },
    { name: "Will sealed packages be shipped to countries outside the USA?", value: "ShippedOutsideUSA  " },
    { name: "Please list the countries where packages will be shipped ? ", value: "ListTheCountriesWhrPckgShipped  " },
    { name: "What country?", value: "WhtCountry" },
    //{ name: "", value: "PasswordHash " },
    //{ name: "", value: "PasswordSalt " },
    { name: "Customer", value: "CustomerComapnyName" },
    { name: "Mailing Address", value: "CustomerMailingAddress" },
    //{ name: "", value: "OrderTypeId  " },
    { name: "OrderReason", value: "OrderReasonFirstTimeId" },
    //{ name: "OrderReasonFirstTimeId", value: "OrderReasonReplacementId  " },
    { name: "Explain", value: "OrderReasonReplacementExplaination " },
    { name: "Product", value: "CustomerProduct " },
    //{ name: "", value: "CoatingRequired " },
    //{ name: "", value: "CasManagerName  " },

    { name: "Plastic Type", value: "PlasticType  " },
    // { name: "", value: "BeverageTypeOther  " },
    { name: "Experimental Type Name", value: "ExperimentalTypeName  " },
    { name: "Outsert", value: "Outsert" },
    { name: "Dietetic or Low Calorie?", value: "DieteticOrLowCalorie  " },
    { name: "Sealed with pressure", value: "SealedWithPressure " },
    { name: "Pressured by", value: "PressuredBy  " },
    { name: "Pressure Amount", value: "PressureAmount  " },
    { name: "How Applied?", value: "PressureApplied " },
    { name: "Type of Capper", value: "TypeOfCapper " },
    { name: "Method of Heating Closure, Explain", value: "HeatingMethod" },
    { name: "Capper Mfg.", value: "CapperManufacturer " },
    //{ name: "", value: "ArrangCapperToolWithField1" },
    //{ name: "", value: "ArrangCapperToolWithField2" },
    { name: "Enter name of capper manufacturer here:", value: "CapperManufacturerName" },
    { name: "PACKAGING PROCESS (Pick Only One From Drop-Down Box Below)", value: "PackageProcess  " },
    { name: "Process Pressure PSIG", value: "ProcessPressure " },
    { name: "Cooling PSIG", value: "CoolingPSIG  " },
    { name: "Overriding", value: "ProcessOverriding  " },
    { name: "Cooked By", value: "CookedBy  " },
    { name: "ProcessPressureType", value: "ProcessPressureType" },
    { name: "ClosureSterilization", value: "ClosureSterilization  " },
    { name: "ChemicalName", value: "ChemicalName " },
    { name: "DoNeckBandsCoverSidewall", value: "DoNeckBandsCoverSidewall  " },
    { name: "VolumesCO2", value: "VolumesCO2" },
    { name: "ChemicalConcentration", value: "ChemicalConcentration " },
    { name: "IsDudDetectionAvailable", value: "IsDudDetectionAvailable" },
    { name: "Package Sterilized via HPP?", value: "PackageSterilizedHPP  " },
    { name: "PackerNameAndAddress", value: "PackerNameAndAddress  " },
    { name: "Pallets High", value: "PalletsHigh  " },
    { name: "Packages Per Tray", value: "PackagesPerTray " },
    { name: "Trays Per Layer", value: "TraysPerLayer" },
    { name: "Layers Per Pallet", value: "LayersPerPallet " },
    { name: "Weight Per Pallet", value: "WeightPerPallet " },
    { name: "Warehouse Storage Temperature", value: "WarehouseStorageCondition " },
    { name: "ExternalCondensationWithHumidity", value: "ExternalCondensationWithHumidity" },
    { name: "Retail Display Temperature", value: "RetailDisplayConditions" },
    { name: "Humidity", value: "Humidity  " },
    { name: "Special warehouse, shipping or display issues", value: "ProductWeightPerPalletInLbs  " },
    { name: "Package Tray Packed", value: "PackageTrayPacked  " },
    { name: "SUBMITTER Customer Contact Name & Title", value: "CustomerContantNameTitle  " },
    { name: "Chuck Type", value: "ChuckType " },
    { name: "Out of Capper Vacuum (in-Hg)", value: "OutOfCapperVacuumInHg " },
    { name: "Water Temp:", value: "WaterTemp " },
    { name: "Pasteurization Time", value: "PasturizationTime  " },
    { name: "Product Temp:", value: "ProductTemp  " },
    //{ name: "", value: "DiskSummary  " },
    //{ name: "", value: "LinerSummary " },
    //{ name: "", value: "CompoundSummary " },
    //{ name: "", value: "FitmentResinSummary" },
    //{ name: "", value: "CapshellResinSummary  " },
    //{ name: "", value: "FitmentSummary  " },
    //{ name: "", value: "LubAddedLDRSummary " },
    //{ name: "", value: "ColorantSummary " },
    //{ name: "", value: "LDRSummary" },
    //{ name: "", value: "FaceSystemSummary  " },
    //{ name: "", value: "FaceCoatingsSummary" },
    //{ name: "", value: "ReverseSystemSummary  " },
    //{ name: "", value: "ReverseCoatingsSummary" },
    //{ name: "", value: "City  " },
    //{ name: "", value: "State " },
    //{ name: "", value: "ZipCode" },
    //{ name: "", value: "CustomerEXT  " },
    { name: "Customer Contact", value: "CustomerContact " }
    //{ name: "Email", value: "CurrentUserEmail" },
    //{ name: "Phone", value: "CurrentUserPhone" }

  ]

  onBacktoFormlistClick() {
    //var data = {
    //  smeTabIndex: this.smeTabForAdvanceSearch
    //};
    this.router.navigate(['/formsList']/*, { state: { data: data } }*/);
  }
}

interface Field {
  name: string;
  value: string;
}
interface SearchFieldValue {
  Field: string,
  Condition: string,
  Searchext: string
}

