import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { PackageSpecification } from '../models/packageSpecification';

@Injectable({
    providedIn: 'root'
})
export class PackageSpecificationService {
    myAppUrl: string;
    myApiUrl: string;
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json; charset=utf-8'
        })
    };
    constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
        this.myAppUrl = baseUrl;
        //this.myAppUrl = "http://silganclosures.csdevhub.com/";
        //this.myAppUrl = "https://localhost:44392/";

        this.myApiUrl = 'api/packagespecificationforms';
    }

    getPackageSpecifications(): Observable<PackageSpecification[]> {
        return this.http.get<PackageSpecification[]>(this.myAppUrl + this.myApiUrl)
            .pipe(
                retry(1),
                catchError(this.errorHandler)
            );
    }

    getPackageSpecification(Id: number): Observable<PackageSpecification> {
        return this.http.get<PackageSpecification>(this.myAppUrl + this.myApiUrl + Id)
            .pipe(
                retry(1),
                catchError(this.errorHandler)
            );
    }

    savePackageSpecification(PackageSpecification): Observable<PackageSpecification> {

        //
        ("URL to save : " + this.myAppUrl + this.myApiUrl);
        let data = { PackageSpecificationForm: PackageSpecification }
        return this.http.post<PackageSpecification>(this.myAppUrl + this.myApiUrl, JSON.stringify(PackageSpecification), this.httpOptions)
            .pipe(
                retry(1),
                catchError(this.errorHandler)
            );
    }

    updatePackageSpecification(Id: number, PackageSpecification): Observable<PackageSpecification> {
        return this.http.put<PackageSpecification>(this.myAppUrl + this.myApiUrl + Id, JSON.stringify(PackageSpecification), this.httpOptions)
            .pipe(
                retry(1),
                catchError(this.errorHandler)
            );
    }

    deletePackageSpecification(Id: number): Observable<PackageSpecification> {
        return this.http.delete<PackageSpecification>(this.myAppUrl + this.myApiUrl + Id)
            .pipe(
                retry(1),
                catchError(this.errorHandler)
            );
    }

    errorHandler(error) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
       // console.log(errorMessage);
        return throwError(errorMessage);
    }
}
