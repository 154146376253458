import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ClosureFormService } from 'src/app/services/closure-form.service';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ClosureForm } from 'src/app/models/closure-form';
import { AlertService } from 'src/app/alert/alert.service';
import { User } from 'src/app/models/user';
import { FileService } from '../../../app/services/file.service';
import * as fileSaver from "file-saver";
import { hasClassName } from '@ng-bootstrap/ng-bootstrap/util/util';
declare var $: any;

@Component({
  selector: 'app-package',
  templateUrl: './package.component.html',
  styleUrls: ['./package.component.css']
})
export class PackageComponent implements OnInit {
  loginUser: User;
  closureForm;
  loading: boolean = false;

  submitted = false;
  IsOutSert = true;
  @Input() formId: number;
  @Output() formCompleted = new EventEmitter();

  formData: ClosureForm;
  showSubmitButton = true;
  sizeData: Number;
  fileUpload: File = null;
  filename: string;
  formSearch: boolean = false;
  userId: number = 1;
  error: string;
  successMessage = "";
 
  uploadResponse = { fileStatus: '', message: '', filePath: '' };
  finishDrawingFileName: any = "";
  finishDrawingFormNo: any;
  get willhaveprintedgraphics() {
    return this.closureForm.get('willhaveprintedgraphics');
  }
  get customerClosureMaterialType() {
    return this.closureForm.get('customerClosureMaterialType');
  }
  get outsert() {
    return this.closureForm.get('outsert');
  }
  get containerType() {
    return this.closureForm.get('containerType');
  }
  get customerClosureMaterial() {
    return this.closureForm.get('customerClosureMaterial');
  }
  get containerMenufacturer() {
    return this.closureForm.get('containerMenufacturer');
  }
  get containerFinishNumber() {
    return this.closureForm.get('containerFinishNumber');
  }
  get sizeType() {
    return this.closureForm.get('sizeType');
  }
  get finishDrawing() {
    return this.closureForm.get('finishDrawing');
  }
  get drawingUpload() {
    return this.closureForm.get('drawingUpload');
  }
  // get fitmentColor() {
  //   return this.closureForm.get('fitmentColor');
  // }
   //get diskColor() {
   //  return this.closureForm.get('diskColor');
   //}
  // get capShellColor() {
  //   return this.closureForm.get('capShellColor');
  // }
  constructor(private formBuilder: FormBuilder, private formService: ClosureFormService, private fileService: FileService   ,private router: Router, private authService: AuthenticationService, private alert: AlertService) {
    this.closureForm = this.formBuilder.group({
      psrNo: '',
      customerProjectName: '',
      sizeType: [''],
      fitmentColor: '',
      diskColor: '',
      capShellColor: '',
      willhaveprintedgraphics: [''],
      willPrintDesignUTC: '',
      willhaveprintedgraphicsType: '',
      specialInstructions: '',
      containerType: ['Glass',  ],
      containerFinishNumber: ['', Validators.required],
      finishDrawing: '',
      drawingUpload:'',
      containerMenufacturer: ['', Validators.required],
      containerClosureSize: '',
      containerComments: '',
      plasticType: '',
      outsert: [''],
      customerClosureMaterial: '',
      customerClosureMaterialType:''
    });
  }
  ngOnInit() {
    this.loginUser = this.authService.currentUserValue;
    let currentURL = this.router.url;

    if (currentURL.includes("formsearch")) {
      this.formSearch = true;
    }

    if (this.formId) {
      if (currentURL.includes("formhistorystepper")) {
        this.formService.getFormHistoryById(this.formId).subscribe(this.onSubscribeSuccess);
        this.closureForm.disable();
        this.showSubmitButton = false;
      }
      else {
        this.formService.getById(this.formId).subscribe(this.onSubscribeSuccess);
       
      }
    }
  }
  onlyNumberValid(evt: any) {
    
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode != 45 && charCode > 31
      && (charCode < 48 || charCode > 57))
      return false;

    return true;
  }
  uploadFile(files) {
    
    ////file[0].name
    // files[0].name.replace("#","File");


    //let Hashvalue: boolean = false;
    //Hashvalue = files[0].name.includes("#");
    //console.log("HashValue", Hashvalue);
    //if (Hashvalue == true) {            
    //  return false;
    //}


    this.fileUpload = files.item(0);
    this.filename = files[0].name.replace(/[&\/\\#,+()$~%!„'":*‚^_¤?<>|@ª{«»§}©®™]/g, '');      
      this.closureForm.patchValue({
        finishDrawing: this.filename
      });
    

    //if (event.target.file.length > 0) {
    //  const file = event.target.files.item(0);
    //  this.fileUpload = file;

      //this.fileService.upload(file, this.userId).subscribe(
      //  (res) => this.uploadResponse = res,
      //  (err) => this.error = err
      //);
    //}
  }
  onSubmit()
  {
   
    this.submitted = true;
    if (this.closureForm.value.customerClosureMaterial.value == 'ROPP (Alum)' && this.closureForm.value.IsOutSert == "") {
      this.IsOutSert = false;
      return;
    } else {
      this.IsOutSert = true;
    }
    // stop here if form is invalid
    if (this.closureForm.invalid) {
      $("#Packageformsubmit-modal").modal({      
        backdrop: 'static',
        keyboard: false,        
      });     
      return;
      
    }
    this.closureForm.disable();
    this.loading = true;

    //if (this.filename == this.closureForm.value.finishDrawing) {

    //} else {
    //this.closureForm.patchValue({
    //  finishDrawing: this.filename
    //});
    if (this.fileUpload != null) {
                  
      this.finishDrawingFileName = this.filename;
      this.fileService.uploadPkg(this.fileUpload, this.closureForm.value.psrNo, this.filename).subscribe(
      (res) => this.uploadResponse = res,
      (err) => this.error = err
      );
    }
    this.formData.packageCompleted = true;
    this.formService.SavePackageFormData(this.closureForm.value)
      .subscribe(
        data => {
          this.loading = false;
          this.closureForm.enable();
          //this.alert.success("Data is saved ");
          localStorage.setItem("dataSource", JSON.stringify(this.formData));

          this.formCompleted.emit(true);
          //this.router.navigate(['/formsList']);
        },
        error => {
          this.closureForm.enable();
          this.loading = false;
      });
    
  }

 
  onSearchForCurrent() {

  }

  onSearchForAllFormFields() {

  }

  onSearchCancel() {

  }
  onSubscribeSuccess = data => {

    this.formData = data;
   
    
    this.formService.getSizeData(+data.customerClosureMaterialSize).subscribe(
      p => {
        this.sizeData = p.size;
       
        this.closureForm.setValue({
          
          //customerEmail: this.formData.customerEmail,
          psrNo: this.formId,
          willhaveprintedgraphics: this.formData.willhaveprintedgraphics,
          willPrintDesignUTC: this.formData.willPrintDesignUTC,
          sizeType: this.sizeData + '' + data.customerClosureMaterialType,
          fitmentColor: data.fitmentColor,
          diskColor: data.diskColor,
          capShellColor: data.capShellColor,
          customerProjectName: data.customerProjectName,
          customerClosureMaterial: data.customerClosureMaterial,
          //initiatedDate:this.formData.initiatedDate,
          //CreatedById: this.authService.currentUserValue.userId,
          willhaveprintedgraphicsType: this.formData.willhaveprintedgraphicsType,
          specialInstructions: this.formData.specialInstructions,
          containerType: this.formData.containerType,
          containerFinishNumber: this.formData.containerFinishNumber,
          finishDrawing: this.formData.finishDrawing,
          drawingUpload: '',
          containerMenufacturer: this.formData.containerMenufacturer,
          containerClosureSize: this.formData.containerClosureSize,
          containerComments: this.formData.containerComments,
          plasticType: this.formData.plasticType,
          outsert: this.formData.outsert,
          customerClosureMaterialType: data.customerClosureMaterialType
        });
        this.finishDrawingFileName = this.formData.finishDrawing;
        this.finishDrawingFormNo = this.formId;
        //let currentURL = this.router.url;
        //if (!currentURL.includes("formhistorystepper")) {

        //  if (this.loginUser != null && this.loginUser != undefined) {
        //    if (this.loginUser.role) {
        //      this.showSubmitButton = true;
        //      this.closureForm.enable();
        //    }
        //  }
        //  else {
        //    if (this.formData.status == 2) {

        //    } else {
        //      this.showSubmitButton = false; /// 11.46    cs  123password
        //      this.closureForm.disable();
        //    }
        //  }
        //}

        let currentURL = this.router.url;
        if (!currentURL.includes("formhistorystepper")) {

          if (this.loginUser != null && this.loginUser != undefined) {
            if (this.loginUser.role == 'Admin') {
              
              this.showSubmitButton = true;
              this.closureForm.enable();
            }
            else if (this.loginUser.role == 'Viewer') {

              this.showSubmitButton = false;
              this.closureForm.disable();
            }
            else if (this.loginUser.role == 'CAS') {
              
              if (this.formData.status == 3 || this.formData.status == 1) {
                this.showSubmitButton = true;
                this.closureForm.enable();
              }
              else {
                this.showSubmitButton = false;
                this.closureForm.disable();
              }
            } else if (this.loginUser.role == 'SME') {     
              //if (this.formData.status == 4 && this.formData.customerClosureMaterial == 'Composite' && this.loginUser.teamId == '2') {
              //  this.showSubmitButton = true;
              //  this.closureForm.enable();
              //}
              //else if (this.formData.status == 4 && this.formData.customerClosureMaterial == 'Plastic' && this.loginUser.teamId == '4') {
              //  this.showSubmitButton = true;
              //  this.closureForm.enable();
              //}
              //else if (this.formData.status == 4 && this.formData.customerClosureMaterial == 'Metal (Steel)' && this.loginUser.teamId == '1') {
              //  this.showSubmitButton = true;
              //  this.closureForm.enable();
              //}
              //else if (this.formData.status == 4 && this.formData.customerClosureMaterial == 'ROPP (Alum)' && this.loginUser.teamId == '3') {
              //  this.showSubmitButton = true;
              //  this.closureForm.enable();
              //}

              if (this.formData.status == 4 && this.formData.customerClosureMaterial == 'Composite') {
                var output = this.loginUser.teamId.split('');
                for (let i = 0; i < output.length; i++) {
                  let id = output[i];
                  if (id == "2") {
                    this.showSubmitButton = true;
                    this.closureForm.enable();
                  }
                }
              }
              else if (this.formData.status == 4 && this.formData.customerClosureMaterial == 'Plastic') {
                var output = this.loginUser.teamId.split('');
                for (let i = 0; i < output.length; i++) {
                  let id = output[i];
                  if (id == "4") {
                    this.showSubmitButton = true;
                    this.closureForm.enable();
                  }
                }
              }
              else if (this.formData.status == 4 && this.formData.customerClosureMaterial == 'Metal (Steel)') {
                var output = this.loginUser.teamId.split('');
                for (let i = 0; i < output.length; i++) {
                  let id = output[i];
                  if (id == "1") {
                    this.showSubmitButton = true;
                    this.closureForm.enable();
                  }
                }
              }
              else if (this.formData.status == 4 && this.formData.customerClosureMaterial == 'ROPP (Alum)') {
                var output = this.loginUser.teamId.split('');
                for (let i = 0; i < output.length; i++) {
                  let id = output[i];
                  if (id == "3") {
                    this.showSubmitButton = true;
                    this.closureForm.enable();
                  }
                }
              }

              else {               
                this.showSubmitButton = false;
                this.closureForm.disable();
              }
            }
          }
          else {
            if (this.formData.status == 2) {
            }
            else {
              this.showSubmitButton = false;
              this.closureForm.disable();
            }
          }
        }

        if (this.formData.isFormLock == 1) {
          if (this.loginUser != null && this.loginUser != undefined) {
            if (this.loginUser.role == "Admin") {
              this.showSubmitButton = false;
              this.closureForm.disable();
            } else if (this.loginUser.role == "Viewer") {
              this.showSubmitButton = false;
              this.closureForm.disable();
            } else if (this.loginUser.role == "CAS") {
              if (this.formData.status == 3 || this.formData.status == 1) {
                this.showSubmitButton = false;
                this.closureForm.disable();
              } else {
                this.showSubmitButton = false;
                this.closureForm.disable();
              }
            } else if (this.loginUser.role == "SME") {
              if (
                this.formData.status == 4 &&
                this.formData.customerClosureMaterial == "Composite"
              ) {
                var output = this.loginUser.teamId.split("");
                for (let i = 0; i < output.length; i++) {
                  let id = output[i];
                  if (id == "2") {
                    this.showSubmitButton = false;
                    this.closureForm.disable();
                  }
                }
              } else if (
                this.formData.status == 4 &&
                this.formData.customerClosureMaterial == "Plastic"
              ) {
                var output = this.loginUser.teamId.split("");
                for (let i = 0; i < output.length; i++) {
                  let id = output[i];
                  if (id == "4") {
                    this.showSubmitButton = false;
                    this.closureForm.disable();
                  }
                }
              } else if (
                this.formData.status == 4 &&
                this.formData.customerClosureMaterial == "Metal (Steel)"
              ) {
                var output = this.loginUser.teamId.split("");
                for (let i = 0; i < output.length; i++) {
                  let id = output[i];
                  if (id == "1") {
                    this.showSubmitButton = false;
                    this.closureForm.disable();
                  }
                }
              } else if (
                this.formData.status == 4 &&
                this.formData.customerClosureMaterial == "ROPP (Alum)"
              ) {
                var output = this.loginUser.teamId.split("");
                for (let i = 0; i < output.length; i++) {
                  let id = output[i];
                  if (id == "3") {
                    this.showSubmitButton = false;
                    this.closureForm.disable();
                  }
                }
              } else {
                this.showSubmitButton = false;
                this.closureForm.disable();
              }
            }
          } else {
            if (this.formData.status == 2) {
              this.showSubmitButton = false;
              this.closureForm.disable();
            } else {
              this.showSubmitButton = false;
              this.closureForm.disable();
            }
          }
        }

      });



  }

  downloadDrawingPdf(finishDrawingFileName, finishDrawingFormNo) {
    
    this.fileService.downloadFinishDrawingFile(finishDrawingFormNo, finishDrawingFileName).subscribe((response) => {
     
      let blob: any = new Blob([response.blob()], {
        type: "text/pdf; charset=utf-8",
      });
      const url = window.URL.createObjectURL(blob);
      // window.open(url);
      // window.location.href = response.url;
      fileSaver.saveAs(blob, finishDrawingFileName);
    }),
      (error) => console.log("Error downloading the file"),
      () => console.log("File downloaded successfully");
    //setTimeout(() => {
    //  this.loadAllForms();
    //}, 11000);
  }


  onpacakageOk() {
    
    $("#Packageformsubmit-modal").modal("hide");
    this.router.navigate(["/formstepper/" + this.closureForm.get("pSRNo").value]);
  }
}







