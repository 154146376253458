import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { User } from '../models/user';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent implements OnInit {

  isExpanded = false;
  currentUser: User;
  get isAdmin() {
    return this.currentUser && this.currentUser.role === "Admin";
  }
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) {
    
  }
  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe(x => {
        // 

        (x);
      this.currentUser = x;
    });
  }
  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }
  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }
}
