import { AbstractControl, ValidationErrors } from '@angular/forms';

export class productShelfLifeValidators { 
    static shelfLifeValidator(control: AbstractControl) : ValidationErrors | null {
        if (control.value  > 24)
            return { shelfLifeValidator: true };

        return null;
    }
}
