import { Routes, RouterModule } from "@angular/router"
import { HomeComponent } from "./home/home.component";
import { CounterComponent } from "./counter/counter.component";
import { FetchDataComponent } from "./fetch-data/fetch-data.component";
import { LoginComponent } from "./login/login.component";
import { RegisterComponent } from "./register/register.component";
import { AuthGuard } from "./guards/auth.guard";
import { EditUserComponent } from "./edit-user/edit-user.component";
import { SpecificationReviewAddEdit } from "./specification-review-add-edit/specification-review-add-edit.component";
import { FormListComponent } from "./form-list/form-list.component";
import { UserProfileComponent } from "./user-profile/user-profile.component";
import { ChangePasswordComponent } from "./change-password/change-password.component";
import { ProfileComponent } from "./form-step/profile/profile.component";
import { FormListSaveDraftComponent } from "./form-list-save-draft/form-list-save-draft.component";
import { forgotPasswordComponent } from "./users/forgot-password/forgot-password.component";

import { ResetPasswordComponent } from "./users/reset-password/reset-password.component";


import { PackageComponent } from './form-step/package/package.component';
import { ProductComponent } from './form-step/product/product.component';
import { PalletizationComponent } from './form-step/palletization/palletization.component';
import { ProcessComponent } from './form-step/process/process.component';
import { CustomerLoginComponent } from './customer-login/customer-login.component';
import { CustomStepperComponent } from "./form-step/custom-stepper/custom-stepper.component";
import { FormStepperComponent } from "./form-step/form-stepper/form-stepper.component";
import { FormSummaryComponent } from './form-step/form-summary/form-summary.component';
import { CustomerAuthGuard } from "./guards/customer-auth.guard";
import { AdvanceSearchComponent } from "./advance-search/advance-search.component";
import { FormHistoryComponent } from "./form-history/form-history.component";
import { CustomerHomeComponent } from "./customer-Home/customer-Home.component";
import { FormSearchComponent } from "./formsearch/formsearch.component";

const routes: Routes = [

  //  { path: '', component: FormListComponent, canActivate: [AuthGuard] },
  { path: '', component: CustomerHomeComponent },
  { path: 'users-list', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent, canActivate: [AuthGuard] },
  { path: 'edituser/:id', component: EditUserComponent, canActivate: [AuthGuard] },
  { path: 'formsList', component: FormListComponent, canActivate: [AuthGuard] },
  { path: 'formsListSaveDraft', component: FormListSaveDraftComponent, canActivate: [AuthGuard] },
  { path: 'newclosureform', component: FormStepperComponent, canActivate: [AuthGuard] },
  { path: 'formsearch', component: FormSearchComponent, canActivate: [AuthGuard] },
  //{ path: 'formsearch/:id', component: FormSearchComponent, canActivate: [AuthGuard] },
  { path: 'editform/:id', component: SpecificationReviewAddEdit, canActivate: [AuthGuard] },
  { path: 'profile', component: UserProfileComponent, canActivate: [AuthGuard] },
  { path: 'changepassword/:id', component: ChangePasswordComponent, canActivate: [AuthGuard] },
  { path: 'editform/:id', component: SpecificationReviewAddEdit, canActivate: [AuthGuard] },
  { path: 'formsummary/:id', component: FormSummaryComponent, canActivate: [AuthGuard] },
  { path: 'formhistory/:id', component: FormHistoryComponent, canActivate: [AuthGuard] },
  { path: 'forgotpassword', component: forgotPasswordComponent },
  { path: 'advancesearchform', component: AdvanceSearchComponent, canActivate: [AuthGuard] },

  { path: 'Account/ResetPassword', component: ResetPasswordComponent },
  { path: 'Account/CustomerLogin', component: CustomerLoginComponent },
  { path: 'Account/CustomerHome', component: CustomerHomeComponent },
  { path: 'PackageComponent', component: PackageComponent },
  { path: 'ProductComponent', component: ProductComponent },
  { path: 'PalletizationComponent', component: PalletizationComponent },
  { path: 'formstepper/:id', component: FormStepperComponent, canActivate: [AuthGuard] },
  { path: 'formcustomerstepper/:id', component: FormStepperComponent, canActivate: [CustomerAuthGuard] },
  { path: 'formhistorystepper/:id', component: FormStepperComponent },
  { path: 'ProcessComponent', component: ProcessComponent },
  { path: 'FormSummaryComponent', component: FormSummaryComponent }


];
export const appRoutingModule = RouterModule.forRoot(routes);
// RouterModule.forRoot([
//     { path: '', component: HomeComponent, pathMatch: 'full' },
//     { path: 'counter', component: CounterComponent },
//     { path: 'fetch-data', component: FetchDataComponent },
//   ])
