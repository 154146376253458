import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { FormBuilder, Validators, FormControl } from "@angular/forms";
import { ClosureFormService } from "src/app/services/closure-form.service";
import { Router } from "@angular/router";
import { AuthenticationService } from "src/app/services/authentication.service";
import { ClosureForm } from "src/app/models/closure-form";
import { formatDate, CurrencyPipe } from "@angular/common";
import { User } from "src/app/models/user";
import { phoneNumberValidator } from "src/app/validators/phoneNumber-validator";
import { UserService } from "../../services/user.service";
import { ToastrService } from "ngx-toastr";

declare var $: any;

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"],
})
export class ProfileComponent implements OnInit {

  currentUser: string;
  UserEmail: string;
  UserPhone: string;

  loginUser: User;
  @Input() formId: number;
  @Output() formCompleted = new EventEmitter();
  formData: ClosureForm;
  showEditButtons = false;

  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$";
  closureForm;
  loading: boolean = false;
  formSearch: boolean = false;
  BusinessOrderTypeId = 1;
  submitted = false;
  formStatus: number;
  successMessage = "";
  todaydate: Date = new Date();
  clicked = false;
  //currentUser1: User;

  CCMaterial: string;
  CCMaterialSize: number;
  CCMaterialType: string;
  get mobile() {
    return this.closureForm.get("CustomerContactPhoneNumber");
  }
  get email() {
    return this.closureForm.get("CustomerEmail");
  }
  get customerName() {
    return this.closureForm.get("customerName");
  }
  get customerComapnyName() {
    return this.closureForm.get("customerComapnyName");
  }
  get city() {
    return this.closureForm.get("city");
  }
  get state() {
    return this.closureForm.get("state");
  }
  get OrderTypeId() {
    return this.closureForm.get("orderTypeId");
  }
  get CustomerClosureMaterial() {
    return this.closureForm.get("CustomerClosureMaterial");
  }
  get CustomerClosureMaterialType() {
    return this.closureForm.get("CustomerClosureMaterialType");
  }
  get location() {
    return this.closureForm.get("location");
  }
  get CustomerClosureMaterialSize() {
    return this.closureForm.get("CustomerClosureMaterialSize");
  }
  get customerProduct() {
    return this.closureForm.get("customerProduct");
  }
  get SilganCoc() {
    return this.closureForm.get("SilganCoc");
  }
  get InitialOrderQuantity() {
    return this.closureForm.get("InitialOrderQuantity");
  }
  get DateClosureNeeded() {
    return this.closureForm.get("DateClosureNeeded");
  }

  get estmatedAnnualVolume() {
    return this.closureForm.get("estmatedAnnualVolume");
  }

  get SpecialIssues() {
    return this.closureForm.get("SpecialIssues");
  }

  get notes() {
    return this.closureForm.get("notes");
  }

  constructor(
    private formBuilder: FormBuilder,
    private formService: ClosureFormService,
    private router: Router,
    private authService: AuthenticationService,
    private toastr: ToastrService,
    private userService: UserService,
    //private fileService:FileService,
    private currencyPipe: CurrencyPipe
  ) {
    
    let values = JSON.parse(localStorage.getItem("currentUser"));
    this.currentUser = values ? values.firstName + " " + values.lastName : "";
    this.currentUser = values ? values.firstName + " " + values.lastName : "";
    this.UserEmail = values ? values.email : "";
    this.UserPhone = values ? values.phone : "";
    // this.initiatedDate = new Date().toString();
    this.closureForm = this.formBuilder.group({
      pSRNo: "",
      CustomerEmail: ["", [Validators.required, Validators.email]],
      casManagerName: this.currentUser == "" ? "" : this.currentUser, //this.authService.currentUserValue.username,
      initiatedDate: new Date().toString(),
      //CreatedById: this.authService.currentUserValue.userId,
      customerComapnyName: ["", Validators.required],
      customerName: ["", Validators.required],
      CustomerContactPhoneNumber: ["", [Validators.required]],
      currentUserEmail: this.UserEmail == "" ? "" : this.UserEmail,
      currentUserPhone: this.UserPhone == "" ? "" : this.UserPhone,    
      customerEXT: [""],
      customerContact: [""],
      location: ["", Validators.required],
      CustomerClosureMaterialType: ["BDP", Validators.required],
      CustomerClosureMaterialSize: [1, Validators.required],
      CustomerClosureMaterial: ["Composite", Validators.required],
      orderTypeId: 1,
      orderReasonFirstTimeId: 1,
      orderReasonReplacementId: 1,
      orderReasonReplacementExplaination: [""],
      customerProduct: ["", Validators.required],
      InitialOrderQuantity: ["", Validators.required],
      DateClosureNeeded: ["", Validators.required],
      SilganCoc: [""],
      estmatedAnnualVolume: ["", Validators.required],
      coatingRequired: ["1", Validators.required],
      SpecialIssues: ["", Validators.required],
      notes: "",
      experimentalTypeName: "",
    });
  }

  ngOnInit() {

    $("#edit-modal").on("hidden.bs.modal", this.onSuccessOk);
    this.loginUser = this.authService.currentUserValue;
    let currentURL = this.router.url;
    if (currentURL.includes("formsearch")) {
      this.formSearch = true;
      this.todaydate.setFullYear(2000);
    }

    if (this.formId) {
      
      //this.formSearch = false;
      if (currentURL.includes("formhistorystepper")) {
        this.formService
          .getFormHistoryById(this.formId)
          .subscribe(this.onSubscribeSuccess);
        this.closureForm.disable();
        this.showEditButtons = false;
      } else {
        this.formService
          .getById(this.formId)
          .subscribe(this.onSubscribeSuccess);
      }
    }



    // this.onChanges();
  }

  onChangesCMType() {      
    let CCMTValue = this.closureForm.get("CustomerClosureMaterialType").value;
    if (CCMTValue == "BDP") {
      this.closureForm.patchValue({
        CustomerClosureMaterial: "Plastic",
        CustomerClosureMaterialSize: "2",
        CustomerClosureMaterialType: "EEJ",
      });
    }
  }

  onChanges() {
  
    // open pop up only when useredit the form not time of initiated
    //check
    if (this.closureForm.get("pSRNo").value != "" && this.closureForm.get("pSRNo").value != undefined )
    $("#form-closure-meterial").modal("show");

    let CCMValue = this.closureForm.get("CustomerClosureMaterial").value;
   
    let CCMSize = this.closureForm.get("CustomerClosureMaterialSize").value;
    
    if (CCMValue == "Composite") {
      this.closureForm.patchValue({
        CustomerClosureMaterialSize: "1",
        CustomerClosureMaterialType: "BTF",
      });

    }
    //else if (CCMSize) { }
    //  if(CCMValue == "Composite") {
    //  this.closureForm.patchValue({
    //    CustomerClosureMaterialSize: "1",
    //    CustomerClosureMaterialType: "BTF",
    //  });
    //}
    else if (CCMValue == "Plastic") {
      this.closureForm.patchValue({
        CustomerClosureMaterialSize: "35",
        CustomerClosureMaterialType: "F48/F49",
      });
    }
    //else if (CCMValue == "Plastic") {
    //  this.closureForm.patchValue({
    //    CustomerClosureMaterialSize: "31",
    //    CustomerClosureMaterialType: "F32",
    //  });
    //}    
    else if (CCMSize == "10") {
      if (CCMValue == "Metal (Steel)") {
      this.closureForm.patchValue({
        CustomerClosureMaterialSize: "10",
        CustomerClosureMaterialType: "MTP",
      });
      }
    }

    else if (CCMValue == "Metal (Steel)") {      
      this.closureForm.patchValue({
        CustomerClosureMaterialSize: "9",
        CustomerClosureMaterialType: "MTP",
      });
      }
     

    else if (CCMValue == "ROPP (Alum)") {
      this.closureForm.patchValue({
        CustomerClosureMaterialSize: "25",
        CustomerClosureMaterialType: "PPB",
      });
    }
  }
  onchangePlasticCapsiz() {
    
    let CCMValues = this.closureForm.get("CustomerClosureMaterial").value;
    let CCMSizes = this.closureForm.get("CustomerClosureMaterialSize").value;
    if (CCMSizes == '31') {
      if (CCMValues == "Plastic") {
        this.closureForm.patchValue({
          CustomerClosureMaterialSize: "31",
          CustomerClosureMaterialType: "F32",
        });
      }
    }
    if (CCMSizes == '35') {
      if (CCMValues == "Plastic") {
        this.closureForm.patchValue({
          CustomerClosureMaterialSize: "35",
          CustomerClosureMaterialType: "F48/F49",
        });
      }
    }
    else if (CCMSizes == '2') {
      if (CCMValues == "Plastic") {
        this.closureForm.patchValue({
          CustomerClosureMaterialSize: "2",
          CustomerClosureMaterialType: "ACL",
        });
      }
    }
    else if (CCMSizes == '3') {
      if (CCMValues == "Plastic") {
        this.closureForm.patchValue({
          CustomerClosureMaterialSize: "3",
          CustomerClosureMaterialType: "VLP",
        });
      }
    }
    else if (CCMSizes == '4') {
      if (CCMValues == "Plastic") {
        this.closureForm.patchValue({
          CustomerClosureMaterialSize: "4",
          CustomerClosureMaterialType: "CC2",
        });
      }
    }
    else if (CCMSizes == '5') {
      if (CCMValues == "Plastic") {
        this.closureForm.patchValue({
          CustomerClosureMaterialSize: "5",
          CustomerClosureMaterialType: "VMD",
        });
      }
    }
    else if (CCMSizes == '6') {
      if (CCMValues == "Plastic") {
        this.closureForm.patchValue({
          CustomerClosureMaterialSize: "6",
          CustomerClosureMaterialType: "VMJ",
        });
      }
    }
    else if (CCMSizes == '32') {
      if (CCMValues == "Plastic") {
        this.closureForm.patchValue({
          CustomerClosureMaterialSize: "32",
          CustomerClosureMaterialType: "EPP",
        });
      }
    }
    else if (CCMSizes == '33') {
      if (CCMValues == "Plastic") {
        this.closureForm.patchValue({
          CustomerClosureMaterialSize: "33",
          CustomerClosureMaterialType: "62M",
        });
      }
    }
    else if (CCMSizes == '7') {
      if (CCMValues == "Plastic") {
        this.closureForm.patchValue({
          CustomerClosureMaterialSize: "7",
          CustomerClosureMaterialType: "VHJ",
        });
      }
    }
    else if (CCMSizes == '34') {
      if (CCMValues == "Plastic") {
        this.closureForm.patchValue({
          CustomerClosureMaterialSize: "34",
          CustomerClosureMaterialType: "XLM",
        });
      }
    }
  }

  onchangeMetalCapsiz() {
    let CCMValues = this.closureForm.get("CustomerClosureMaterial").value;
    let CCMSizes = this.closureForm.get("CustomerClosureMaterialSize").value;
    if (CCMSizes == '9') {
      if (CCMValues == "Metal (Steel)") {
        this.closureForm.patchValue({
          CustomerClosureMaterialSize: "9",
          CustomerClosureMaterialType: "MTP",
        });
      }
    }
    else if (CCMSizes == '10') {
      if (CCMValues == "Metal (Steel)") {
        this.closureForm.patchValue({
          CustomerClosureMaterialSize: "10",
          CustomerClosureMaterialType: "MTP",
        });
      }
    }
    if (CCMSizes == '11') {
      if (CCMValues == "Metal (Steel)") {
        this.closureForm.patchValue({
          CustomerClosureMaterialSize: "11",
          CustomerClosureMaterialType: "RUB",
        });
      }
    }
    if (CCMSizes == '12') {
      if (CCMValues == "Metal (Steel)") {
        this.closureForm.patchValue({
          CustomerClosureMaterialSize: "12",
          CustomerClosureMaterialType: "DEJ",
        });
      }
    }
    if (CCMSizes == '13') {
      if (CCMValues == "Metal (Steel)") {
        this.closureForm.patchValue({
          CustomerClosureMaterialSize: "13",
          CustomerClosureMaterialType: "RTP",
        });
      }
    }
    if (CCMSizes == '14') {
      if (CCMValues == "Metal (Steel)") {
        this.closureForm.patchValue({
          CustomerClosureMaterialSize: "14",
          CustomerClosureMaterialType: "FXB",
        });
      }
    }
    if (CCMSizes == '15') {
      if (CCMValues == "Metal (Steel)") {
        this.closureForm.patchValue({
          CustomerClosureMaterialSize: "15",
          CustomerClosureMaterialType: "PHA",
        });
      }
    }
    if (CCMSizes == '16') {
      if (CCMValues == "Metal (Steel)") {
        this.closureForm.patchValue({
          CustomerClosureMaterialSize: "16",
          CustomerClosureMaterialType: "RTB",
        });
      }
    }
    if (CCMSizes == '17') {
      if (CCMValues == "Metal (Steel)") {
        this.closureForm.patchValue({
          CustomerClosureMaterialSize: "17",
          CustomerClosureMaterialType: "FAB",
        });
      }
    }
    if (CCMSizes == '18') {
      if (CCMValues == "Metal (Steel)") {
        this.closureForm.patchValue({
          CustomerClosureMaterialSize: "18",
          CustomerClosureMaterialType: "RVB",
        });
      }
    }
    if (CCMSizes == '19') {
      if (CCMValues == "Metal (Steel)") {
        this.closureForm.patchValue({
          CustomerClosureMaterialSize: "19",
          CustomerClosureMaterialType: "DPB",
        });
      }
    }
    if (CCMSizes == '21') {
      if (CCMValues == "Metal (Steel)") {
        this.closureForm.patchValue({
          CustomerClosureMaterialSize: "21",
          CustomerClosureMaterialType: "DCB",
        });
      }
    }
    if (CCMSizes == '22') {
      if (CCMValues == "Metal (Steel)") {
        this.closureForm.patchValue({
          CustomerClosureMaterialSize: "22",
          CustomerClosureMaterialType: "FAP",
        });
      }
    }
    if (CCMSizes == '20') {
      if (CCMValues == "Metal (Steel)") {
        this.closureForm.patchValue({
          CustomerClosureMaterialSize: "20",
          CustomerClosureMaterialType: "RPB",
        });
      }
    }
    if (CCMSizes == '23') {
      if (CCMValues == "Metal (Steel)") {
        this.closureForm.patchValue({
          CustomerClosureMaterialSize: "23",
          CustomerClosureMaterialType: "RTS",
        });
      }
    }
    if (CCMSizes == '24') {
      if (CCMValues == "Metal (Steel)") {
        this.closureForm.patchValue({
          CustomerClosureMaterialSize: "24",
          CustomerClosureMaterialType: "RSB",
        });
      }
    }
  }

  onchangeRoppCapsiz() {
    let CCMValues = this.closureForm.get("CustomerClosureMaterial").value;
    let CCMSizes = this.closureForm.get("CustomerClosureMaterialSize").value;
    if (CCMSizes == '25') {
      if (CCMValues == "ROPP (Alum)") {
        this.closureForm.patchValue({
          CustomerClosureMaterialSize: "25",
          CustomerClosureMaterialType: "PPB",
        });
      }
    }
     if (CCMSizes == '26') {
       if (CCMValues == "ROPP (Alum)") {
        this.closureForm.patchValue({
          CustomerClosureMaterialSize: "26",
          CustomerClosureMaterialType: "EXP",
        });
      }
    }
     if (CCMSizes == '27') {
       if (CCMValues == "ROPP (Alum)") {
        this.closureForm.patchValue({
          CustomerClosureMaterialSize: "27",
          CustomerClosureMaterialType: "TNR",
        });
      }
    }
     if (CCMSizes == '28') {
       if (CCMValues == "ROPP (Alum)") {
        this.closureForm.patchValue({
          CustomerClosureMaterialSize: "28",
          CustomerClosureMaterialType: "SPB",
        });
      }
    }
     if (CCMSizes == '29') {
       if (CCMValues == "ROPP (Alum)") {
        this.closureForm.patchValue({
          CustomerClosureMaterialSize: "29",
          CustomerClosureMaterialType: "PPR",
        });
      }
    }
     if (CCMSizes == '30') {
       if (CCMValues == "ROPP (Alum)") {
        this.closureForm.patchValue({
          CustomerClosureMaterialSize: "30",
          CustomerClosureMaterialType: "PPA",
        });
      }
    }
  }




  confirmedYes() {
    this.updateAndClearAllFormData();
  }

  confirmedNo() {
    this.closureForm.patchValue({
      CustomerClosureMaterial: this.CCMaterial,
      CustomerClosureMaterialSize: this.CCMaterialSize.toString(),
      CustomerClosureMaterialType: this.CCMaterialType,
    });
    $("#form-closure-meterial").modal("hide");
  }

  onlyNumberValid(event: any) {
   
    var charCode = event.which ? event.which : event.keyCode;
    if (charCode != 45 && charCode > 31 && (charCode < 48 || charCode > 57))
      return false;

    return true;
  }

  //keyPress(event: any) {

    
  //  let inputChar = String.fromCharCode(event.charCode);
  //  if (event.keyCode != 8 && !regex.test(inputChar)) {
  //    event.preventDefault();
      
  //  }
  //}
  onChangeCustComapnyName() {
    this.closureForm.patchValue({
      customerName: this.closureForm.get("customerComapnyName").value,
    });
  }

  onSubscribeSuccess = (data) => {
  
    this.formData = data;
    this.closureForm.setValue({
      pSRNo: this.formId,
      CustomerEmail: this.formData.customerEmail,
      casManagerName: this.formData.casManagerName,

      currentUserEmail: this.formData.currentUserEmail,
      currentUserPhone: this.formData.currentUserPhone,
      initiatedDate: formatDate(
        this.formData.initiatedDate,
        "MM-dd-yyyy",
        "en"
      ),
      //CreatedById: this.authService.currentUserValue.userId,
      customerComapnyName: this.formData.customerComapnyName,
      customerName: this.formData.customerName,
      CustomerContactPhoneNumber: this.formData.customerContactPhoneNumber,      
      customerEXT: this.formData.customerEXT,
      customerContact: this.formData.customerContact,
      location: this.formData.location,
      CustomerClosureMaterial: this.formData.customerClosureMaterial,
      CustomerClosureMaterialType: this.formData.customerClosureMaterialType,
      CustomerClosureMaterialSize: this.formData.customerClosureMaterialSize,
      orderTypeId: this.formData.orderTypeId,
      orderReasonFirstTimeId: this.formData.orderReasonFirstTimeId,
      orderReasonReplacementId: this.formData.orderReasonReplacementId,
      orderReasonReplacementExplaination: this.formData
        .orderReasonReplacementExplaination,
      customerProduct: this.formData.customerProduct,
      InitialOrderQuantity: this.formData.initialOrderQuantity,
      DateClosureNeeded: formatDate(
        this.formData.dateClosureNeeded,
        "yyyy-MM-dd",
        "en"
      ),
      SilganCoc: this.formData.silganCoc,
      //   estmatedAnnualVolume: this.currencyPipe.transform(this.formData.estmatedAnnualVolume),
      estmatedAnnualVolume: this.formData.estmatedAnnualVolume,
      coatingRequired: this.formData.coatingRequired,
      SpecialIssues: this.formData.specialIssues,
      notes: this.formData.notes,
      experimentalTypeName: this.formData.experimentalTypeName,
    });

    this.CCMaterial= this.formData.customerClosureMaterial;
    this.CCMaterialSize = this.formData.customerClosureMaterialSize;
    this.CCMaterialType = this.formData.customerClosureMaterialType; 

    let currentURL = this.router.url;
    if (!currentURL.includes("formhistorystepper")) {
      if (this.loginUser != null && this.loginUser != undefined) {
        if (this.loginUser.role == "Admin") {
          this.showEditButtons = true;
          this.closureForm.enable();
        } else if (this.loginUser.role == "Viewer") {
          this.showEditButtons = false;
          this.closureForm.disable();
        } else if (this.loginUser.role == "CAS") {
          if (this.formData.status == 3 || this.formData.status == 1) {
            this.showEditButtons = true;
            this.closureForm.enable();
          } else {
            this.showEditButtons = false;
            this.closureForm.disable();
          }
        } else if (this.loginUser.role == "SME") {
          if (
            this.formData.status == 4 &&
            this.formData.customerClosureMaterial == "Composite"
          ) {
            var output = this.loginUser.teamId.split("");
            for (let i = 0; i < output.length; i++) {
              let id = output[i];
              if (id == "2") {
                this.showEditButtons = true;
                this.closureForm.enable();
              }
            }
          } else if (
            this.formData.status == 4 &&
            this.formData.customerClosureMaterial == "Plastic"
          ) {
            var output = this.loginUser.teamId.split("");
            for (let i = 0; i < output.length; i++) {
              let id = output[i];
              if (id == "4") {
                this.showEditButtons = true;
                this.closureForm.enable();
              }
            }
          } else if (
            this.formData.status == 4 &&
            this.formData.customerClosureMaterial == "Metal (Steel)"
          ) {
            var output = this.loginUser.teamId.split("");
            for (let i = 0; i < output.length; i++) {
              let id = output[i];
              if (id == "1") {
                this.showEditButtons = true;
                this.closureForm.enable();
              }
            }
          } else if (
            this.formData.status == 4 &&
            this.formData.customerClosureMaterial == "ROPP (Alum)"
          ) {
            var output = this.loginUser.teamId.split("");
            for (let i = 0; i < output.length; i++) {
              let id = output[i];
              if (id == "3") {
                this.showEditButtons = true;
                this.closureForm.enable();
              }
            }
          } else {
            this.showEditButtons = false;
            this.closureForm.disable();
          }
        }
      } else {
        if (this.formData.status == 2) {
          this.showEditButtons = false;
          this.closureForm.disable();
        } else {
          this.showEditButtons = false;
          this.closureForm.disable();
        }
      }
    }
    if (this.formData.isFormLock==1) {
      if (this.loginUser != null && this.loginUser != undefined) {
        if (this.loginUser.role == "Admin") {
          this.showEditButtons = false;
          this.closureForm.disable();
        } else if (this.loginUser.role == "Viewer") {
          this.showEditButtons = false;
          this.closureForm.disable();
        } else if (this.loginUser.role == "CAS") {
          if (this.formData.status == 3 || this.formData.status == 1) {
            this.showEditButtons = false;
            this.closureForm.disable();
          } else {
            this.showEditButtons = false;
            this.closureForm.disable();
          }
        } else if (this.loginUser.role == "SME") {
          if (
            this.formData.status == 4 &&
            this.formData.customerClosureMaterial == "Composite"
          ) {
            var output = this.loginUser.teamId.split("");
            for (let i = 0; i < output.length; i++) {
              let id = output[i];
              if (id == "2") {
                this.showEditButtons = false;
                this.closureForm.disable();
              }
            }
          } else if (
            this.formData.status == 4 &&
            this.formData.customerClosureMaterial == "Plastic"
          ) {
            var output = this.loginUser.teamId.split("");
            for (let i = 0; i < output.length; i++) {
              let id = output[i];
              if (id == "4") {
                this.showEditButtons = false;
                this.closureForm.disable();
              }
            }
          } else if (
            this.formData.status == 4 &&
            this.formData.customerClosureMaterial == "Metal (Steel)"
          ) {
            var output = this.loginUser.teamId.split("");
            for (let i = 0; i < output.length; i++) {
              let id = output[i];
              if (id == "1") {
                this.showEditButtons = false;
                this.closureForm.disable();
              }
            }
          } else if (
            this.formData.status == 4 &&
            this.formData.customerClosureMaterial == "ROPP (Alum)"
          ) {
            var output = this.loginUser.teamId.split("");
            for (let i = 0; i < output.length; i++) {
              let id = output[i];
              if (id == "3") {
                this.showEditButtons = false;
                this.closureForm.disable();
              }
            }
          } else {
            this.showEditButtons = false;
            this.closureForm.disable();
          }
        }
      } else {
        if (this.formData.status == 2) {
          this.showEditButtons = false;
          this.closureForm.disable();
        } else {
          this.showEditButtons = false;
          this.closureForm.disable();
        }
      }
    }


  };
  onSubscribeError(data) {}
  onSubmit() {    
    this.submitted = true;
    if (this.closureForm.invalid) {
      $("#formsubmit-modal").modal({
        backdrop: 'static',
        keyboard: false
      });
      return;
    }
    this.loading = true;
    this.formService.register(this.closureForm.value).subscribe(
      (data) => {
        this.loading = false;
        //this.toastr.success("This PSR form has been emailed to the customer.");
        this.successMessage = "This PSR form has been emailed to the customer.";
        $("#edit-modal").modal({

         
        });
      },
      (error) => {
        this.loading = false;
      }
    );
  }

  onSendEmailToCustomer() {
    this.submitted = true;
    if (this.closureForm.invalid) {
      $("#formsubmit-modal").modal("show");
      return;
    }
    this.loading = true;
    //this.closureForm.disable();
    this.formService.SendEmailToCustomer(this.closureForm.value).subscribe(
      (data) => {
        this.loading = false;
        //this.toastr.success("This form has been initiated but not yet sent to the customer.");
        this.successMessage = "This PSR form has been emailed to the customer.";
        $("#edit-modal").modal("show");
        //this.closureForm.enable();
        //this.router.navigate(['/formsList']);
      },
      (error) => {
        this.closureForm.enable();
        this.loading = false;
      }
    );
  }

  onSearchForCurrent() {
    // this.formService.GetAllFormsByAdvSearchForms(this.closureForm.value, 'Profile').subscribe(data => {
    //   this.router.navigate(['/formsList'], { state: { data: data } });
    // });
  }

  onSearchForAllFormFields() {
    // this.formService.GetAllFormsByAdvSearchForms(this.closureForm.value, 'All').subscribe(data => {
    //   this.router.navigate(['/formsList'], { state: { data: this.closureForm.value } });
    // });
  }

  onSearchCancel() {
    this.router.navigate(["/formsList"]);
  }

  changeOrderType(orderType) {
    this.closureForm.get("orderTypeId").value = orderType;
  }
  onSubmitSaveDraft() {
    
    this.submitted = true;
    // stop here if form is invalid
    if (this.closureForm.invalid) {
      $("#formsubmit-modal").modal("show");
      this.clicked;
      return;
      
    }

    this.loading = true;
    // this.closureForm.disable();
    this.formService.SaveDraft(this.closureForm.value).subscribe(
      (data) => {
        this.loading = false;
        this.closureForm.enable();
        this.successMessage =
          "This form has been initiated but not yet sent to the customer.";
       this.clicked = true;
        $("#edit-modal").modal("show");
        
        //this.router.navigate(['/formsList']);
      },
      (error) => {
        this.closureForm.enable();
        this.loading = false;
      }
    );
  }
  onSubmitSaveProfile() {
    this.submitted = true;
    Object.keys(this.closureForm.controls).forEach((key) => {
      if (this.closureForm.controls[key].errors) {
      }
    });
    if (this.closureForm.invalid) {
      $("#formsubmit-modal").modal("show");
      return;
    }
    this.loading = true;
    this.formData.profileCompleted = true;
    this.formService.SaveProfileFormData(this.closureForm.value).subscribe(
      (data) => {
        this.loading = false;
        this.closureForm.enable();
        localStorage.setItem("dataSource", JSON.stringify(this.formData));
        location.reload();
     //   this.formCompleted.emit(true);

      },
      (error) => {
        this.closureForm.enable();
        this.loading = false;
      }
    );
  }

  updateAndClearAllFormData() {
    this.submitted = true;
    Object.keys(this.closureForm.controls).forEach((key) => {
      if (this.closureForm.controls[key].errors) {
      }
    });
    if (this.closureForm.invalid) {
      $("#formsubmit-modal").modal("show");
      return;
    }
    this.loading = true;
    this.formService.UpdateAndClearAllFormData(this.closureForm.value).subscribe(
      (data) => {
        this.loading = false;
        this.closureForm.enable();
        $("#form-closure-meterial").modal("hide");
        //this.router.navigate(['/formsList']);
        //this.location.reload();
        location.reload();
       // this.router.navigate(['/formstepper/' + this.closureForm.get("pSRNo").value]);
       // this.formCompleted.emit(true);
      },
      (error) => {
        this.closureForm.enable();
        this.loading = false;
      }
    );
  }

  onSubmitCancel() {
    this.router.navigate(["/formsList"]);
  }
  onSuccessOk() {
    $("#edit-modal").modal("hide");
    this.router.navigate(["/formsList"]);
  }

  onOk() {
    
    $("#formsubmit-modal").modal("hide");
    this.router.navigate(["/newclosureform"]);
  }
}
interface IFormProfile {
  customerEmail: string;
  casManagerName: string;
  currentUserEmail: string;
  currentUserPhone: string;
  createdById: number;
  customerComapnyName: string;
  customerName: string;
  customerContactPhoneNumber: string;
  location: string;
  customerClosureMaterial: string;
  customerClosureMaterialType: string;
  customerClosureMaterialSize: number;
  orderTypeId: number;
  orderReasonFirstTimeId: number;
  orderReasonReplacementId: number;
  orderReasonReplacementExplaination: string;
  customerProduct: string;
  initialOrderQuantity: string;
  dateClosureNeeded: string;
  silganCoc: string;
  estmatedAnnualVolume: string;
  coatingRequired: boolean;
  specialIssues: string;
  experimentalTypeName: string;
  notes: string;
  customerEXT: string;
  customerContact: string;
}


