import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { User } from '../models/user';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;
    baseUrl: string;
    constructor(private http: HttpClient, @Inject('BASE_URL') appURL: string) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
        //this.baseUrl = "https://localhost:44392/";
        this.baseUrl = appURL;
        //this.baseUrl = "http://silganclosures.csdevhub.com/";
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

  login(username: string, password: string, IsRememberMe: boolean) {    
    return this.http.post<any>(`${this.baseUrl}users/authenticate`, { username, password, IsRememberMe })
            .pipe(map(user => {
                
                // login successful if there's a jwt token in the response
                if (user && user.token) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify(user));
                    localStorage.setItem('_ur', user.role);
                    if(IsRememberMe == true)
                    {
                        // encript the username or password
                        // let encUsrnam = CryptoJS.AES.encrypt(username, "password").toString();  
                        // let encUsrpass = CryptoJS.AES.encrypt(password, "username").toString(); 
                        localStorage.setItem("userName", username);
                        localStorage.setItem("userPassword", password);
                    }
                    this.currentUserSubject.next(user);
                }

                return user;
            }));
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
    }
}
