import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AuthenticationService } from '../services/authentication.service';
//import { AlertService } from '../alert/alert.service';
import { ToastrService } from 'ngx-toastr';
//  import * as CryptoJS from 'crypto-js';  

@Component({
  templateUrl: 'login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  Username: string;
  Password: string;
  RememberMe: boolean;
  get password() {
    return this.loginForm.get('password');
  }

  get username() {
    return this.loginForm.get('username')
  }
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private toastr: ToastrService
  ) {
    // redirect to home if already logged in
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(['/formsList']);
    }
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(6)]],
      rememberMe: [false]
    });
  }

  ngOnInit() {
    
    if(localStorage.getItem("userName") != null)
    {
      // let encusrnam=localStorage.getItem("userName");
      // let encusrpass=localStorage.getItem("userPassword");
      // this.Username=CryptoJS.AES.decrypt(encusrnam, "password").toString(CryptoJS.enc.Utf8);  
      // this.Password=CryptoJS.AES.decrypt(encusrpass, "username").toString(CryptoJS.enc.Utf8);  
      this.Username=localStorage.getItem("userName");
      this.Password=localStorage.getItem("userPassword");
      this.RememberMe=true;
    }
    //this.Username = this.getCookie("Username");
    //this.Password = decodeURIComponent(this.getCookie("Password"));
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/formsList';
  }
  //getCookie(name: string) {
  //  const value = "; " + document.cookie;
  //  const parts = value.split("; " + name + "=");

  //  if (parts.length == 2) {
  //    return parts.pop().split(";").shift();
  //  }
  //}
  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  loginValidator(event: any) {
    
    var charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 32 && (charCode < 48 || charCode > 57 || charCode >= 97 || charCode <= 122 || charCode >= 65 || charCode <= 90))
      return true;

    return false;
  }
 
  onSubmit() {
    
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      this.submitted = true;
      return;
    }

    this.loading = true;
    this.authenticationService.login(this.f.username.value, this.f.password.value, this.f.rememberMe.value)
      .pipe(first())
      .subscribe(
        data => {
          this.loading = false;
          this.router.navigate([this.returnUrl]);

        },
        error => {
          this.toastr.error(error);
          this.loading = false;
        });
  }
}
