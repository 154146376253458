import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ClosureForm } from 'src/app/models/closure-form';
import { FormBuilder, Validators } from '@angular/forms';
import { ClosureFormService } from 'src/app/services/closure-form.service';
import { Router } from '@angular/router';
import { User } from 'src/app/models/user';
import { AuthenticationService } from 'src/app/services/authentication.service';

declare var $: any;
@Component({
  selector: 'app-palletization',
  templateUrl: './palletization.component.html',
  styleUrls: ['./palletization.component.css']
})



export class PalletizationComponent implements OnInit {
  loginUser: User;
  loading: boolean = false;
  submitted = false;
  closureForm;
  formData: ClosureForm;
  @Input() formId: number;
  @Output() formCompleted = new EventEmitter();
  showSubmitButton = true;
  formSearch: boolean = false;
  successMessage = "";
  formStatus: number;
  get packageTrayPacked() {
    return this.closureForm.get('packageTrayPacked');
  }
  get warehouseStorageCondition() {
    return this.closureForm.get('warehouseStorageCondition');
  }
  get customerClosureMaterial() {
    return this.closureForm.get('customerClosureMaterial');
  }
  get humidity() {
    return this.closureForm.get('humidity');
  }
  get packagesPerTray() {
    return this.closureForm.get('packagesPerTray');
  }
  get externalCondensationWithHumidity() {
    return this.closureForm.get('externalCondensationWithHumidity');
  }
  get retailDisplayConditions() {
    return this.closureForm.get('retailDisplayConditions');
  }
  get customerContantNameTitle() {
    return this.closureForm.get('customerContantNameTitle');
  }
  get palletsHigh() {
    return this.closureForm.get('palletsHigh');
  }

  constructor(private formBuilder: FormBuilder, private formService: ClosureFormService, private authService: AuthenticationService, private router: Router) {
    this.closureForm = this.formBuilder.group({
      psrNo: '',
      //   packerNameAndAddress: [''],
      palletsHigh: [''],
      packagesPerTray: [''],
      traysPerLayer: [''],
      layersPerPallet: [''],
      weightPerPallet: [''],
      warehouseStorageCondition: ['Refrigerated', Validators.required],
      externalCondensationWithHumidity: ['External Condensation with Humidity'],
      retailDisplayConditions: ['', Validators.required],
      humidity: ['Dry'],
      productWeightPerPalletInLbs: [''],
      packageTrayPacked: ['Unknown', Validators.required],
      customerContantNameTitle: ['', Validators.required],
      customerClosureMaterial: 'Composite',
      totalPalletWeightPound:''
    });
  }

  ngOnInit() {
    this.loginUser = this.authService.currentUserValue;
    let currentURL = this.router.url;

    if (currentURL.includes("formsearch")) {
      this.formSearch = true;
    }

    if (this.formId) {
      if (currentURL.includes("formhistorystepper")) {
        this.formService.getFormHistoryById(this.formId).subscribe(this.onSubscribeSuccess);
        this.closureForm.disable();
        this.showSubmitButton = false;
      }
      else {
        this.formService.getById(this.formId).subscribe(this.onSubscribeSuccess);
      }
    }

    if (this.formId) {
      this.formService.getById(this.formId).subscribe()
    }
  }

  onSubscribeSuccess = data => {
//    console.log("Form Data: " + JSON.stringify(data));
    this.formData = data;
 //   debugger;
  //  console.log("formdata:", this.formData);
    this.formStatus = this.formData.status;
    this.closureForm.setValue({
      //customerEmail: this.formData.customerEmail,
      psrNo: this.formId,
      //   packerNameAndAddress: this.formData.packerNameAndAddress,
      palletsHigh: this.formData.palletsHigh,
      packagesPerTray: this.formData.packagesPerTray,
      traysPerLayer: this.formData.traysPerLayer,
      layersPerPallet: this.formData.layersPerPallet,
      weightPerPallet: this.formData.weightPerPallet,
      warehouseStorageCondition: this.formData.warehouseStorageCondition == '' ? 'Refrigerated' : this.formData.warehouseStorageCondition,
      externalCondensationWithHumidity: this.formData.externalCondensationWithHumidity == '' ? 'External Condensation with Humidity' : this.formData.externalCondensationWithHumidity,
      humidity: this.formData.humidity == '' ? 'Dry' : this.formData.humidity,
      retailDisplayConditions: this.formData.retailDisplayConditions == '' ? 'Refrigerated' : this.formData.retailDisplayConditions,
      productWeightPerPalletInLbs: this.formData.productWeightPerPalletInLbs,
      packageTrayPacked: this.formData.packageTrayPacked == '' ? 'Unknown' : this.formData.packageTrayPacked,
      customerContantNameTitle: this.formData.customerContantNameTitle == null ? data.customerName : this.formData.customerContantNameTitle,
      customerClosureMaterial: this.formData.customerClosureMaterial,
      totalPalletWeightPound: this.formData.totalPalletWeightPound,
    //  CustomerEmail: this.formData.customerEmail,
     // customerComapnyName: this.formData.customerComapnyName,
      //this.closureForm.disable();
    });

    //let currentURL = this.router.url;
    //if (!currentURL.includes("formhistorystepper")) {

    //  if (this.loginUser != null && this.loginUser != undefined) {
    //    console.log("customer role" + this.loginUser.role);
    //    if (this.loginUser.role) {
    //      this.showSubmitButton = true;
    //      this.closureForm.enable();
    //    }
    //  }
    //  else {
    //    if (this.formData.status == 2) {

    //    } else {
    //      this.showSubmitButton = false; /// 11.46    cs  123password
    //      this.closureForm.disable();
    //    }
    //  }
    //}

    let currentURL = this.router.url;
    if (!currentURL.includes("formhistorystepper")) {
      if (this.loginUser != null && this.loginUser != undefined) {
   //     console.log("customer role" + this.loginUser.role);
        if (this.loginUser.role == 'Admin') {
          this.showSubmitButton = true;
          this.closureForm.enable();
        }
        else if (this.loginUser.role == 'Viewer') {

          this.showSubmitButton = false;
          this.closureForm.disable();
        }
        else if (this.loginUser.role == 'CAS') {
          if (this.formData.status == 3 || this.formData.status == 1) {
            this.showSubmitButton = true;
            this.closureForm.enable();
          }
          else {
            this.showSubmitButton = false;
            this.closureForm.disable();
          }
        } else if (this.loginUser.role == 'SME') {  
          //if (this.formData.status == 4 && this.formData.customerClosureMaterial == 'Composite' && this.loginUser.teamId == '2') {
          //  this.showSubmitButton = true;
          //  this.closureForm.enable();
          //}
          //else if (this.formData.status == 4 && this.formData.customerClosureMaterial == 'Plastic' && this.loginUser.teamId == '4') {
          //  this.showSubmitButton = true;
          //  this.closureForm.enable();
          //}
          //else if (this.formData.status == 4 && this.formData.customerClosureMaterial == 'Metal (Steel)' && this.loginUser.teamId == '1') {
          //this.showSubmitButton = true;
          //  this.closureForm.enable();
          //}
          //else if (this.formData.status == 4 && this.formData.customerClosureMaterial == 'ROPP (Alum)' && this.loginUser.teamId == '3') {
          //  this.showSubmitButton = true;
          //  this.closureForm.enable();
          //}

          if (this.formData.status == 4 && this.formData.customerClosureMaterial == 'Composite') {
            var output = this.loginUser.teamId.split('');
            for (let i = 0; i < output.length; i++) {
              let id = output[i];
              if (id == "2") {
                this.showSubmitButton = true;
                this.closureForm.enable();
              }
            }
          }
          else if (this.formData.status == 4 && this.formData.customerClosureMaterial == 'Plastic') {
            var output = this.loginUser.teamId.split('');
            for (let i = 0; i < output.length; i++) {
              let id = output[i];
              if (id == "4") {
                this.showSubmitButton = true;
                this.closureForm.enable();
              }
            }
          }
          else if (this.formData.status == 4 && this.formData.customerClosureMaterial == 'Metal (Steel)') {
            var output = this.loginUser.teamId.split('');
            for (let i = 0; i < output.length; i++) {
              let id = output[i];
              if (id == "1") {
                this.showSubmitButton = true;
                this.closureForm.enable();
              }
            }
          }
          else if (this.formData.status == 4 && this.formData.customerClosureMaterial == 'ROPP (Alum)') {
            var output = this.loginUser.teamId.split('');
            for (let i = 0; i < output.length; i++) {
              let id = output[i];
              if (id == "3") {
                this.showSubmitButton = true;
                this.closureForm.enable();
              }
            }
          }
          else {
            this.showSubmitButton = false;
            this.closureForm.disable();
          }
        }
      }
      else {
        if (this.formData.status == 2) {
        }
        else {
          this.showSubmitButton = false;
          this.closureForm.disable();
        }
      }
    }

    if (this.formData.isFormLock == 1) {
      if (this.loginUser != null && this.loginUser != undefined) {
        if (this.loginUser.role == "Admin") {
          this.showSubmitButton = false;
          this.closureForm.disable();
        } else if (this.loginUser.role == "Viewer") {
          this.showSubmitButton = false;
          this.closureForm.disable();
        } else if (this.loginUser.role == "CAS") {
          if (this.formData.status == 3 || this.formData.status == 1) {
            this.showSubmitButton = false;
            this.closureForm.disable();
          } else {
            this.showSubmitButton = false;
            this.closureForm.disable();
          }
        } else if (this.loginUser.role == "SME") {
          if (
            this.formData.status == 4 &&
            this.formData.customerClosureMaterial == "Composite"
          ) {
            var output = this.loginUser.teamId.split("");
            for (let i = 0; i < output.length; i++) {
              let id = output[i];
              if (id == "2") {
                this.showSubmitButton = false;
                this.closureForm.disable();
              }
            }
          } else if (
            this.formData.status == 4 &&
            this.formData.customerClosureMaterial == "Plastic"
          ) {
            var output = this.loginUser.teamId.split("");
            for (let i = 0; i < output.length; i++) {
              let id = output[i];
              if (id == "4") {
                this.showSubmitButton = false;
                this.closureForm.disable();
              }
            }
          } else if (
            this.formData.status == 4 &&
            this.formData.customerClosureMaterial == "Metal (Steel)"
          ) {
            var output = this.loginUser.teamId.split("");
            for (let i = 0; i < output.length; i++) {
              let id = output[i];
              if (id == "1") {
                this.showSubmitButton = false;
                this.closureForm.disable();
              }
            }
          } else if (
            this.formData.status == 4 &&
            this.formData.customerClosureMaterial == "ROPP (Alum)"
          ) {
            var output = this.loginUser.teamId.split("");
            for (let i = 0; i < output.length; i++) {
              let id = output[i];
              if (id == "3") {
                this.showSubmitButton = false;
                this.closureForm.disable();
              }
            }
          } else {
            this.showSubmitButton = false;
            this.closureForm.disable();
          }
        }
      } else {
        if (this.formData.status == 2) {
          this.showSubmitButton = false;
          this.closureForm.disable();
        } else {
          this.showSubmitButton = false;
          this.closureForm.disable();
        }
      }
    }

  }
  onChanges() {
    let humidity = this.closureForm.get('humidity').value;
    let retailDisplayConditions = this.closureForm.get('retailDisplayConditions').value;
    if (humidity == 'Wet') {
      this.closureForm.patchValue({
        retailDisplayConditions: "Ice Bucket"
      });
    }
  }
  onlyNumberValid(evt: any) {
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode != 45 && charCode > 31
      && (charCode < 48 || charCode > 57))
      return false;

    return true;
  }



  onSendEmailToCustomer() {debugger
    this.submitted = true;
    if (this.closureForm.invalid) {
      $("#Palletizationformsubmit-modal").modal("show");
      return;
    }
    this.loading = true;
    //this.closureForm.disable();
    this.formService.SendEmailToCustomerFromPalletzation(this.closureForm.value)
      .subscribe(
        data => {
          this.loading = false;
          //this.toastr.success("This form has been initiated but not yet sent to the customer.");
          this.successMessage = "This PSR form has been emailed to the customer.";
          $('#edit-modal').modal('show');
          //this.closureForm.enable();
          //this.router.navigate(['/formsList']);
        },
        error => {
          this.closureForm.enable();
          this.loading = false;
        });
  }


  onSubmit() {
    this.submitted = true;
   // console.log(this.closureForm.value);
    //console.log(' Closure form invalid : ' + this.closureForm.invalid);
    // stop here if form is invalid
    if (this.closureForm.invalid) {
      $("#Palletizationformsubmit-modal").modal("show");
      return;
    }
    this.closureForm.disable();
    this.loading = true;
    this.formData.palletizationCompleted = true;

    this.formService.SavePallizationFormData(this.closureForm.value)
      .subscribe(
        data => {
          this.closureForm.enable();
          this.loading = false;
          localStorage.setItem("dataSource", JSON.stringify(this.formData));
          this.formCompleted.emit(true);
          //this.router.navigate(['/formsList']);
        },
        error => {
          this.closureForm.enable();
          this.loading = false;
        });
  }
  onSearchForCurrent() {

  }

  onSearchForAllFormFields() {

  }

  onSearchCancel() {

  }




  _keyUp(event: any) {
   
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.key);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }//81,87,69,82,84,89,85,73,79,80,65,83,68,70,71,72,74,75,76,59,222,90,88,67,86,66,78,77
     //  onkeydown = "return event.keyCode != 69 && event.keyCode != 189"
    if (event.key == 'e' || event.key == '-' || event.key == '+' || event.key == 'q' || event.key == 'w' || event.key == 'r' || event.key == 't' || event.key == 'y'
      || event.key == 'u' || event.key == 'i' || event.key == 'o' || event.key == 'p' || event.key == 'a' || event.key == 's' || event.key == 'd' || event.key == 'f'
      || event.key == 'g' || event.key == 'h' || event.key == 'j' || event.key == 'k' || event.key == 'l' || event.key == 'z' || event.key == 'x' || event.key == 'c'
      || event.key == 'v' || event.key == 'b' || event.key == 'n' || event.key == 'n' || event.key == 'm' || event.key == 'm' || event.key == '.' || event.key == ','    ) {
    this.closureForm.patchValue({     
      palletsHigh: "",
    });
    }


  }

  onPalletizationOk() {
    
    $("#Palletizationformsubmit-modal").modal("hide");
    this.router.navigate(["/formstepper/" + this.closureForm.get("pSRNo").value]);
  }
  onSuccessOk() {
    this.router.navigate(['/formsList']);
  }

}
