import { from } from 'rxjs';
import { productShelfLifeValidators } from './productShelfLifeValidators';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ClosureFormService } from 'src/app/services/closure-form.service';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ClosureForm } from 'src/app/models/closure-form';
import { User } from 'src/app/models/user';
import { FileService } from '../../../app/services/file.service';
declare var $: any;
import { CommonModule } from '@angular/common';  
import { debug } from 'util';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css']
})
export class ProductComponent implements OnInit {
  loginUser: User;
  closureForm;
  loading: boolean = false;
  showEditButtons = true;
  submitted = false;
  @Input() formId;
  @Output() formCompleted = new EventEmitter();
  showSubmitButton = true;
  formData: ClosureForm;
  formSearch: boolean = false;
  fileUpload: File = null;
  filename: string;
  uploadResponse = { status: '', message: '', filePath: '' };
  userId: number = 1;
  error: string;
  successMessage = "";
  productEstimatedpHLevel = "";
  //CustomerClosureMaterial = "";
 
 


  get isFatOnSurface() {
    return this.closureForm.get('isFatOnSurface');
  }
  get isBeverage() {
    return this.closureForm.get('isBeverage');
  }

  get beverageType() {
    return this.closureForm.get('beverageType');
  }
  get isVinegarPresent() {
    return this.closureForm.get('isVinegarPresent');
  }
  get phLevelId() {
    return this.closureForm.get('phLevelId');
  }
  get shelfLifePackedProduct() {
    return this.closureForm.get('shelfLifePackedProduct');
  }

  get isSO2Present() {
    return this.closureForm.get('isSO2Present');
  }
  get productContainCBDorTHC() {
    return this.closureForm.get('productContainCBDorTHC');
  }

  get isAlcoholPresent() {
   
    return this.closureForm.get('isAlcoholPresent');
  }

  get shippedOutsideUSA() {
    return this.closureForm.get('shippedOutsideUSA');
  }
  get whtCountry() {
    return this.closureForm.get('whtCountry');
  }

  get dieteticOrLowCalorie() {
    return this.closureForm.get('dieteticOrLowCalorie');
  }
  get fdaFoodCategory() {
    
    return this.closureForm.get('fdaFoodCategory');
  }
  get fdaCondition() {
    return this.closureForm.get('fdaCondition');
  }
  get cbdfile() {
    return this.closureForm.get('cbdfile');
  }
  get cbddrwningfileupload() {
    return this.closureForm.get('cbddrwningfileupload');
  }
  get productPhLevel() {
    return this.closureForm.get('productPhLevel');
  }
  get ActualPh() {
    return this.closureForm.get("estmatedAnnualVolume");
  }
  get CustomerClosureMaterial() {
    return this.closureForm.get("CustomerClosureMaterial");
  }
  get ozoneGasTreated() {
    return this.closureForm.get("ozoneGasTreated");
  }
  constructor(private formBuilder: FormBuilder, private formService: ClosureFormService, private fileService: FileService, private router: Router, private authService: AuthenticationService) {
    
    this.closureForm = this.formBuilder.group({
      pSRNo: this.formId,
      isFatOnSurface: ['', Validators.required],
      isBeverage: ['No', Validators.required],
      beverageType: '',
      phLevelId: ['pH 4.6 or Below', Validators.required],
      productPhLevel: [""],
      productEstimatedpHLevel : '',
      beverageTypeOther: '',
      ozoneGasTreated:'',
      isVinegarPresent: ['', Validators.required],
      amountofVinegarPresent: '',
      isSO2Present: ['No', Validators.required],
      ppm: '',
      cbdfile: '',
      cbddrwningfileupload: '',
      isAlcoholPresent: ['', Validators.required],
      amntOfAlcoholPresent: '',
      shelfLifePackedProduct: ['', [Validators.required, productShelfLifeValidators.shelfLifeValidator]],
      productContainCBDorTHC: ['CBD', Validators.required],
      //fdaFoodCategory: ['Type I: Non-Acid Aqueous Products; May Contain Salt or Sugar or Both (pH Above 5.0)', Validators.required],
      fdaFoodCategory: ['', Validators.required],
      fdaCondition: ['A. High temperature heat-sterilized (e.g., over 212 deg. F)', Validators.required],
      shippedOutsideUSA: ['No', Validators.required],
      listTheCountriesWhrPckgShipped: '',
      whtCountry: '',
      dieteticOrLowCalorie: 'No',      
      CustomerClosureMaterial: '',
     
      
    });
  }

  ngOnInit() {
    
    this.loginUser = this.authService.currentUserValue;
    let currentURL = this.router.url;
    if (currentURL.includes("formsearch")) {
      this.formSearch = true;
    }
    if (this.formId) {
      if (currentURL.includes("formhistorystepper")) {
        this.formService.getFormHistoryById(this.formId).subscribe(this.onSubscribeSuccess);
        this.closureForm.disable();
        this.showEditButtons = false;
      }
      else {
        this.formService.getById(this.formId).subscribe(this.onSubscribeSuccess);
      }
    }
  }
  ngOnChanges() {
    
    this.ngOnInit();
  }
  check(){
    let hey = this.closureForm.get('shelfLifePackedProduct');

  }
  onlyNumberValid(evt: any) {
   
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57)) {
      if (charCode == 37)
        return true;
      else
        return false;
    }
    else
      return true;
  }

  onbeverageChange() {
    let getisBeverage = this.closureForm.get('isBeverage').value;
    if (getisBeverage == "No") {
      this.closureForm.patchValue({
        beverageType: ""
      });
    }
  }

  //Dot Not supported
  //onlyPHNumberValid(evt: any) {
  //  var charCode = (evt.which) ? evt.which : evt.keyCode;
  //  if (charCode != 45 && charCode > 31
  //    && (charCode < 48 || charCode > 57))
  //    return false;

  //  return true;
  //}




  uploadFile(files) {
    ////file[0].name        
    this.fileUpload = files.item(0);
    this.filename = files[0].name;
      this.closureForm.patchValue({
        cbdfile: this.filename
      });
  }

  checkPhValue() {
    
    let selectPh = this.closureForm.get("productEstimatedpHLevel").value;
    let actualPh = this.closureForm.get("productPhLevel").value;
    let phvalue = "4.6";
    if (selectPh == 'pH Above 4.6') {
      if (actualPh > 4.6) {
        this.closureForm.patchValue({
          productPhLevel: actualPh,
        });
      }
      else {
        this.closureForm.patchValue({
          productPhLevel: '',
        });
      }
    }

    if (selectPh == 'pH 4.6 or Below') {
      if (actualPh <= 4.6) {
        this.closureForm.patchValue({
          productPhLevel: actualPh,
        });
      }
      else {
        this.closureForm.patchValue({
          productPhLevel: '',
        });
      }
    }

  }

  checkValidity() {   
  
    let ph = this.closureForm.get("productEstimatedpHLevel").value;
    let ActualPh = this.closureForm.get("productPhLevel").value;
      this.productEstimatedpHLevel
    if (ph == 'pH 4.6 or Below') {
      if (ActualPh > "4.6") {
        this.closureForm.patchValue({
          productPhLevel: "",
        });
      }
    }    
      if (ph == 'pH Above 4.6') {
        if (ActualPh <= "4.6") {
          if (ActualPh == "1") {
            this.closureForm.patchValue({
              productPhLevel: "1",
            });
          }
          if (ActualPh == "10") {
            this.closureForm.patchValue({
              productPhLevel: "10",
            });
          }
          if (ActualPh == "11") {
            this.closureForm.patchValue({
              productPhLevel: "11",
            });
          }
          if (ActualPh == "12") {
            this.closureForm.patchValue({
              productPhLevel: "12",
            });
          }
          if (ActualPh == "13") {
            this.closureForm.patchValue({
              productPhLevel: "13",
            });
          }
          if (ActualPh == "14") {
            this.closureForm.patchValue({
              productPhLevel: "14",
            });
          }

        if (ActualPh == "4") {
          this.closureForm.patchValue({
            productPhLevel: "4",
          });
        }
        if (ActualPh == "4.") {
          this.closureForm.patchValue({
            productPhLevel: "4.",
          });
      }
      if (ActualPh == "4.1") {
        this.closureForm.patchValue({
          productPhLevel: "",
        });
      }
      if (ActualPh == "4.2") {
        this.closureForm.patchValue({
          productPhLevel: "",
        });
      }
      if (ActualPh == "4.3") {
        this.closureForm.patchValue({
          productPhLevel: "",
        });
      }
      if (ActualPh == "4.4") {
        this.closureForm.patchValue({
          productPhLevel: "",
        });
      }
      if (ActualPh == "4.5") {
        this.closureForm.patchValue({
          productPhLevel: "",
        });
      }
      if (ActualPh == "4.6") {
        this.closureForm.patchValue({
          productPhLevel: "",
        });
      }
      //if (ActualPh == "1") {
      //  this.closureForm.patchValue({
      //    productPhLevel: "",
      //  });
      //}
      if (ActualPh == "2") {
        this.closureForm.patchValue({
          productPhLevel: "",
        });
      }
      if (ActualPh == "3") {
        this.closureForm.patchValue({
          productPhLevel: "",
        });
      }       
        }          
    }       
    if (ph == 'pH Above 4.6') {
      if (ActualPh > "4.7") {

        this.closureForm.patchValue({
          productPhLevel: ActualPh,
        });

      }
    }
        
  }

  onSubmit() {
    let ph = this.closureForm.get("productEstimatedpHLevel").value;
    let ActualPh = this.closureForm.get("productPhLevel").value;
    let phvalue = '4.6';
    
    if (ph == 'pH 4.6 or Below') {
      if (ActualPh > 5) {
        this.closureForm.patchValue({
          productPhLevel: "",
        });
        this.submitted = false;
      }
    }
    
    if (ph == 'pH Above 4.6') {
      if (ActualPh == 1 || ActualPh > 14 || ActualPh == 4 || ActualPh == 4. )  {
          this.closureForm.patchValue({
            productPhLevel: "",
          });
          this.submitted = false;
      }
    }
    


    
    this.submitted = true;
    // stop here if form is invalid
    if (this.closureForm.invalid) {
      $("#Productformsubmit-modal").modal("show");
      return;
    }
    this.closureForm.disable();
    this.loading = true;
    if (this.fileUpload != null) {
      this.fileService.uploadPrdct(this.fileUpload, this.formId).subscribe(
        (res) => this.uploadResponse = res,
        (err) => this.error = err
      );
    }
    this.formData.productCompleted = true;
  
    
   

    this.formService.SaveProductFormData(this.closureForm.value)
      .subscribe(
        data => {
          this.closureForm.enable();
          this.loading = false;
    localStorage.setItem("dataSource", JSON.stringify(this.formData));
          this.formCompleted.emit(true);
          //this.router.navigate(['/formsList']);
        },
        error => {
          this.closureForm.enable();
          this.loading = false;
        });
  }

  onSearchForCurrent() {

  }

  onSearchForAllFormFields() {

  }

  onSearchCancel() {

  }
  onSubscribeSuccess = data => {
  
    this.formData = data;
    this.closureForm.setValue({
      pSRNo: this.formId,
      isFatOnSurface: this.formData.isFatOnSurface == '' ? 'No' : this.formData.isFatOnSurface,
      isBeverage: this.formData.isBeverage == '' ? 'No' : this.formData.isBeverage,
      //isBeverage: this.formData.isBeverage,
      beverageType: this.formData.beverageType == '' ? '' : this.formData.beverageType,
      beverageTypeOther: this.formData.beverageTypeOther,
      ozoneGasTreated: this.formData.ozoneGasTreated,
      phLevelId: this.formData.phLevelId == '' ? 'pH 4.6 or Below' : this.formData.phLevelId,
      productPhLevel: this.formData.productPhLevel,
      productEstimatedpHLevel: this.formData.productEstimatedpHLevel,
      isVinegarPresent: this.formData.isVinegarPresent == '' ? 'No' : this.formData.isVinegarPresent,
      amountofVinegarPresent: this.formData.amountofVinegarPresent,
      isSO2Present: this.formData.isSO2Present == '' ? 'No' : this.formData.isSO2Present,
      ppm: this.formData.ppm,
      isAlcoholPresent: this.formData.isAlcoholPresent == '' ? 'No' : this.formData.isAlcoholPresent,      
      amntOfAlcoholPresent: this.formData.amntOfAlcoholPresent,
      shelfLifePackedProduct: this.formData.shelfLifePackedProduct,
      productContainCBDorTHC: this.formData.productContainCBDorTHC == '' ? 'CBD' : this.formData.productContainCBDorTHC,
      fdaFoodCategory: this.formData.fdaFoodCategory,
      fdaCondition: this.formData.fdaCondition,
      cbdfile: '',
      cbddrwningfileupload: '',
      shippedOutsideUSA: this.formData.shippedOutsideUSA == '' ? 'No' : this.formData.shippedOutsideUSA,
      listTheCountriesWhrPckgShipped: this.formData.listTheCountriesWhrPckgShipped,
      whtCountry: this.formData.whtCountry,
      dieteticOrLowCalorie: this.formData.dieteticOrLowCalorie == '' ? 'No' : this.formData.dieteticOrLowCalorie,
      CustomerClosureMaterial: this.formData.customerClosureMaterial,
      
      
      
     
    });
    
    //this.closureForm.disable();

   let currentURL = this.router.url;
    if (!currentURL.includes("formhistorystepper")) {
      
      if (this.loginUser != null && this.loginUser != undefined) {
        if (this.loginUser.role == 'Admin') {
          
          this.showEditButtons = true;
          this.closureForm.enable();
        }
        else if (this.loginUser.role == 'Viewer') {

          this.showEditButtons = false;
          this.closureForm.disable();
        }
        else if (this.loginUser.role == 'CAS') {
          
          if (this.formData.status == 3 || this.formData.status == 1) {
            this.showEditButtons = true;
            this.closureForm.enable();
          }
          else {
            this.showEditButtons = false;
            this.closureForm.disable();
          }
        } else if (this.loginUser.role == 'SME') {
         
          //if (this.formData.status == 4 && this.formData.customerClosureMaterial == 'Composite' && this.loginUser.teamId == '2') {
          //  this.showEditButtons = true;
          //  this.closureForm.enable();
          //}
          //else if (this.formData.status == 4 && this.formData.customerClosureMaterial == 'Plastic' && this.loginUser.teamId == '4') {
          //  this.showEditButtons = true;
          //  this.closureForm.enable();
          //}
          //else if (this.formData.status == 4 && this.formData.customerClosureMaterial == 'Metal (Steel)' && this.loginUser.teamId == '1') {
          //  this.showEditButtons = true;
          //  this.closureForm.enable();
          //}
          //else if (this.formData.status == 4 && this.formData.customerClosureMaterial == 'ROPP (Alum)' && this.loginUser.teamId == '3') {
          //  this.showEditButtons = true;
          //  this.closureForm.enable();
          //}

          if (this.formData.status == 4 && this.formData.customerClosureMaterial == 'Composite') {
            var output = this.loginUser.teamId.split('');
            for (let i = 0; i < output.length; i++) {
              let id = output[i];
              if (id == "2") {
                this.showEditButtons = true;
                this.closureForm.enable();
              }
            }
          }
          else if (this.formData.status == 4 && this.formData.customerClosureMaterial == 'Plastic') {
            var output = this.loginUser.teamId.split('');
            for (let i = 0; i < output.length; i++) {
              let id = output[i];
              if (id == "4") {
                this.showEditButtons = true;
                this.closureForm.enable();
              }
            }
          }
          else if (this.formData.status == 4 && this.formData.customerClosureMaterial == 'Metal (Steel)') {
            var output = this.loginUser.teamId.split('');
            for (let i = 0; i < output.length; i++) {
              let id = output[i];
              if (id == "1") {
                this.showEditButtons = true;
                this.closureForm.enable();
              }
            }
          }
          else if (this.formData.status == 4 && this.formData.customerClosureMaterial == 'ROPP (Alum)') {
          
            var output = this.loginUser.teamId.split('');
            for (let i = 0; i < output.length; i++) {
              let id = output[i];
              if (id == "3") {
                this.showEditButtons = true;
                this.closureForm.enable();
              }
            }

          }

          else {
            this.showEditButtons = false;
            this.closureForm.disable();
          }
        }
      }
      else {     
        if (this.formData.status == 2) {
        }
        else {
          this.showEditButtons = false;
          this.closureForm.disable();
        }
    } 
    }


    if (this.formData.isFormLock == 1) {
      if (this.loginUser != null && this.loginUser != undefined) {
        if (this.loginUser.role == "Admin") {
          this.showEditButtons = false;
          this.closureForm.disable();
        } else if (this.loginUser.role == "Viewer") {
          this.showEditButtons = false;
          this.closureForm.disable();
        } else if (this.loginUser.role == "CAS") {
          if (this.formData.status == 3 || this.formData.status == 1) {
            this.showEditButtons = false;
            this.closureForm.disable();
          } else {
            this.showEditButtons = false;
            this.closureForm.disable();
          }
        } else if (this.loginUser.role == "SME") {
          if (
            this.formData.status == 4 &&
            this.formData.customerClosureMaterial == "Composite"
          ) {
            var output = this.loginUser.teamId.split("");
            for (let i = 0; i < output.length; i++) {
              let id = output[i];
              if (id == "2") {
                this.showEditButtons = false;
                this.closureForm.disable();
              }
            }
          } else if (
            this.formData.status == 4 &&
            this.formData.customerClosureMaterial == "Plastic"
          ) {
            var output = this.loginUser.teamId.split("");
            for (let i = 0; i < output.length; i++) {
              let id = output[i];
              if (id == "4") {
                this.showEditButtons = false;
                this.closureForm.disable();
              }
            }
          } else if (
            this.formData.status == 4 &&
            this.formData.customerClosureMaterial == "Metal (Steel)"
          ) {
            var output = this.loginUser.teamId.split("");
            for (let i = 0; i < output.length; i++) {
              let id = output[i];
              if (id == "1") {
                this.showEditButtons = false;
                this.closureForm.disable();
              }
            }
          } else if (
            this.formData.status == 4 &&
            this.formData.customerClosureMaterial == "ROPP (Alum)"
          ) {
            var output = this.loginUser.teamId.split("");
            for (let i = 0; i < output.length; i++) {
              let id = output[i];
              if (id == "3") {
                this.showEditButtons = false;
                this.closureForm.disable();
              }
            }
          } else {
            this.showEditButtons = false;
            this.closureForm.disable();
          }
        }
      } else {
        if (this.formData.status == 2) {
          this.showEditButtons = false;
          this.closureForm.disable();
        } else {
          this.showEditButtons = false;
          this.closureForm.disable();
        }
      }
    }

  }
  onproductOk() {
    
    $("#Productformsubmit-modal").modal("hide");    
    this.router.navigate(["/formstepper/" + this.closureForm.get(this.formId).value]);
  }
  omit_special_char(event) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }
  public inputValidator(event: any) {

    //console.log(event.target.value);
    const pattern = /^[a-zA-Z]*$/;   
    //let inputChar = String.fromCharCode(event.charCode)
    if (!pattern.test(event.target.value)) {
      event.target.value = event.target.value.replace(/[^a-zA-Z]/g, "");
      // invalid character, prevent input

    }
  }

}
