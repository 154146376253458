import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { CdkStepper } from "@angular/cdk/stepper";
import { Router } from '@angular/router';
import { jsonpFactory } from "@angular/http/src/http_module";
declare var $: any;


@Component({
  selector: "app-custom-stepper",
  templateUrl: "./custom-stepper.component.html",
  styleUrls: ["./custom-stepper.component.css"],
  providers: [{ provide: CdkStepper, useExisting: CustomStepperComponent }],
})

export class CustomStepperComponent extends CdkStepper implements OnInit {
  
  progressWidth: number;
 ProfileCompleted: boolean = false;
  PackageCompleted: boolean = false;
  ProductCompleted: boolean = false;
  ProcessCompleted: boolean = false;
  PalletizationCompleted: boolean = false;
  
  //id: number;
  
  ngOnInit(): void {

    
    // this.selectedIndex = 6;
    //this.id = this.route.snapshot.params["id"];
   
    var dataSource = JSON.parse(localStorage.getItem("dataSource"));

  
   /* console.log('datasource',dataSource);*/
    this.ProfileCompleted =
      dataSource == null ? false : dataSource.profileCompleted;
    this.PackageCompleted =
      dataSource == null ? false : dataSource.packageCompleted;
    this.ProductCompleted =
      dataSource == null ? false : dataSource.productCompleted;
    this.ProcessCompleted =
      dataSource == null ? false : dataSource.processCompleted;
    this.PalletizationCompleted =
      dataSource == null ? false : dataSource.palletizationCompleted;

    let count = 0;

    if (this.ProfileCompleted) {
      count = count + 1;
    }
    if (this.PackageCompleted) {
      count = count + 1;
    }
    if (this.ProductCompleted) {
      count = count + 1;
    }
    if (this.ProcessCompleted) {
      count = count + 1;
    }
    if (this.PalletizationCompleted) {
      count = count + 1;
    }
    this.progressWidth = count * 20;
  
  }

  ngDoCheck(): void {
    this.ngOnInit();

  }

  onClick(index: number): void {
    
    if (index == 0) {
      if (this.ProfileCompleted == false) {        
        $("#Customformsubmit-modal").modal("show");
      }
      else {
        this.selectedIndex = index;
      }
    }
    else if (index == 1) {
      if (this.PackageCompleted == false) {
        $("#Customformsubmit-modal").modal("show");

      }
      else {
        this.selectedIndex = index;
      }
    }
    else if (index == 2) {
      if (this.ProductCompleted == false) {
        //this.selectedIndex--;
        $("#Customformsubmit-modal").modal("show");
      }
      else {
        this.selectedIndex = index;
      }
    }
    else if (index == 3) {
      if (this.ProcessCompleted == false) {
        $("#Customformsubmit-modal").modal("show");
      }
      else {
        this.selectedIndex = index;
      }
    }
    else if (index == 4) {
      if (this.PalletizationCompleted == false) {
        $("#Customformsubmit-modal").modal("show");
      }
      else {
        this.selectedIndex = index;
      }
    }
    else {
      this.selectedIndex = index;
    }
    //this.selectedIndex = index;
    // this.progressWidth = (this.selectedIndex + 1) * 20;
  }
  onStepperNext() {    
    if (this.selectedIndex < 5) {
      if (this.selectedIndex == 0) {
        if (this.ProfileCompleted == false) {
          this.selectedIndex--;
        $("#Customformsubmit-modal").modal("show");
        }
      }
      if (this.selectedIndex == 1) {
        if (this.PackageCompleted == false) {
          this.selectedIndex--;
        $("#Customformsubmit-modal").modal("show");

        }
      }
      if (this.selectedIndex == 2) {
        if (this.ProductCompleted == false) {
          this.selectedIndex--;
        $("#Customformsubmit-modal").modal("show");
        }
      }
      if (this.selectedIndex == 3) {
        if (this.ProcessCompleted == false) {
          this.selectedIndex--;
        $("#Customformsubmit-modal").modal("show");
        }
      }
      if (this.selectedIndex == 4) {
        if (this.PalletizationCompleted == false) {
          $("#Customformsubmit-modal").modal("show");
        }
 
      }
      else  {       
        this.selectedIndex++;
      }
      //if (this.selectedIndex < 5)
      //  this.progressWidth = (this.selectedIndex + 1) * 20;
    }
  }

  oncustomOk() {    
    $("#Customformsubmit-modal").modal("hide");       
  }
  onStepperPrevious() {
    if (this.selectedIndex > 0) {
      this.selectedIndex--;
      //this.progressWidth = (this.selectedIndex + 1) * 20;
    }
  }
  stepperChangeCollect(data) {
    
    var dataSource = JSON.parse(localStorage.getItem("dataSource"));
    //console.log('datasource',dataSource);
    this.ProfileCompleted =
      dataSource == null ? false : dataSource.profileCompleted;
    this.PackageCompleted =
      dataSource == null ? false : dataSource.packageCompleted;
    this.ProductCompleted =
      dataSource == null ? false : dataSource.productCompleted;
    this.ProcessCompleted =
      dataSource == null ? false : dataSource.processCompleted;
    this.PalletizationCompleted =
      dataSource == null ? false : dataSource.palletizationCompleted;

    //if (this.ProfileCompleted) {
    //  this.progressWidth = (data.selectedIndex + 1) * 20;
    //}

    let count = 0;
    if (this.ProfileCompleted) {
      count = count + 1;
    }
    if (this.PackageCompleted) {
      count = count + 1;
    }
    if (this.ProductCompleted) {
      count = count + 1;
    }
    if (this.ProcessCompleted) {
      count = count + 1;
    }
    if (this.PalletizationCompleted) {
      count = count + 1;
    }
    this.progressWidth = count * 20;
    // debugger;
    //    this.progressWidth = (count) * 20;

    //if (this.selectedIndex < 4) {
    //  this.progressWidth = (data.selectedIndex + 1) * 20;
    //}
  }
}
