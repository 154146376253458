import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { UserService } from "../services/user.service";
import { first } from "rxjs/operators";
import { ToastrService } from 'ngx-toastr';
import { RegisterComponent } from "../register/register.component";
import { User } from '../models/user';
import { debug } from "util";
declare var $: any;
@Component({
    selector: 'user-list',
    templateUrl: './user-list.component.html',
    styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit {
    config: any;
    users : User[] = [];
    selectedUserId: number;
    deleteUserId: number;
    loading: boolean = false;
    display = 'none';
    previousSortColumn: string;
    // headElements = ['Name', 'Email', 'Phone', 'Role', 'Actions'];
    teams = [
        {
            id: 1,
            name: 'Metal (Steel)'
        },
        {
            id: 2,
            name: 'Composite'
        },
        {
            id: 3,
            name: 'ROPP (Alum)'
        },
        {
            id: 4,
            name: 'Plastic'
        }
    ];
    constructor(private userService: UserService, private toastr: ToastrService) { 
        this.config = {
            itemsPerPage: 10,
            currentPage: 1,
            totalItems: this.users.length,
          };
    }

    ngOnInit() {
        $('#new-user').on('hidden.bs.modal', this.onCloseAddUser);
        this.loadAllUsers();
   //     console.log( this.users);
    }


    openModal() {
        this.display = 'block';
    }
    onCloseHandled() {
        this.display = 'none';
    }
    deleteUser(id: number) {
        // let confirmation = confirm("Are you sure you want to delete " + userFirstNLastN);
        // if (confirmation) {
        this.userService.delete(id)
            .pipe(first())
            .subscribe((data) => {
                this.loadAllUsers();
                this.toastr.success("User successfully deleted.");
                $('#delete-user').modal('hide');
            }, err => {
                this.toastr.success(err);
                $('#delete-user').modal('hide');
            });
        // }
    }
  setDeleteUser(id: number) {
        this.deleteUserId = id;
    }

    private loadAllUsers() {
        this.userService.getAll()
            .pipe(first())
            .subscribe(users => this.users = users);
   //         console.log( this.users);
    }
    pageChanged(event){
        this.config.currentPage = event;
    }
    onUserAdd() {
      //  console.log("User Added and close model");
        this.loadAllUsers();
        $('#new-user').modal('hide');
    }
    onCloseAddUser() {
        
    }
    onUserUpdate() {
       // console.log("User updated and close model");
        this.loadAllUsers();
        $('#edit-modal').modal('hide');
    }
  getTeamName(teamId: string) {  
    var output = teamId.split('');
    let teamName;
    var teamNames="";
    for (let i = 0; i < output.length; i++) {    
      let id = output[i];
      if (id != "0") {
        teamName = this.teams.find(t => t.id == parseInt(id));
        if (i == 0) {
          teamNames += teamName.name 
        } else {
          teamNames +=  ','+ teamName.name 
        }
      }
    }
     //    teamName = this.teams.find(t => t.id == id);
    //    if (teamName) {
    //        return '- ' + teamName.name + '';
    //}
    if (teamNames) {
      return '- ' + teamNames + '';
    }
        return '';
  }
  sortAscendingDesceding(col: string) {
    let sortValue = "firstName";
    switch (col) {
      case "firstName":
        sortValue = "firstName";
        break;
      case "email":
        sortValue = "email";
        break;
      case "phone":
        sortValue = "phone";
        break;
      case "role":
        sortValue = "role";
        break;
      default:
        sortValue = "firstName";
        break;
    }
    if (col !== "Actions") {
      if (this.previousSortColumn == sortValue) {
        this.users.reverse();
      }
      else {
        this.previousSortColumn = sortValue;
        this.users.sort((a, b) => (a[sortValue] > b[sortValue]) ? 1 : -1);
      }
    }
  }
}
