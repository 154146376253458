import { Component, OnInit } from '@angular/core';
import { User } from '../models/user';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../services/user.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  user: User;
  loading: boolean = false;
  submitted = false;
  changePasswordForm: FormGroup;

  get oldPassword() {
    return this.changePasswordForm.get("oldPassword");
  }
  get newPassword() {
    return this.changePasswordForm.get("newPassword");
  }
  get confirmPassword() {
    return this.changePasswordForm.get("confirmPassword");
  }
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private userService: UserService,
      private formBuilder: FormBuilder,
      private toastr: ToastrService

    ) {


    }

    ngOnInit() {
        this.changePasswordForm = this.formBuilder.group({
          userId: this.route.snapshot.params['id'],
          oldPassword: ['', [Validators.required, Validators.minLength(6)]],
          newPassword: ['', [Validators.required, Validators.minLength(6)]],
          confirmPassword: ['', [Validators.required, Validators.minLength(6)]]
        }, { validators: this.checkPasswords });
    }

  checkPasswords(group: FormGroup) {
   // here we have the 'passwords' group
    const password = group.get('newPassword').value;
    const confirmPassword = group.get('confirmPassword').value;

    return password === confirmPassword ? null : { notSame: true }
  }
  onSubmit(form: NgForm) {
    if (this.changePasswordForm.invalid) {
      this.submitted = true;
      return;
     
    }
        this.userService.changePassword(this.route.snapshot.params['id'], form)
            .subscribe(x => {
                this.router.navigate(['/profile'])
                 this.changePasswordForm = this.formBuilder.group({
                   userId: this.route.snapshot.params['id'],
                   oldPassword:[''],
                   newPassword:[''],
                   confirmPassword:['']
               });
              //console.log(x);
            }, error => { this.toastr.error("Current password is not correct") });
    }

}
